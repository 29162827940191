// 引入windi css
import '@/plugins/windi.css'

// 导入全局的svg图标
// import '@/plugins/svgIcon'

// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'
import { createI18n } from 'vue-i18n'
import zh from 'view-ui-plus/dist/locale/zh-CN'
import tw from 'view-ui-plus/dist/locale/zh-TW'
import en from 'view-ui-plus/dist/locale/en-US'
import { useLocaleStoreWithOut } from '@/store/modules/locale'
const localeStore = useLocaleStoreWithOut()
const locale = localeStore.getCurrentLocale
const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  locale: locale.lang,
  messages: {
    'zh-cn': zh,
    'zh-tw': tw,
    en: en
  }
})

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入element-plus/icons-vue
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from './router'

import { createApp } from 'vue'

// import './mock'

import App from './App.vue'
import ArcoVue from '@arco-design/web-vue'
import '@arco-design/web-vue/dist/arco.css'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './permission'
import eventBus from 'vue3-eventbus'
import VueCropper from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import vuex from './store/vuex'
import OpenAIPlugin from 'iems-chatbot-ui-plugin'

const setupAll = async () => {
  const app = createApp(App)

  await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)

  app.use(ArcoVue).use(ViewUIPlus, { i18n }).mount('#app')

  app.use(VueCropper)

  app.use(eventBus)
  app.use(vuex)
  app.use(OpenAIPlugin)
}

setupAll().then(() => {})
