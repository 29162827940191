
      import axios from 'axios';
      let data = JSON.parse(localStorage.getItem('ThemeColor'))
      let data1 = document.getElementById('my-bg')
      data1.style.backgroundColor = data?.headerTheme

      let data2 = document.getElementById('app-loading')
      data2.style.backgroundColor = data?.headerTheme

      let data3 = document.getElementById('app')
      data3.style.backgroundColor = data?.headerTheme

      // 每隔40分钟刷新调用token的接口
      function refreshTokenInterval() {
        setInterval(() => {
          const portal = location.origin.includes('portal') ? location.origin : ''
          const nsArray = ['portal-ibms', 'portal-hvac', 'portal-carbonr']
          const portalPrefix = nsArray.find((p) => portal.includes(p))
          let portal1 = portal.replace(portalPrefix, 'portal-apm')
          const cookieToken = document.cookie.split(';')
          const url = portal1 + '/auth/api/v1/refreshtoken'

          if (cookieToken) {
            // 使用 setInterval()函数每隔 1000 毫秒调用一次接口
            axios({
              method: 'get',
	            '&componentName=StaticProperty': undefined,
              url,
              // params: {},
              // headers: {
              //   Authorization: 'Bearer ' + EIToken
              // }
	            withCredentials: true
            }).then((res) => {
	            sessionStorage.setItem('EIToken', res?.data?.accessToken)
              console.info(
                '🚀 ~ file:index method: line:170 ----- index.html刷新token******************===>',
                res
              )
            })
          }
        }, 40 * 60 * 1000)
      }
      window.onload = function() {
        refreshTokenInterval();
      };
    