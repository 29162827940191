import { configs } from '@/config/axios/config'
import { MockMethod } from 'vite-plugin-mock'

const { result_code } = configs

const timeout = 1000

export default [
  // 获取区域
  {
    url: '/getAreaApi',
    method: 'get',
    timeout,
    response: () => {
      return {
        code: result_code,
        data: [
          {
            "orgId": 1,
            "type": "park",
            "description": "",
            "parentId": "1384",
            "groupId": 10,
            "sortNum": 2,
            "grade": 2,
            "apm_org_id": "1",
            "apm_org_name": "Main Org.",
            "label": "\u6606\u5c71\u56ed\u533a",
            "value": "1385",
            "children": [
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "1385",
                "groupId": 10,
                "sortNum": 3,
                "grade": 2,
                "label": "\u4e00\u81f3\u56db\u671f",
                "value": "1386",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 4,
                    "grade": 2,
                    "label": "\u4e00\u671f\u5382",
                    "value": "1388",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1388",
                        "groupId": 10,
                        "sortNum": 5,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5382\u4e00\u697c",
                        "value": "1409",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 6,
                            "grade": 2,
                            "label": "\u4e00\u53821F\u6c2e\u6c14\u7528\u91cf",
                            "value": "1412",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1412",
                                "groupId": 10,
                                "sortNum": 7,
                                "grade": 2,
                                "label": "KYLJLL\u4e00\u5382",
                                "value": "1339",
                                "sourceId": 1728
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 10,
                            "grade": 2,
                            "label": "\u4e00\u671f\u7a7a\u538b\u673a",
                            "value": "1413",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1413",
                                "groupId": 10,
                                "sortNum": 11,
                                "grade": 2,
                                "label": "AKMC_1002",
                                "value": "549",
                                "sourceId": 2006
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 14,
                            "grade": 2,
                            "label": "\u4e00\u5382\u84b8\u6c7d\u7528\u91cf",
                            "value": "1414",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1414",
                                "groupId": 10,
                                "sortNum": 15,
                                "grade": 2,
                                "label": "ZQLLJ\u4e00\u5382",
                                "value": "1289",
                                "sourceId": 1721
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 18,
                            "grade": 2,
                            "label": "\u4e00\u5382\u603b\u7167\u660e",
                            "value": "1415",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1415",
                                "groupId": 10,
                                "sortNum": 19,
                                "grade": 2,
                                "label": "AKMC_EPCAL",
                                "value": "533",
                                "sourceId": 1722
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 22,
                            "grade": 2,
                            "label": "\u4e00\u5382\u5317\u4fa7\u7528\u6c34",
                            "value": "1416",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1416",
                                "groupId": 10,
                                "sortNum": 23,
                                "grade": 2,
                                "label": "AKMC_WATER6",
                                "value": "845",
                                "sourceId": 1723
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 26,
                            "grade": 2,
                            "label": "\u4e00\u53821F\u70e7\u673a\u623f",
                            "value": "1417",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1417",
                                "groupId": 10,
                                "sortNum": 27,
                                "grade": 2,
                                "label": "AKMC_L9",
                                "value": "655",
                                "sourceId": 1724
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 30,
                            "grade": 2,
                            "label": "\u4e00\u5382\u5357\u4fa7\u7528\u6c34",
                            "value": "1419",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1419",
                                "groupId": 10,
                                "sortNum": 31,
                                "grade": 2,
                                "label": "AKMC_WATER5",
                                "value": "847",
                                "sourceId": 1725
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 34,
                            "grade": 2,
                            "label": "\u4e00\u671f\u51b7\u5374\u6cf5\u5357",
                            "value": "1420",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1420",
                                "groupId": 10,
                                "sortNum": 35,
                                "grade": 2,
                                "label": "AKMC_1CLQN",
                                "value": "553",
                                "sourceId": 1726
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 38,
                            "grade": 2,
                            "label": "\u4e00\u5382\u7a7a\u8c03\u7bb1",
                            "value": "1421",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1421",
                                "groupId": 10,
                                "sortNum": 39,
                                "grade": 2,
                                "label": "AKMC_1CKTX",
                                "value": "552",
                                "sourceId": 1727
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 42,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT\u4ed3\u5e93",
                            "value": "1422",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1422",
                                "groupId": 10,
                                "sortNum": 43,
                                "grade": 2,
                                "label": "AKMC_L1",
                                "value": "579",
                                "sourceId": 1729
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 46,
                            "grade": 2,
                            "label": "\u4e00\u671f\u51b7\u5374\u6cf5\u5317",
                            "value": "1423",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00\u671f\u51b7\u5374\u6cf5\u5317",
                                "parentId": "1423",
                                "groupId": 10,
                                "sortNum": 47,
                                "grade": 2,
                                "label": "AKMC_1CLQB",
                                "value": "551",
                                "sourceId": 1720
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1409",
                            "groupId": 10,
                            "sortNum": 50,
                            "grade": 2,
                            "label": "\u4e00\u671f\u7a7a\u8c03\u4e3b\u673a",
                            "value": "1995",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00\u671f\u7a7a\u8c03\u7cfb\u7edf",
                                "parentId": "1995",
                                "groupId": 10,
                                "sortNum": 51,
                                "grade": 2,
                                "label": "AKMC_1001",
                                "value": "577",
                                "sourceId": 3672
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1388",
                        "groupId": 10,
                        "sortNum": 55,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5382\u4e8c\u697c",
                        "value": "1410",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 56,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT\u52a8\u529b1",
                            "value": "1424",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1424",
                                "groupId": 10,
                                "sortNum": 57,
                                "grade": 2,
                                "label": "AKMC_L3",
                                "value": "578",
                                "sourceId": 1717
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 60,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT\u7a7a\u8c03",
                            "value": "1425",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1425",
                                "groupId": 10,
                                "sortNum": 61,
                                "grade": 2,
                                "label": "AKMC_L6",
                                "value": "575",
                                "sourceId": 1718
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 64,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT\u52a8\u529b2",
                            "value": "1426",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1426",
                                "groupId": 10,
                                "sortNum": 65,
                                "grade": 2,
                                "label": "SMT1\u7ebf\u56de\u710a\u7089",
                                "value": "1439",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1439",
                                    "groupId": 10,
                                    "sortNum": 66,
                                    "grade": 2,
                                    "label": "AKMC_SMT3A",
                                    "value": "544",
                                    "sourceId": 3686
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1426",
                                "groupId": 10,
                                "sortNum": 69,
                                "grade": 2,
                                "label": "SMT\u8d34\u7247\u673a",
                                "value": "2001",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "2001",
                                    "groupId": 10,
                                    "sortNum": 70,
                                    "grade": 2,
                                    "label": "AKMC_L5",
                                    "value": "580",
                                    "sourceId": 3677
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 74,
                            "grade": 2,
                            "label": "\u4e00\u53822F\u7a7a\u538b\u7528\u91cf1",
                            "value": "1427",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1427",
                                "groupId": 10,
                                "sortNum": 75,
                                "grade": 2,
                                "label": "KYLJLL\u4e00\u53822F1",
                                "value": "1340",
                                "sourceId": 1706
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 78,
                            "grade": 2,
                            "label": "\u4e00\u53822F\u70e7\u673a\u623f",
                            "value": "1428",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1428",
                                "groupId": 10,
                                "sortNum": 79,
                                "grade": 2,
                                "label": "AKMC_L7",
                                "value": "574",
                                "sourceId": 1707
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 82,
                            "grade": 2,
                            "label": "\u4e00\u5382DIP\u52a8\u529b",
                            "value": "1429",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1429",
                                "groupId": 10,
                                "sortNum": 83,
                                "grade": 2,
                                "label": "DIP3\u7ebf\u9521\u7089",
                                "value": "1434",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1434",
                                    "groupId": 10,
                                    "sortNum": 84,
                                    "grade": 2,
                                    "label": "AKMC_L23",
                                    "value": "546",
                                    "sourceId": 1710
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1429",
                                "groupId": 10,
                                "sortNum": 87,
                                "grade": 2,
                                "label": "DIP1\u7ebf\u9521\u7089",
                                "value": "1435",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1435",
                                    "groupId": 10,
                                    "sortNum": 88,
                                    "grade": 2,
                                    "label": "AKMC_L21",
                                    "value": "550",
                                    "sourceId": 1708
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1429",
                                "groupId": 10,
                                "sortNum": 91,
                                "grade": 2,
                                "label": "DIP2\u7ebf\u9521\u7089",
                                "value": "1437",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1437",
                                    "groupId": 10,
                                    "sortNum": 92,
                                    "grade": 2,
                                    "label": "AKMC_L22",
                                    "value": "543",
                                    "sourceId": 1709
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1429",
                                "groupId": 10,
                                "sortNum": 95,
                                "grade": 2,
                                "label": "\u4e00\u671fDIP\u6d41\u6c34\u7ebf",
                                "value": "2002",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "2002",
                                    "groupId": 10,
                                    "sortNum": 96,
                                    "grade": 2,
                                    "label": "AKMC_L2",
                                    "value": "581",
                                    "sourceId": 3673
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 100,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT3\u7ebf",
                            "value": "1430",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1430",
                                "groupId": 10,
                                "sortNum": 101,
                                "grade": 2,
                                "label": "SMT3\u7ebf\u8d34\u7247\u673a",
                                "value": "2003",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "2003",
                                    "groupId": 10,
                                    "sortNum": 102,
                                    "grade": 2,
                                    "label": "AKMC_SMT3",
                                    "value": "547",
                                    "sourceId": 3675
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1430",
                                "groupId": 10,
                                "sortNum": 105,
                                "grade": 2,
                                "label": "SMT3\u7ebf\u56de\u710a\u7089",
                                "value": "3674",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "3674",
                                    "groupId": 10,
                                    "sortNum": 106,
                                    "grade": 2,
                                    "label": "I_1S_2F_SMT3",
                                    "value": "3687",
                                    "sourceId": 3688
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 110,
                            "grade": 2,
                            "label": "\u4e00\u53822F\u7a7a\u538b\u7528\u91cf2",
                            "value": "1431",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1431",
                                "groupId": 10,
                                "sortNum": 111,
                                "grade": 2,
                                "label": "KYLJLL\u4e00\u53822F2",
                                "value": "1341",
                                "sourceId": 1714
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 114,
                            "grade": 2,
                            "label": "\u4e00\u5382SMT\u70b9\u6599\u673a",
                            "value": "1432",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1432",
                                "groupId": 10,
                                "sortNum": 115,
                                "grade": 2,
                                "label": "AKMC_L8",
                                "value": "582",
                                "sourceId": 1715
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1410",
                            "groupId": 10,
                            "sortNum": 118,
                            "grade": 2,
                            "label": "\u4e00\u53822F\u6c2e\u6c14\u7528\u91cf",
                            "value": "1433",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1433",
                                "groupId": 10,
                                "sortNum": 119,
                                "grade": 2,
                                "label": "KYLJLL\u4e00\u53822FDQ",
                                "value": "1342",
                                "sourceId": 1716
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 124,
                    "grade": 2,
                    "label": "\u4e8c\u671f\u5382",
                    "value": "1392",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1392",
                        "groupId": 10,
                        "sortNum": 125,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5382\u4e00\u697c",
                        "value": "1408",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1408",
                            "groupId": 10,
                            "sortNum": 126,
                            "grade": 2,
                            "label": "\u5317\u4fa7\u603b\u7167\u660e",
                            "value": "1442",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1442",
                                "groupId": 10,
                                "sortNum": 127,
                                "grade": 2,
                                "label": "AKMC_NBLA",
                                "value": "567",
                                "sourceId": 1739
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1408",
                            "groupId": 10,
                            "sortNum": 130,
                            "grade": 2,
                            "label": "\u5357\u4fa7\u603b\u7167\u660e",
                            "value": "1443",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1443",
                                "groupId": 10,
                                "sortNum": 131,
                                "grade": 2,
                                "label": "AKMC_SBLA",
                                "value": "568",
                                "sourceId": 1737
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1408",
                            "groupId": 10,
                            "sortNum": 134,
                            "grade": 2,
                            "label": "1F\u7a7a\u8c03\u7bb1\u5357",
                            "value": "1444",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1444",
                                "groupId": 10,
                                "sortNum": 135,
                                "grade": 2,
                                "label": "AKMC_SYKTX",
                                "value": "531",
                                "sourceId": 1738
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1392",
                        "groupId": 10,
                        "sortNum": 139,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5382\u4e8c\u697c",
                        "value": "1441",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1441",
                            "groupId": 10,
                            "sortNum": 140,
                            "grade": 2,
                            "label": "\u4e8c\u671fMIS\u673a\u623f",
                            "value": "1445",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1445",
                                "groupId": 10,
                                "sortNum": 141,
                                "grade": 2,
                                "label": "AKMC_2FMIS",
                                "value": "583",
                                "sourceId": 1766
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1441",
                            "groupId": 10,
                            "sortNum": 144,
                            "grade": 2,
                            "label": "2F\u52a8\u529b\u5357",
                            "value": "1446",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1446",
                                "groupId": 10,
                                "sortNum": 145,
                                "grade": 2,
                                "label": "AKMC_SB2P",
                                "value": "569",
                                "sourceId": 1767
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1441",
                            "groupId": 10,
                            "sortNum": 148,
                            "grade": 2,
                            "label": "2F\u52a8\u529b\u5317",
                            "value": "1447",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1447",
                                "groupId": 10,
                                "sortNum": 149,
                                "grade": 2,
                                "label": "AKMC_NB2P",
                                "value": "572",
                                "sourceId": 1765
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1441",
                            "groupId": 10,
                            "sortNum": 152,
                            "grade": 2,
                            "label": "2F\u7a7a\u8c03\u7bb1\u5357",
                            "value": "1448",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1448",
                                "groupId": 10,
                                "sortNum": 153,
                                "grade": 2,
                                "label": "AKMC_SEKTX",
                                "value": "527",
                                "sourceId": 1741
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1441",
                            "groupId": 10,
                            "sortNum": 156,
                            "grade": 2,
                            "label": "2F\u7a7a\u8c03\u7bb1\u5317",
                            "value": "1449",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1449",
                                "groupId": 10,
                                "sortNum": 157,
                                "grade": 2,
                                "label": "AKMC_NEKTX",
                                "value": "529",
                                "sourceId": 1740
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1392",
                        "groupId": 10,
                        "sortNum": 161,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5382\u4e09\u697c",
                        "value": "1440",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1440",
                            "groupId": 10,
                            "sortNum": 162,
                            "grade": 2,
                            "label": "3F\u7a7a\u8c03\u7bb1\u5317",
                            "value": "1450",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1450",
                                "groupId": 10,
                                "sortNum": 163,
                                "grade": 2,
                                "label": "AKMC_NSKTX",
                                "value": "530",
                                "sourceId": 1736
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1440",
                            "groupId": 10,
                            "sortNum": 166,
                            "grade": 2,
                            "label": "3F\u52a8\u529b\u5357",
                            "value": "1451",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1451",
                                "groupId": 10,
                                "sortNum": 167,
                                "grade": 2,
                                "label": "AKMC_SB3P",
                                "value": "573",
                                "sourceId": 1759
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1440",
                            "groupId": 10,
                            "sortNum": 170,
                            "grade": 2,
                            "label": "3F\u52a8\u529b\u5317",
                            "value": "1452",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1452",
                                "groupId": 10,
                                "sortNum": 171,
                                "grade": 2,
                                "label": "AKMC_NB3P",
                                "value": "566",
                                "sourceId": 1758
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1440",
                            "groupId": 10,
                            "sortNum": 174,
                            "grade": 2,
                            "label": "3F\u7a7a\u8c03\u7bb1\u5357",
                            "value": "1453",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1453",
                                "groupId": 10,
                                "sortNum": 175,
                                "grade": 2,
                                "label": "AKMC_SSKTX",
                                "value": "528",
                                "sourceId": 1735
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1392",
                        "groupId": 10,
                        "sortNum": 179,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5382\u56db\u697c",
                        "value": "1407",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 180,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u70ed\u56de\u6536\u6c34",
                            "value": "1454",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1454",
                                "groupId": 10,
                                "sortNum": 181,
                                "grade": 2,
                                "label": "AKMC_CLRTER1",
                                "value": "1370",
                                "sourceId": 1760
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 184,
                            "grade": 2,
                            "label": "\u4e8c\u671f4F\u7a7a\u538b\u7528\u91cf",
                            "value": "1455",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1455",
                                "groupId": 10,
                                "sortNum": 185,
                                "grade": 2,
                                "label": "KYLJLL\u4e8c\u53824F",
                                "value": "1344",
                                "sourceId": 1764
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 188,
                            "grade": 2,
                            "label": "\u4e8c\u5382\u84b8\u6c7d\u7528\u91cf",
                            "value": "1456",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1456",
                                "groupId": 10,
                                "sortNum": 189,
                                "grade": 2,
                                "label": "ZQLLJ\u4e8c\u53824F",
                                "value": "1343",
                                "sourceId": 1762
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 192,
                            "grade": 2,
                            "label": "\u4e8c\u5382\u7528\u6c34",
                            "value": "1457",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1457",
                                "groupId": 10,
                                "sortNum": 193,
                                "grade": 2,
                                "label": "AKMC_WATER7",
                                "value": "820",
                                "sourceId": 1761
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 196,
                            "grade": 2,
                            "label": "\u4e8c\u671f4F\u7a7a\u538b",
                            "value": "1459",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1459",
                                "groupId": 10,
                                "sortNum": 197,
                                "grade": 2,
                                "label": "AKMC_NB4P",
                                "value": "565",
                                "sourceId": 1763
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1407",
                            "groupId": 10,
                            "sortNum": 200,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u7a7a\u8c03\u673a\u623f",
                            "value": "2004",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e8c\u671f\u5382",
                                "parentId": "2004",
                                "groupId": 10,
                                "sortNum": 201,
                                "grade": 2,
                                "label": "AKMC_ALL",
                                "value": "570",
                                "sourceId": 3669
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 206,
                    "grade": 2,
                    "label": "\u4e09\u671f\u5382",
                    "value": "1391",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 207,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u4e00\u697c",
                        "value": "1462",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 208,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u52a8\u529b2",
                            "value": "1464",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u671f\u673a\u7bb1\u5382\u51b2\u5e8a",
                                "parentId": "1464",
                                "groupId": 10,
                                "sortNum": 209,
                                "grade": 2,
                                "label": "AKMC_3S1F2",
                                "value": "614",
                                "sourceId": 1750
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 212,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u7a7a\u538b\u7528\u91cf",
                            "value": "1465",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1465",
                                "groupId": 10,
                                "sortNum": 213,
                                "grade": 2,
                                "label": "KYLJLL3\u53821F",
                                "value": "1345",
                                "sourceId": 1754
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 216,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u52a8\u529b1",
                            "value": "1466",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1466",
                                "groupId": 10,
                                "sortNum": 217,
                                "grade": 2,
                                "label": "AKMC_3S1F1",
                                "value": "588",
                                "sourceId": 1755
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 220,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u529e\u516c\u7a7a\u8c03",
                            "value": "1467",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1467",
                                "groupId": 10,
                                "sortNum": 221,
                                "grade": 2,
                                "label": "AKMC_1ACP1",
                                "value": "627",
                                "sourceId": 1751
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 224,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u8f66\u95f4\u7a7a\u8c03",
                            "value": "1468",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1468",
                                "groupId": 10,
                                "sortNum": 225,
                                "grade": 2,
                                "label": "AKMC_1ACP1H",
                                "value": "616",
                                "sourceId": 1752
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1462",
                            "groupId": 10,
                            "sortNum": 228,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u7167\u660e",
                            "value": "1469",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1469",
                                "groupId": 10,
                                "sortNum": 229,
                                "grade": 2,
                                "label": "AKMC_3S1FL",
                                "value": "596",
                                "sourceId": 1753
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 233,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u4e8c\u697c",
                        "value": "1463",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1463",
                            "groupId": 10,
                            "sortNum": 234,
                            "grade": 2,
                            "label": "\u4e09\u671f2F\u7a7a\u8c03",
                            "value": "1470",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1470",
                                "groupId": 10,
                                "sortNum": 235,
                                "grade": 2,
                                "label": "AKMC_2ACP1",
                                "value": "622",
                                "sourceId": 1756
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1463",
                            "groupId": 10,
                            "sortNum": 238,
                            "grade": 2,
                            "label": "\u4e09\u671f2F\u7167\u660e",
                            "value": "1471",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1471",
                                "groupId": 10,
                                "sortNum": 239,
                                "grade": 2,
                                "label": "AKMC_3S2FL",
                                "value": "595",
                                "sourceId": 1757
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1463",
                            "groupId": 10,
                            "sortNum": 242,
                            "grade": 2,
                            "label": "\u4e09\u671f2FCTOS\u70e7\u673a\u623f",
                            "value": "1472",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1472",
                                "groupId": 10,
                                "sortNum": 243,
                                "grade": 2,
                                "label": "AKMC_3S2FS",
                                "value": "599",
                                "sourceId": 1795
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 247,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u4e09\u697c",
                        "value": "1461",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1461",
                            "groupId": 10,
                            "sortNum": 248,
                            "grade": 2,
                            "label": "\u4e09\u671f3F\u7167\u660e",
                            "value": "1473",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1473",
                                "groupId": 10,
                                "sortNum": 249,
                                "grade": 2,
                                "label": "AKMC_3S3FL",
                                "value": "608",
                                "sourceId": 1794
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1461",
                            "groupId": 10,
                            "sortNum": 252,
                            "grade": 2,
                            "label": "\u4e09\u671f3F\u5357\u7a7a\u8c03",
                            "value": "1474",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1474",
                                "groupId": 10,
                                "sortNum": 253,
                                "grade": 2,
                                "label": "AKMC_3ACP2",
                                "value": "629",
                                "sourceId": 1748
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1461",
                            "groupId": 10,
                            "sortNum": 256,
                            "grade": 2,
                            "label": "\u4e09\u671f3F\u5317\u7a7a\u8c03",
                            "value": "1475",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1475",
                                "groupId": 10,
                                "sortNum": 257,
                                "grade": 2,
                                "label": "AKMC_3ACP1",
                                "value": "642",
                                "sourceId": 1749
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 261,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u56db\u697c",
                        "value": "1460",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1460",
                            "groupId": 10,
                            "sortNum": 262,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u52a8\u529b",
                            "value": "1476",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1476",
                                "groupId": 10,
                                "sortNum": 263,
                                "grade": 2,
                                "label": "AKMC_3S6FWA",
                                "value": "598",
                                "sourceId": 1787
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1460",
                            "groupId": 10,
                            "sortNum": 266,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u5357\u7a7a\u8c03",
                            "value": "1477",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1477",
                                "groupId": 10,
                                "sortNum": 267,
                                "grade": 2,
                                "label": "AKMC_4ACP2",
                                "value": "649",
                                "sourceId": 1746
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1460",
                            "groupId": 10,
                            "sortNum": 270,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u5317\u7a7a\u8c03",
                            "value": "1478",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1478",
                                "groupId": 10,
                                "sortNum": 271,
                                "grade": 2,
                                "label": "AKMC_4ACP1",
                                "value": "634",
                                "sourceId": 1745
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1460",
                            "groupId": 10,
                            "sortNum": 274,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u7167\u660e",
                            "value": "1479",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1479",
                                "groupId": 10,
                                "sortNum": 275,
                                "grade": 2,
                                "label": "AKMC_3S4FL",
                                "value": "586",
                                "sourceId": 1786
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 279,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u4e94\u697c",
                        "value": "1406",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 280,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u5317\u7a7a\u8c03",
                            "value": "1480",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1480",
                                "groupId": 10,
                                "sortNum": 281,
                                "grade": 2,
                                "label": "AKMC_5ACP1",
                                "value": "625",
                                "sourceId": 1744
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 284,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u5357\u7a7a\u8c03",
                            "value": "1481",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1481",
                                "groupId": 10,
                                "sortNum": 285,
                                "grade": 2,
                                "label": "AKMC_5ACP2",
                                "value": "637",
                                "sourceId": 1743
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 288,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u52a8\u529b",
                            "value": "1482",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1482",
                                "groupId": 10,
                                "sortNum": 289,
                                "grade": 2,
                                "label": "AKMC_3S5FP",
                                "value": "603",
                                "sourceId": 1783
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 292,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u7167\u660e",
                            "value": "1483",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1483",
                                "groupId": 10,
                                "sortNum": 293,
                                "grade": 2,
                                "label": "AKMC_3S5FL",
                                "value": "592",
                                "sourceId": 1784
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 296,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u7a7a\u538b\u7528\u91cf",
                            "value": "1484",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1484",
                                "groupId": 10,
                                "sortNum": 297,
                                "grade": 2,
                                "label": "KYLJLL3\u53825F",
                                "value": "1346",
                                "sourceId": 1785
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 300,
                            "grade": 2,
                            "label": "\u4e09\u671f5FMIS\u673a\u623f",
                            "value": "1485",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1485",
                                "groupId": 10,
                                "sortNum": 301,
                                "grade": 2,
                                "label": "AKMC_3SMIS2",
                                "value": "610",
                                "sourceId": 1782
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1406",
                            "groupId": 10,
                            "sortNum": 304,
                            "grade": 2,
                            "label": "5F\u4e2d\u95f4\u4ed3\u7a7a\u8c03",
                            "value": "1486",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1486",
                                "groupId": 10,
                                "sortNum": 305,
                                "grade": 2,
                                "label": "AKMC_5ACP4",
                                "value": "617",
                                "sourceId": 1742
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1391",
                        "groupId": 10,
                        "sortNum": 309,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5382\u516d\u697c",
                        "value": "1405",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 310,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u7a7a\u538b\u7528\u91cf",
                            "value": "1488",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1488",
                                "groupId": 10,
                                "sortNum": 311,
                                "grade": 2,
                                "label": "KYLJLL3\u53826F",
                                "value": "1347",
                                "sourceId": 1789
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 314,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u52a8\u529b2",
                            "value": "1489",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1489",
                                "groupId": 10,
                                "sortNum": 315,
                                "grade": 2,
                                "label": "AKMC_3S6F2",
                                "value": "587",
                                "sourceId": 1788
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 318,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u8f66\u95f4\u7a7a\u8c03",
                            "value": "1490",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1490",
                                "groupId": 10,
                                "sortNum": 319,
                                "grade": 2,
                                "label": "AKMC_6FPAI",
                                "value": "605",
                                "sourceId": 1790
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 322,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u529e\u516c\u7a7a\u8c03",
                            "value": "1491",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1491",
                                "groupId": 10,
                                "sortNum": 323,
                                "grade": 2,
                                "label": "AKMC_6ACP1",
                                "value": "619",
                                "sourceId": 1747
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 326,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u7167\u660e",
                            "value": "1493",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1493",
                                "groupId": 10,
                                "sortNum": 327,
                                "grade": 2,
                                "label": "AKMC_3S6FL",
                                "value": "601",
                                "sourceId": 1792
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 330,
                            "grade": 2,
                            "label": "\u524d\u5904\u7406\u7528\u6c34",
                            "value": "1494",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1494",
                                "groupId": 10,
                                "sortNum": 331,
                                "grade": 2,
                                "label": "AKMC_WATER3",
                                "value": "823",
                                "sourceId": 1793
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 334,
                            "grade": 2,
                            "label": "\u4e09\u671f\u7535\u68af",
                            "value": "2016",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2016",
                                "groupId": 10,
                                "sortNum": 335,
                                "grade": 2,
                                "label": "AKMC_3SLA",
                                "value": "615",
                                "sourceId": 3667
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1405",
                            "groupId": 10,
                            "sortNum": 338,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u52a8\u529b1",
                            "value": "2017",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2017",
                                "groupId": 10,
                                "sortNum": 339,
                                "grade": 2,
                                "label": "AKMC_3S6F1",
                                "value": "589",
                                "sourceId": 3668
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 344,
                    "grade": 2,
                    "label": "\u56db\u671f\u5382",
                    "value": "1387",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 345,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u4e00\u697c",
                        "value": "1497",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1497",
                            "groupId": 10,
                            "sortNum": 346,
                            "grade": 2,
                            "label": "\u56db\u671f1F\u6210\u4ed3\u7167\u660e",
                            "value": "1499",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1499",
                                "groupId": 10,
                                "sortNum": 347,
                                "grade": 2,
                                "label": "AKMC_4S1FL",
                                "value": "597",
                                "sourceId": 1823
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1497",
                            "groupId": 10,
                            "sortNum": 350,
                            "grade": 2,
                            "label": "\u56db\u671f1F\u6210\u4ed3\u7a7a\u8c03",
                            "value": "1500",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1500",
                                "groupId": 10,
                                "sortNum": 351,
                                "grade": 2,
                                "label": "AKMC_1ACP3",
                                "value": "624",
                                "sourceId": 1772
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1497",
                            "groupId": 10,
                            "sortNum": 354,
                            "grade": 2,
                            "label": "\u56db\u671f1F\u529e\u516c\u7a7a\u8c03",
                            "value": "1501",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1501",
                                "groupId": 10,
                                "sortNum": 355,
                                "grade": 2,
                                "label": "AKMC_1ACP2",
                                "value": "636",
                                "sourceId": 1771
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 359,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u4e8c\u697c",
                        "value": "1495",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 360,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u7a7a\u538b\u7528\u91cf",
                            "value": "1502",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1502",
                                "groupId": 10,
                                "sortNum": 361,
                                "grade": 2,
                                "label": "KYLJLL4\u53822F",
                                "value": "1348",
                                "sourceId": 1849
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 364,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u52a8\u529b",
                            "value": "1503",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1503",
                                "groupId": 10,
                                "sortNum": 365,
                                "grade": 2,
                                "label": "AKMC_4S2FP",
                                "value": "593",
                                "sourceId": 1852
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 368,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u7167\u660e",
                            "value": "1504",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1504",
                                "groupId": 10,
                                "sortNum": 369,
                                "grade": 2,
                                "label": "AKMC_4S2FL",
                                "value": "604",
                                "sourceId": 1848
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 372,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671fMIS\u673a\u623f\u7a7a\u8c03",
                            "value": "1505",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1505",
                                "groupId": 10,
                                "sortNum": 373,
                                "grade": 2,
                                "label": "AKMC_3SMISA",
                                "value": "611",
                                "sourceId": 1850
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 376,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671fMIS\u673a\u623f",
                            "value": "1506",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1506",
                                "groupId": 10,
                                "sortNum": 377,
                                "grade": 2,
                                "label": "AKMC_3SMIS1",
                                "value": "607",
                                "sourceId": 1851
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 380,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u529e\u516c\u7a7a\u8c03",
                            "value": "1507",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1507",
                                "groupId": 10,
                                "sortNum": 381,
                                "grade": 2,
                                "label": "AKMC_2ACP5",
                                "value": "650",
                                "sourceId": 1780
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 384,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u5317\u7a7a\u8c03",
                            "value": "1508",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1508",
                                "groupId": 10,
                                "sortNum": 385,
                                "grade": 2,
                                "label": "AKMC_2ACP3",
                                "value": "620",
                                "sourceId": 1781
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 388,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u5357\u7a7a\u8c03",
                            "value": "1509",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1509",
                                "groupId": 10,
                                "sortNum": 389,
                                "grade": 2,
                                "label": "AKMC_2ACP4",
                                "value": "643",
                                "sourceId": 1778
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1495",
                            "groupId": 10,
                            "sortNum": 392,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u4e2d\u95f4\u4ed3\u7a7a\u8c03\u7bb1",
                            "value": "1510",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1510",
                                "groupId": 10,
                                "sortNum": 393,
                                "grade": 2,
                                "label": "AKMC_2ACP2",
                                "value": "647",
                                "sourceId": 1779
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 397,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u56db\u697c",
                        "value": "1498",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 398,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u52a8\u529b",
                            "value": "1516",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u56db\u671f\u56db\u697c\u52a8\u529b",
                                "parentId": "1516",
                                "groupId": 10,
                                "sortNum": 399,
                                "grade": 2,
                                "label": "AKMC_4S4FP",
                                "value": "585",
                                "sourceId": 1824
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 402,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u7a7a\u8c03\u5317",
                            "value": "1517",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1517",
                                "groupId": 10,
                                "sortNum": 403,
                                "grade": 2,
                                "label": "AKMC_4ACP5",
                                "value": "644",
                                "sourceId": 1773
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 406,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u4e2d\u95f4\u4ed3\u7a7a\u8c03\u7bb1",
                            "value": "1518",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1518",
                                "groupId": 10,
                                "sortNum": 407,
                                "grade": 2,
                                "label": "AKMC_4ACP4",
                                "value": "653",
                                "sourceId": 1775
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 410,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u6c2e\u6c14\u7528\u91cf",
                            "value": "1519",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1519",
                                "groupId": 10,
                                "sortNum": 411,
                                "grade": 2,
                                "label": "KYLJLL4\u53824F2",
                                "value": "1350",
                                "sourceId": 1825
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 414,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u7a7a\u8c03\u5357",
                            "value": "1520",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1520",
                                "groupId": 10,
                                "sortNum": 415,
                                "grade": 2,
                                "label": "AKMC_4ACP6",
                                "value": "646",
                                "sourceId": 1774
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 418,
                            "grade": 2,
                            "label": "SMT\u72ec\u7acb\u7a7a\u8c03",
                            "value": "1521",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1521",
                                "groupId": 10,
                                "sortNum": 419,
                                "grade": 2,
                                "label": "AKMC_4FCHAP",
                                "value": "618",
                                "sourceId": 1826
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 422,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u7167\u660e",
                            "value": "1522",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1522",
                                "groupId": 10,
                                "sortNum": 423,
                                "grade": 2,
                                "label": "AKMC_4S4FL",
                                "value": "613",
                                "sourceId": 1827
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1498",
                            "groupId": 10,
                            "sortNum": 426,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u7a7a\u538b\u7528\u91cf",
                            "value": "1523",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1523",
                                "groupId": 10,
                                "sortNum": 427,
                                "grade": 2,
                                "label": "KYLJLL4\u53824F1",
                                "value": "1349",
                                "sourceId": 1828
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 431,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u4e09\u697c",
                        "value": "1496",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1496",
                            "groupId": 10,
                            "sortNum": 432,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u7167\u660e",
                            "value": "1511",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1511",
                                "groupId": 10,
                                "sortNum": 433,
                                "grade": 2,
                                "label": "AKMC_4S3FL",
                                "value": "594",
                                "sourceId": 1822
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1496",
                            "groupId": 10,
                            "sortNum": 436,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u52a8\u529b",
                            "value": "1512",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1512",
                                "groupId": 10,
                                "sortNum": 437,
                                "grade": 2,
                                "label": "AKMC_4S3FP",
                                "value": "606",
                                "sourceId": 1821
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1496",
                            "groupId": 10,
                            "sortNum": 440,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u5317\u7a7a\u8c03",
                            "value": "1513",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1513",
                                "groupId": 10,
                                "sortNum": 441,
                                "grade": 2,
                                "label": "AKMC_3ACP5",
                                "value": "635",
                                "sourceId": 1770
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1496",
                            "groupId": 10,
                            "sortNum": 444,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u4e2d\u95f4\u4ed3\u7a7a\u8c03\u7bb1",
                            "value": "1514",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1514",
                                "groupId": 10,
                                "sortNum": 445,
                                "grade": 2,
                                "label": "AKMC_3ACP4",
                                "value": "623",
                                "sourceId": 1769
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1496",
                            "groupId": 10,
                            "sortNum": 448,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u5357\u7a7a\u8c03",
                            "value": "1515",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1515",
                                "groupId": 10,
                                "sortNum": 449,
                                "grade": 2,
                                "label": "AKMC_3ACP6",
                                "value": "631",
                                "sourceId": 1768
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 453,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u4e94\u697c",
                        "value": "1403",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1403",
                            "groupId": 10,
                            "sortNum": 454,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u5357\u7a7a\u8c03",
                            "value": "1524",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1524",
                                "groupId": 10,
                                "sortNum": 455,
                                "grade": 2,
                                "label": "AKMC_5ACP6",
                                "value": "638",
                                "sourceId": 1777
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1403",
                            "groupId": 10,
                            "sortNum": 458,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u5317\u7a7a\u8c03",
                            "value": "1525",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1525",
                                "groupId": 10,
                                "sortNum": 459,
                                "grade": 2,
                                "label": "AKMC_5ACP5",
                                "value": "641",
                                "sourceId": 1776
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1403",
                            "groupId": 10,
                            "sortNum": 462,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u7167\u660e",
                            "value": "1526",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1526",
                                "groupId": 10,
                                "sortNum": 463,
                                "grade": 2,
                                "label": "AKMC_4S5FL",
                                "value": "591",
                                "sourceId": 1847
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1403",
                            "groupId": 10,
                            "sortNum": 466,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u52a8\u529b",
                            "value": "1528",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1528",
                                "groupId": 10,
                                "sortNum": 467,
                                "grade": 2,
                                "label": "AKMC_4S5FP",
                                "value": "609",
                                "sourceId": 1846
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1403",
                            "groupId": 10,
                            "sortNum": 470,
                            "grade": 2,
                            "label": "\u56db\u671f\u52a8\u529b\u635f\u8017",
                            "value": "2033",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u56db\u671f\u603b\u52a8\u529b",
                                "parentId": "2033",
                                "groupId": 10,
                                "sortNum": 471,
                                "grade": 2,
                                "label": "AKMC_4SALLP",
                                "value": "590",
                                "sourceId": 3665
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1387",
                        "groupId": 10,
                        "sortNum": 475,
                        "grade": 2,
                        "label": "\u56db\u671f\u5382\u516d\u697c",
                        "value": "1404",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 476,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u538b1",
                            "value": "1530",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                                "parentId": "1530",
                                "groupId": 10,
                                "sortNum": 477,
                                "grade": 2,
                                "label": "AKMC_AIRC",
                                "value": "632",
                                "sourceId": 2007
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 480,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u84b8\u6c7d\u7528\u91cf",
                            "value": "1531",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1531",
                                "groupId": 10,
                                "sortNum": 481,
                                "grade": 2,
                                "label": "ZQLLJ4\u53826F",
                                "value": "1351",
                                "sourceId": 1829
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 484,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u5357\u4fa7\u51b0\u6c34\u673a",
                            "value": "1532",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1532",
                                "groupId": 10,
                                "sortNum": 485,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "651",
                                "sourceId": 1837
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 488,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u5907\u75283",
                            "value": "1533",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1533",
                                "groupId": 10,
                                "sortNum": 489,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "628",
                                "sourceId": 1833
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 492,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u5907\u75281",
                            "value": "1534",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1534",
                                "groupId": 10,
                                "sortNum": 493,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "633",
                                "sourceId": 1835
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 496,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u538b2",
                            "value": "1535",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                                "parentId": "1535",
                                "groupId": 10,
                                "sortNum": 497,
                                "grade": 2,
                                "label": "AKMC_AIRC5",
                                "value": "630",
                                "sourceId": 2008
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 500,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u5317\u4fa7\u51b0\u6c34\u673a1",
                            "value": "1536",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1536",
                                "groupId": 10,
                                "sortNum": 501,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "654",
                                "sourceId": 1840
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 504,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u6392\u70df\u7cfb\u7edf",
                            "value": "1537",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1537",
                                "groupId": 10,
                                "sortNum": 505,
                                "grade": 2,
                                "label": "AKMC_FIREF",
                                "value": "602",
                                "sourceId": 1842
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 508,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u51b7\u70ed\u6cf5",
                            "value": "1538",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1538",
                                "groupId": 10,
                                "sortNum": 509,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "639",
                                "sourceId": 1845
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 512,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u6cf5\u623f",
                            "value": "1539",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1539",
                                "groupId": 10,
                                "sortNum": 513,
                                "grade": 2,
                                "label": "AKMC_FIREP",
                                "value": "600",
                                "sourceId": 1831
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 516,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u673a\u623f\u7a7a\u8c03",
                            "value": "1540",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1540",
                                "groupId": 10,
                                "sortNum": 517,
                                "grade": 2,
                                "label": "AKMC_6ACP2",
                                "value": "652",
                                "sourceId": 1832
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 520,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u4e2d\u95f4\u51b0\u6c34\u673a",
                            "value": "1541",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "1541",
                                "groupId": 10,
                                "sortNum": 521,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "645",
                                "sourceId": 1834
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 524,
                            "grade": 2,
                            "label": "\u56db\u671f\u70ed\u56de\u6536\u6c34",
                            "value": "1542",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1542",
                                "groupId": 10,
                                "sortNum": 525,
                                "grade": 2,
                                "label": "AKMC_CLRTER",
                                "value": "1372",
                                "sourceId": 1836
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 528,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u5317\u4fa7\u51b0\u6c34\u673a2",
                            "value": "1543",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1543",
                                "groupId": 10,
                                "sortNum": 529,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "640",
                                "sourceId": 1839
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 532,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u51b7\u5374\u5854",
                            "value": "1544",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1544",
                                "groupId": 10,
                                "sortNum": 533,
                                "grade": 2,
                                "label": "AKMC_AP10",
                                "value": "648",
                                "sourceId": 1841
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 536,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u5907\u75282",
                            "value": "1545",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1545",
                                "groupId": 10,
                                "sortNum": 537,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "626",
                                "sourceId": 1843
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 540,
                            "grade": 2,
                            "label": "\u4e09\u56db\u671f\u7a7a\u8c03\u51b7\u5374\u6cf5",
                            "value": "1546",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1546",
                                "groupId": 10,
                                "sortNum": 541,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "621",
                                "sourceId": 1844
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1404",
                            "groupId": 10,
                            "sortNum": 544,
                            "grade": 2,
                            "label": "\u56db\u671f\u7535\u68af",
                            "value": "2031",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2031",
                                "groupId": 10,
                                "sortNum": 545,
                                "grade": 2,
                                "label": "AKMC_4SLA",
                                "value": "612",
                                "sourceId": 3666
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 550,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c",
                    "value": "1547",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1547",
                        "groupId": 10,
                        "sortNum": 551,
                        "grade": 2,
                        "label": "\u529e\u516c\u697c\u4e00\u697c",
                        "value": "1551",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1551",
                            "groupId": 10,
                            "sortNum": 552,
                            "grade": 2,
                            "label": "\u529e\u516c\u697c\u7528\u6c34",
                            "value": "1553",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1553",
                                "groupId": 10,
                                "sortNum": 553,
                                "grade": 2,
                                "label": "AKMC_WATER4",
                                "value": "846",
                                "sourceId": 1796
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1551",
                            "groupId": 10,
                            "sortNum": 556,
                            "grade": 2,
                            "label": "\u529e\u516c\u533a\u7a7a\u8c03",
                            "value": "1555",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1555",
                                "groupId": 10,
                                "sortNum": 557,
                                "grade": 2,
                                "label": "AKMC_OFKTX",
                                "value": "542",
                                "sourceId": 1734
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1551",
                            "groupId": 10,
                            "sortNum": 560,
                            "grade": 2,
                            "label": "\u529e\u516c\u533a\u7167\u660e\u63d2\u5ea7",
                            "value": "2020",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2020",
                                "groupId": 10,
                                "sortNum": 561,
                                "grade": 2,
                                "label": "AKMC_OFCA",
                                "value": "535",
                                "sourceId": 3664
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1547",
                        "groupId": 10,
                        "sortNum": 565,
                        "grade": 2,
                        "label": "\u529e\u516c\u697c\u4e8c\u697c",
                        "value": "1552",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1552",
                            "groupId": 10,
                            "sortNum": 566,
                            "grade": 2,
                            "label": "IT\u603b\u673a\u623f",
                            "value": "1556",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1556",
                                "groupId": 10,
                                "sortNum": 567,
                                "grade": 2,
                                "label": "AKMC_MISA",
                                "value": "556",
                                "sourceId": 1798
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1552",
                            "groupId": 10,
                            "sortNum": 570,
                            "grade": 2,
                            "label": "\u529e\u516c2F\u63d2\u5ea7\u5357",
                            "value": "1557",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1557",
                                "groupId": 10,
                                "sortNum": 571,
                                "grade": 2,
                                "label": "AKMC_OF2S",
                                "value": "541",
                                "sourceId": 1799
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1552",
                            "groupId": 10,
                            "sortNum": 574,
                            "grade": 2,
                            "label": "\u529e\u516c2F\u7167\u660e\u5357",
                            "value": "1558",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1558",
                                "groupId": 10,
                                "sortNum": 575,
                                "grade": 2,
                                "label": "AKMC_OF2L",
                                "value": "548",
                                "sourceId": 1797
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 580,
                    "grade": 2,
                    "label": "\u751f\u6d3b\u533a",
                    "value": "1549",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1549",
                        "groupId": 10,
                        "sortNum": 581,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5bbf\u820d",
                        "value": "1560",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1560",
                            "groupId": 10,
                            "sortNum": 582,
                            "grade": 2,
                            "label": "\u5927\u98df\u5802\u7528\u6c34",
                            "value": "1564",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1564",
                                "groupId": 10,
                                "sortNum": 583,
                                "grade": 2,
                                "label": "AKMC_WATER8",
                                "value": "824",
                                "sourceId": 1809
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1560",
                            "groupId": 10,
                            "sortNum": 586,
                            "grade": 2,
                            "label": "\u4e00\u671f\u5bbf\u820d\u63d2\u5ea7\/\u98df\u5802",
                            "value": "1565",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1565",
                                "groupId": 10,
                                "sortNum": 587,
                                "grade": 2,
                                "label": "\u5927\u98df\u5802\u53a8\u623f",
                                "value": "1569",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1569",
                                    "groupId": 10,
                                    "sortNum": 588,
                                    "grade": 2,
                                    "label": "AKMC_DSTDL",
                                    "value": "560",
                                    "sourceId": 1810
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1565",
                                "groupId": 10,
                                "sortNum": 591,
                                "grade": 2,
                                "label": "\u5927\u98df\u5802\u4fdd\u6e29\u53f0",
                                "value": "1570",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1570",
                                    "groupId": 10,
                                    "sortNum": 592,
                                    "grade": 2,
                                    "label": "AKMC_DSTBW",
                                    "value": "557",
                                    "sourceId": 1811
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1565",
                                "groupId": 10,
                                "sortNum": 595,
                                "grade": 2,
                                "label": "\u4e00\u671f\u5bbf\u820d\u63d2\u5ea7",
                                "value": "2026",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "2026",
                                    "groupId": 10,
                                    "sortNum": 596,
                                    "grade": 2,
                                    "label": "AKMC_DORMP",
                                    "value": "536",
                                    "sourceId": 2027
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1560",
                            "groupId": 10,
                            "sortNum": 600,
                            "grade": 2,
                            "label": "\u4e00\u671f\u5bbf\u820d\u697c\u7167\u660e",
                            "value": "1566",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1566",
                                "groupId": 10,
                                "sortNum": 601,
                                "grade": 2,
                                "label": "\u5927\u98df\u5802\u5927\u5385\u7167\u660e",
                                "value": "1572",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1572",
                                    "groupId": 10,
                                    "sortNum": 602,
                                    "grade": 2,
                                    "label": "AKMC_DSTDT",
                                    "value": "564",
                                    "sourceId": 1814
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1566",
                                "groupId": 10,
                                "sortNum": 605,
                                "grade": 2,
                                "label": "\u5927\u98df\u5802\u53a8\u623f\u7167\u660e",
                                "value": "1574",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "1574",
                                    "groupId": 10,
                                    "sortNum": 606,
                                    "grade": 2,
                                    "label": "AKMC_DSTCF",
                                    "value": "563",
                                    "sourceId": 1813
                                  }
                                ]
                              },
                              {
                                "orgId": 1,
                                "type": "node",
                                "description": "",
                                "parentId": "1566",
                                "groupId": 10,
                                "sortNum": 609,
                                "grade": 2,
                                "label": "\u4e00\u671f\u5bbf\u820d\u7167\u660e",
                                "value": "2028",
                                "children": [
                                  {
                                    "orgId": 1,
                                    "type": "Machine",
                                    "description": "",
                                    "parentId": "2028",
                                    "groupId": 10,
                                    "sortNum": 610,
                                    "grade": 2,
                                    "label": "AKMC_DORML",
                                    "value": "539",
                                    "sourceId": 2029
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1560",
                            "groupId": 10,
                            "sortNum": 614,
                            "grade": 2,
                            "label": "\u4e00\u671f\u5973\u5bbf\u820d\u7528\u6c34",
                            "value": "1568",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1568",
                                "groupId": 10,
                                "sortNum": 615,
                                "grade": 2,
                                "label": "AKMC_WATER10",
                                "value": "822",
                                "sourceId": 1812
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1560",
                            "groupId": 10,
                            "sortNum": 618,
                            "grade": 2,
                            "label": "\u4e00\u671f\u7537\u5bbf\u820d\u7528\u6c34",
                            "value": "2025",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2025",
                                "groupId": 10,
                                "sortNum": 619,
                                "grade": 2,
                                "label": "AKMC_WATER9",
                                "value": "825",
                                "sourceId": 2030
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1549",
                        "groupId": 10,
                        "sortNum": 623,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5bbf\u820d\u697c",
                        "value": "1562",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 624,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u5973\u5bbf\u820d\u7528\u6c34",
                            "value": "1575",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1575",
                                "groupId": 10,
                                "sortNum": 625,
                                "grade": 2,
                                "label": "AKMC_WATER12",
                                "value": "817",
                                "sourceId": 3670
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 628,
                            "grade": 2,
                            "label": "\u5c0f\u98df\u5802\u7528\u6c34",
                            "value": "1576",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1576",
                                "groupId": 10,
                                "sortNum": 629,
                                "grade": 2,
                                "label": "AKMC_WATER13",
                                "value": "819",
                                "sourceId": 1806
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 632,
                            "grade": 2,
                            "label": "\u9984\u9968\u5e97\u7528\u6c34",
                            "value": "1577",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1577",
                                "groupId": 10,
                                "sortNum": 633,
                                "grade": 2,
                                "label": "AKMC_WATER15",
                                "value": "821",
                                "sourceId": 1804
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 636,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u5bbf\u820d\u7167\u660e\u63d2\u5ea7",
                            "value": "1579",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e8c\u671f\u5bbf\u820d",
                                "parentId": "1579",
                                "groupId": 10,
                                "sortNum": 637,
                                "grade": 2,
                                "label": "AKMC_2DORM",
                                "value": "537",
                                "sourceId": 1980
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 640,
                            "grade": 2,
                            "label": "\u9984\u9968\u5e97\u53a8\u623f",
                            "value": "1580",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1580",
                                "groupId": 10,
                                "sortNum": 641,
                                "grade": 2,
                                "label": "AKMC_HDP",
                                "value": "561",
                                "sourceId": 1807
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 644,
                            "grade": 2,
                            "label": "\u5927\u98df\u5802\u7a7a\u8c03",
                            "value": "1581",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1581",
                                "groupId": 10,
                                "sortNum": 645,
                                "grade": 2,
                                "label": "AKMC_DSTKT",
                                "value": "559",
                                "sourceId": 1802
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 648,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u7537\u5bbf\u820d\u7528\u6c34",
                            "value": "1582",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1582",
                                "groupId": 10,
                                "sortNum": 649,
                                "grade": 2,
                                "label": "AKMC_WATER11",
                                "value": "818",
                                "sourceId": 3671
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 652,
                            "grade": 2,
                            "label": "\u9762\u5305\u623f\u53a8\u623f",
                            "value": "1583",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1583",
                                "groupId": 10,
                                "sortNum": 653,
                                "grade": 2,
                                "label": "AKMC_MBF",
                                "value": "558",
                                "sourceId": 1808
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 656,
                            "grade": 2,
                            "label": "\u5c0f\u98df\u5802\u53a8\u623f",
                            "value": "1584",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1584",
                                "groupId": 10,
                                "sortNum": 657,
                                "grade": 2,
                                "label": "AKMC_XST",
                                "value": "562",
                                "sourceId": 1803
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 660,
                            "grade": 2,
                            "label": "\u9762\u5305\u623f\u7528\u6c34",
                            "value": "1585",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1585",
                                "groupId": 10,
                                "sortNum": 661,
                                "grade": 2,
                                "label": "AKMC_WATER14",
                                "value": "816",
                                "sourceId": 1805
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1562",
                            "groupId": 10,
                            "sortNum": 664,
                            "grade": 2,
                            "label": "\u5c0f\u5356\u90e8\u51b0\u7bb1\u63d2\u5ea7",
                            "value": "1969",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1969",
                                "groupId": 10,
                                "sortNum": 665,
                                "grade": 2,
                                "label": "AKMC_XMP",
                                "value": "2000",
                                "sourceId": 2023
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1549",
                        "groupId": 10,
                        "sortNum": 669,
                        "grade": 2,
                        "label": "\u5e72\u90e8\u5bbf\u820d\u516c\u5bd3\u697c",
                        "value": "1559",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1559",
                            "groupId": 10,
                            "sortNum": 670,
                            "grade": 2,
                            "label": "\u5e72\u90e8\u98df\u5802",
                            "value": "1816",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1816",
                                "groupId": 10,
                                "sortNum": 671,
                                "grade": 2,
                                "label": "AKMC_SBOCDP",
                                "value": "576",
                                "sourceId": 1819
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1559",
                            "groupId": 10,
                            "sortNum": 674,
                            "grade": 2,
                            "label": "\u516c\u5bd3\u7167\u660e",
                            "value": "1817",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1817",
                                "groupId": 10,
                                "sortNum": 675,
                                "grade": 2,
                                "label": "AKMC_SBOCD",
                                "value": "540",
                                "sourceId": 1818
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1549",
                        "groupId": 10,
                        "sortNum": 679,
                        "grade": 2,
                        "label": "\u5e72\u90e8\u5bbf\u820d\u5bb6\u5ead\u697c",
                        "value": "1563",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "1563",
                            "groupId": 10,
                            "sortNum": 680,
                            "grade": 2,
                            "label": "AKMC_CDFB",
                            "value": "538",
                            "sourceId": 1815
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1549",
                        "groupId": 10,
                        "sortNum": 683,
                        "grade": 2,
                        "label": "\u751f\u6d3b\u533a\u84b8\u6c7d\u7528\u91cf",
                        "value": "1561",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "1561",
                            "groupId": 10,
                            "sortNum": 684,
                            "grade": 2,
                            "label": "ZQLLJ\u751f\u6d3b\u533a",
                            "value": "1352",
                            "sourceId": 1820
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1386",
                    "groupId": 10,
                    "sortNum": 688,
                    "grade": 2,
                    "label": "\u8b66\u536b\u5ba4",
                    "value": "1548",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1548",
                        "groupId": 10,
                        "sortNum": 689,
                        "grade": 2,
                        "label": "\u5317\u8b66\u536b\u5ba4",
                        "value": "1586",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "1586",
                            "groupId": 10,
                            "sortNum": 690,
                            "grade": 2,
                            "label": "AKMC_NGROOM",
                            "value": "532",
                            "sourceId": 1801
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1548",
                        "groupId": 10,
                        "sortNum": 693,
                        "grade": 2,
                        "label": "\u5357\u8b66\u536b\u5ba4",
                        "value": "1587",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "1587",
                            "groupId": 10,
                            "sortNum": 694,
                            "grade": 2,
                            "label": "AKMC_SGROOM",
                            "value": "534",
                            "sourceId": 1800
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "1385",
                "groupId": 10,
                "sortNum": 699,
                "grade": 2,
                "label": "\u4e94\u671f",
                "value": "1411",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 700,
                    "grade": 2,
                    "label": "A\u680b",
                    "value": "1592",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1592",
                        "groupId": 10,
                        "sortNum": 701,
                        "grade": 2,
                        "label": "A\u680b\u4e00\u697c",
                        "value": "1594",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 702,
                            "grade": 2,
                            "label": "A\u680b1F\u7c89\u70e4\u7ebf",
                            "value": "1597",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1597",
                                "groupId": 10,
                                "sortNum": 703,
                                "grade": 2,
                                "label": "YZ_A1FFX",
                                "value": "445",
                                "sourceId": 1868
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 706,
                            "grade": 2,
                            "label": "\u5e94\u6025\u6c34\u6c60\u5e9f\u6c14\u5854",
                            "value": "1598",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1598",
                                "groupId": 10,
                                "sortNum": 707,
                                "grade": 2,
                                "label": "YZ_FQ3",
                                "value": "504",
                                "sourceId": 1855
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 710,
                            "grade": 2,
                            "label": "A\u680b\u8d27\u68af",
                            "value": "1599",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1599",
                                "groupId": 10,
                                "sortNum": 711,
                                "grade": 2,
                                "label": "YZ_ADT",
                                "value": "508",
                                "sourceId": 1856
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 714,
                            "grade": 2,
                            "label": "A\u680b\u751f\u6d3b\u7528\u6c34",
                            "value": "1600",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1600",
                                "groupId": 10,
                                "sortNum": 715,
                                "grade": 2,
                                "label": "AKMC_WATER5ASH",
                                "value": "827",
                                "sourceId": 1866
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 718,
                            "grade": 2,
                            "label": "A\u680b\u5faa\u73af\u6c34\u6c60",
                            "value": "1601",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1601",
                                "groupId": 10,
                                "sortNum": 719,
                                "grade": 2,
                                "label": "YZ_AXHSC",
                                "value": "443",
                                "sourceId": 1857
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 722,
                            "grade": 2,
                            "label": "\u5e9f\u6c34\u5904\u7406\u7a7a\u538b\u6d41\u91cf",
                            "value": "1602",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1602",
                                "groupId": 10,
                                "sortNum": 723,
                                "grade": 2,
                                "label": "YZ_5AFSZKY",
                                "value": "1279",
                                "sourceId": 1867
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 726,
                            "grade": 2,
                            "label": "A\u680b\u6d88\u9632\u7528\u7535",
                            "value": "1603",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1603",
                                "groupId": 10,
                                "sortNum": 727,
                                "grade": 2,
                                "label": "YZ_AXF",
                                "value": "519",
                                "sourceId": 1858
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 730,
                            "grade": 2,
                            "label": "A\u680b1F\u897f\u5357\u7167\u660e",
                            "value": "1604",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1604",
                                "groupId": 10,
                                "sortNum": 731,
                                "grade": 2,
                                "label": "YZ_AZ1FXN",
                                "value": "447",
                                "sourceId": 1859
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 734,
                            "grade": 2,
                            "label": "A\u680b\u5176\u4ed6\u7167\u660e",
                            "value": "1605",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1605",
                                "groupId": 10,
                                "sortNum": 735,
                                "grade": 2,
                                "label": "YZ_AZZM",
                                "value": "499",
                                "sourceId": 3685
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 738,
                            "grade": 2,
                            "label": "A\u680b1F\u6f06\u6e23\u623f",
                            "value": "1606",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1606",
                                "groupId": 10,
                                "sortNum": 739,
                                "grade": 2,
                                "label": "YZ_AQZF",
                                "value": "456",
                                "sourceId": 1860
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 742,
                            "grade": 2,
                            "label": "A\u680b1F\u4e1c\u5357\u7167\u660e",
                            "value": "1607",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1607",
                                "groupId": 10,
                                "sortNum": 743,
                                "grade": 2,
                                "label": "YZ_AZ1FDN",
                                "value": "454",
                                "sourceId": 1861
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 746,
                            "grade": 2,
                            "label": "A\u680b1F\u7a7a\u8c03\u7bb1",
                            "value": "1608",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1608",
                                "groupId": 10,
                                "sortNum": 747,
                                "grade": 2,
                                "label": "YZ_A1FKTX",
                                "value": "450",
                                "sourceId": 1862
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 750,
                            "grade": 2,
                            "label": "A\u680b1F\u5317\u7167\u660e",
                            "value": "1609",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1609",
                                "groupId": 10,
                                "sortNum": 751,
                                "grade": 2,
                                "label": "YZ_AZ1FB",
                                "value": "446",
                                "sourceId": 1863
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 754,
                            "grade": 2,
                            "label": "A\u680b1F\u7a7a\u538b\u6d41\u91cf",
                            "value": "1610",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1610",
                                "groupId": 10,
                                "sortNum": 755,
                                "grade": 2,
                                "label": "YZ_5A1FKY",
                                "value": "1280",
                                "sourceId": 1864
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1594",
                            "groupId": 10,
                            "sortNum": 758,
                            "grade": 2,
                            "label": "A\u680b\u751f\u4ea7\u7528\u6c34",
                            "value": "1611",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1611",
                                "groupId": 10,
                                "sortNum": 759,
                                "grade": 2,
                                "label": "AKMC_WATER5ASC",
                                "value": "834",
                                "sourceId": 1865
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1592",
                        "groupId": 10,
                        "sortNum": 763,
                        "grade": 2,
                        "label": "A\u680b\u9876\u697c",
                        "value": "1595",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1595",
                            "groupId": 10,
                            "sortNum": 764,
                            "grade": 2,
                            "label": "A\u680b\u697c\u9876\u7a7a\u538b\u673a\u623f",
                            "value": "1621",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u7a7a\u538b\u7ad9",
                                "parentId": "1621",
                                "groupId": 10,
                                "sortNum": 765,
                                "grade": 2,
                                "label": "YZ_AKYJ",
                                "value": "441",
                                "sourceId": 2005
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1595",
                            "groupId": 10,
                            "sortNum": 768,
                            "grade": 2,
                            "label": "A\u680b\u697c\u9876\u5e9f\u6c14\u8bbe\u59071",
                            "value": "1622",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fA\u680b\u6db2\u70e4\u5e9f\u6c141",
                                "parentId": "1622",
                                "groupId": 10,
                                "sortNum": 769,
                                "grade": 2,
                                "label": "YZ_FQ1",
                                "value": "513",
                                "sourceId": 1870
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1595",
                            "groupId": 10,
                            "sortNum": 772,
                            "grade": 2,
                            "label": "A\u680b\u697c\u9876\u5e9f\u6c14\u8bbe\u59072",
                            "value": "1623",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fA\u680b\u6db2\u70e4\u5e9f\u6c142",
                                "parentId": "1623",
                                "groupId": 10,
                                "sortNum": 773,
                                "grade": 2,
                                "label": "YZ_FQ2",
                                "value": "520",
                                "sourceId": 1871
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1595",
                            "groupId": 10,
                            "sortNum": 776,
                            "grade": 2,
                            "label": "A\u680b\u7a7a\u538b\u70ed\u56de\u6536\u6c34",
                            "value": "1624",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1624",
                                "groupId": 10,
                                "sortNum": 777,
                                "grade": 2,
                                "label": "AKMC_CLRTER5A",
                                "value": "1371",
                                "sourceId": 1872
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1592",
                        "groupId": 10,
                        "sortNum": 781,
                        "grade": 2,
                        "label": "A\u680b\u4e8c\u697c",
                        "value": "1596",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 782,
                            "grade": 2,
                            "label": "A\u680b2F\u4e1d\u5370\u7ebf\u4e1c",
                            "value": "1612",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1612",
                                "groupId": 10,
                                "sortNum": 783,
                                "grade": 2,
                                "label": "YZ_ASY2",
                                "value": "452",
                                "sourceId": 1873
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 786,
                            "grade": 2,
                            "label": "A\u680b2F\u7a7a\u538b\u6d41\u91cf",
                            "value": "1613",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1613",
                                "groupId": 10,
                                "sortNum": 787,
                                "grade": 2,
                                "label": "YZ_5A2FKY",
                                "value": "1277",
                                "sourceId": 1874
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 790,
                            "grade": 2,
                            "label": "A\u680bES\u6253\u6837\u55b7\u623f",
                            "value": "1614",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1614",
                                "groupId": 10,
                                "sortNum": 791,
                                "grade": 2,
                                "label": "YZ_A2FES",
                                "value": "444",
                                "sourceId": 1875
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 794,
                            "grade": 2,
                            "label": "A\u680b2F\u897f\u5357\u7167\u660e",
                            "value": "1615",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1615",
                                "groupId": 10,
                                "sortNum": 795,
                                "grade": 2,
                                "label": "YZ_AZ2FXN",
                                "value": "451",
                                "sourceId": 1876
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 798,
                            "grade": 2,
                            "label": "A\u680b2F\u4e1c\u5357\u7167\u660e",
                            "value": "1616",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1616",
                                "groupId": 10,
                                "sortNum": 799,
                                "grade": 2,
                                "label": "YZ_AZ2FDN",
                                "value": "455",
                                "sourceId": 1877
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 802,
                            "grade": 2,
                            "label": "A\u680b2F\u6db2\u70e4\u7ebf",
                            "value": "1617",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1617",
                                "groupId": 10,
                                "sortNum": 803,
                                "grade": 2,
                                "label": "YZ_A2FYTX",
                                "value": "442",
                                "sourceId": 1878
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 806,
                            "grade": 2,
                            "label": "A\u680b2F\u5317\u7167\u660e",
                            "value": "1618",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1618",
                                "groupId": 10,
                                "sortNum": 807,
                                "grade": 2,
                                "label": "YZ_AZ2FB",
                                "value": "453",
                                "sourceId": 1879
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 810,
                            "grade": 2,
                            "label": "A\u680b2F\u7a7a\u8c03\u7bb1",
                            "value": "1619",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1619",
                                "groupId": 10,
                                "sortNum": 811,
                                "grade": 2,
                                "label": "YZ_A2FKTX",
                                "value": "449",
                                "sourceId": 1730
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1596",
                            "groupId": 10,
                            "sortNum": 814,
                            "grade": 2,
                            "label": "A\u680b2F\u4e1d\u5370\u7ebf\u897f",
                            "value": "1620",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1620",
                                "groupId": 10,
                                "sortNum": 815,
                                "grade": 2,
                                "label": "YZ_ASY1",
                                "value": "440",
                                "sourceId": 1880
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 820,
                    "grade": 2,
                    "label": "B\u680b",
                    "value": "1591",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1591",
                        "groupId": 10,
                        "sortNum": 821,
                        "grade": 2,
                        "label": "B\u680b\u4e8c\u697c",
                        "value": "1625",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 822,
                            "grade": 2,
                            "label": "B\u680b2F\u5e73\u53f0\u5e9f\u6c14",
                            "value": "1652",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1652",
                                "groupId": 10,
                                "sortNum": 823,
                                "grade": 2,
                                "label": "YZ_B2FFQ",
                                "value": "468",
                                "sourceId": 1881
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 826,
                            "grade": 2,
                            "label": "B\u680b2F\u7528\u6c34",
                            "value": "1653",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1653",
                                "groupId": 10,
                                "sortNum": 827,
                                "grade": 2,
                                "label": "AKMC_WATER5B2F",
                                "value": "826",
                                "sourceId": 1882
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 830,
                            "grade": 2,
                            "label": "B\u680b2F\u5357\u7167\u660e",
                            "value": "1654",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1654",
                                "groupId": 10,
                                "sortNum": 831,
                                "grade": 2,
                                "label": "YZ_BZ2FN",
                                "value": "480",
                                "sourceId": 1883
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 834,
                            "grade": 2,
                            "label": "B\u680b2F\u7a7a\u538b\u6d41\u91cf",
                            "value": "1655",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1655",
                                "groupId": 10,
                                "sortNum": 835,
                                "grade": 2,
                                "label": "YZ_5B2FKY",
                                "value": "1278",
                                "sourceId": 1884
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 838,
                            "grade": 2,
                            "label": "B\u680b2F\u6d41\u6c34\u7ebf",
                            "value": "1656",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1656",
                                "groupId": 10,
                                "sortNum": 839,
                                "grade": 2,
                                "label": "YZ_BTZXB",
                                "value": "466",
                                "sourceId": 1885
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 842,
                            "grade": 2,
                            "label": "B\u680b2F\u4e1c\u5317\u7167\u660e",
                            "value": "1657",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1657",
                                "groupId": 10,
                                "sortNum": 843,
                                "grade": 2,
                                "label": "YZ_BZ2FDB",
                                "value": "478",
                                "sourceId": 1886
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 846,
                            "grade": 2,
                            "label": "B\u680b2F\u7a7a\u8c03\u7bb1",
                            "value": "1658",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1658",
                                "groupId": 10,
                                "sortNum": 847,
                                "grade": 2,
                                "label": "YZ_B2FKTX",
                                "value": "486",
                                "sourceId": 1731
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 850,
                            "grade": 2,
                            "label": "B\u680b2F\u7c89\u70e4\u7ebf",
                            "value": "1659",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1659",
                                "groupId": 10,
                                "sortNum": 851,
                                "grade": 2,
                                "label": "YZ_BFXX",
                                "value": "464",
                                "sourceId": 1887
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1625",
                            "groupId": 10,
                            "sortNum": 854,
                            "grade": 2,
                            "label": "B\u680b2F\u897f\u5317\u7167\u660e",
                            "value": "1660",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1660",
                                "groupId": 10,
                                "sortNum": 855,
                                "grade": 2,
                                "label": "YZ_BZ2FXB",
                                "value": "471",
                                "sourceId": 1888
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1591",
                        "groupId": 10,
                        "sortNum": 859,
                        "grade": 2,
                        "label": "B\u680b\u4e00\u697c",
                        "value": "1626",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 860,
                            "grade": 2,
                            "label": "B\u680b1F\u7c89\u70e4\u7ebf",
                            "value": "1629",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1629",
                                "groupId": 10,
                                "sortNum": 861,
                                "grade": 2,
                                "label": "YZ_B1FDLZ",
                                "value": "491",
                                "sourceId": 1889
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 864,
                            "grade": 2,
                            "label": "B\u680b\u8336\u6c34\u95f4\u7a7a\u8c03",
                            "value": "1630",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1630",
                                "groupId": 10,
                                "sortNum": 865,
                                "grade": 2,
                                "label": "YZ_BCSJKT",
                                "value": "460",
                                "sourceId": 1890
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 868,
                            "grade": 2,
                            "label": "B\u680b1F\u897f\u5317\u7167\u660e",
                            "value": "1631",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1631",
                                "groupId": 10,
                                "sortNum": 869,
                                "grade": 2,
                                "label": "YZ_BZ1FXB",
                                "value": "472",
                                "sourceId": 1891
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 872,
                            "grade": 2,
                            "label": "B\u680b\u8d27\u68af",
                            "value": "1632",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1632",
                                "groupId": 10,
                                "sortNum": 873,
                                "grade": 2,
                                "label": "YZ_BDT",
                                "value": "502",
                                "sourceId": 1892
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 876,
                            "grade": 2,
                            "label": "B\u680b1F\u7528\u6c34",
                            "value": "1633",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1633",
                                "groupId": 10,
                                "sortNum": 877,
                                "grade": 2,
                                "label": "AKMC_WATER5B1F",
                                "value": "831",
                                "sourceId": 1893
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 880,
                            "grade": 2,
                            "label": "B\u680b1F\u62c9\u4e1d\u673a",
                            "value": "1634",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1634",
                                "groupId": 10,
                                "sortNum": 881,
                                "grade": 2,
                                "label": "YZ_BLSJ",
                                "value": "437",
                                "sourceId": 1894
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 884,
                            "grade": 2,
                            "label": "B\u680b\u751f\u4ea7\u7528\u6c34",
                            "value": "1635",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1635",
                                "groupId": 10,
                                "sortNum": 885,
                                "grade": 2,
                                "label": "AKMC_WATER5BSC",
                                "value": "844",
                                "sourceId": 1895
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 888,
                            "grade": 2,
                            "label": "B\u680b\u751f\u6d3b\u7528\u6c34",
                            "value": "1636",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1636",
                                "groupId": 10,
                                "sortNum": 889,
                                "grade": 2,
                                "label": "AKMC_WATER5BSH",
                                "value": "836",
                                "sourceId": 1896
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 892,
                            "grade": 2,
                            "label": "B\u680b1F\u4e1c\u5317\u7167\u660e",
                            "value": "1637",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1637",
                                "groupId": 10,
                                "sortNum": 893,
                                "grade": 2,
                                "label": "YZ_BZ1FDB",
                                "value": "469",
                                "sourceId": 1897
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 896,
                            "grade": 2,
                            "label": "B\u680b1F\u6298\u5f2f\u7a7a\u8c03",
                            "value": "1638",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1638",
                                "groupId": 10,
                                "sortNum": 897,
                                "grade": 2,
                                "label": "YZ_B1FXKT",
                                "value": "439",
                                "sourceId": 1898
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 900,
                            "grade": 2,
                            "label": "B\u680b\u5176\u4ed6\u7167\u660e",
                            "value": "1639",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1639",
                                "groupId": 10,
                                "sortNum": 901,
                                "grade": 2,
                                "label": "YZ_BZZM",
                                "value": "498",
                                "sourceId": 3682
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 904,
                            "grade": 2,
                            "label": "B\u680b\u6d88\u9632\u7528\u6c34",
                            "value": "1640",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1640",
                                "groupId": 10,
                                "sortNum": 905,
                                "grade": 2,
                                "label": "AKMC_WATER5BXF",
                                "value": "833",
                                "sourceId": 3683
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 908,
                            "grade": 2,
                            "label": "B\u680b1F\u94b3\u5de5\u7528\u7535",
                            "value": "1641",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1641",
                                "groupId": 10,
                                "sortNum": 909,
                                "grade": 2,
                                "label": "YZ_BQGQ",
                                "value": "474",
                                "sourceId": 1899
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 912,
                            "grade": 2,
                            "label": "B\u680b1F\u629b\u4e38\u673a",
                            "value": "1642",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1642",
                                "groupId": 10,
                                "sortNum": 913,
                                "grade": 2,
                                "label": "YZ_BPWJ",
                                "value": "489",
                                "sourceId": 1900
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 916,
                            "grade": 2,
                            "label": "B\u680b1F\u7a7a\u538b\u7528\u91cf",
                            "value": "1643",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1643",
                                "groupId": 10,
                                "sortNum": 917,
                                "grade": 2,
                                "label": "YZ_5B1FKY",
                                "value": "1276",
                                "sourceId": 1901
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 920,
                            "grade": 2,
                            "label": "B\u680b1F\u7a7a\u8c03\u7bb1",
                            "value": "1644",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1644",
                                "groupId": 10,
                                "sortNum": 921,
                                "grade": 2,
                                "label": "YZ_B1FKTX",
                                "value": "483",
                                "sourceId": 1732
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 924,
                            "grade": 2,
                            "label": "B\u680b1F\u5357\u7167\u660e",
                            "value": "1645",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1645",
                                "groupId": 10,
                                "sortNum": 925,
                                "grade": 2,
                                "label": "YZ_BZ1FN",
                                "value": "490",
                                "sourceId": 1902
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 928,
                            "grade": 2,
                            "label": "B\u680b1F\u94c6\u9489\u7528\u7535",
                            "value": "1646",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1646",
                                "groupId": 10,
                                "sortNum": 929,
                                "grade": 2,
                                "label": "YZ_BMDQ",
                                "value": "458",
                                "sourceId": 1903
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 932,
                            "grade": 2,
                            "label": "B\u680b1F\u6298\u5f2f\u7535\u67dc1",
                            "value": "1647",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1647",
                                "groupId": 10,
                                "sortNum": 933,
                                "grade": 2,
                                "label": "YZ_BZW1",
                                "value": "461",
                                "sourceId": 3684
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 936,
                            "grade": 2,
                            "label": "B\u680b1F\u6298\u5f2f\u7535\u67dc2",
                            "value": "1648",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1648",
                                "groupId": 10,
                                "sortNum": 937,
                                "grade": 2,
                                "label": "YZ_BZW2",
                                "value": "488",
                                "sourceId": 1904
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 940,
                            "grade": 2,
                            "label": "B\u680b1F\u51b2\u5e8a\/\u55b7\u7802",
                            "value": "1649",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1649",
                                "groupId": 10,
                                "sortNum": 941,
                                "grade": 2,
                                "label": "YZ_BCC",
                                "value": "473",
                                "sourceId": 1955
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 944,
                            "grade": 2,
                            "label": "B\u680b\u6d88\u9632\u7528\u7535",
                            "value": "1650",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1650",
                                "groupId": 10,
                                "sortNum": 945,
                                "grade": 2,
                                "label": "YZ_BXF",
                                "value": "510",
                                "sourceId": 1905
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1626",
                            "groupId": 10,
                            "sortNum": 948,
                            "grade": 2,
                            "label": "B\u680b1F\u70b9\u710a\u7ebf",
                            "value": "1651",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1651",
                                "groupId": 10,
                                "sortNum": 949,
                                "grade": 2,
                                "label": "YZ_BDH",
                                "value": "475",
                                "sourceId": 1906
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1591",
                        "groupId": 10,
                        "sortNum": 953,
                        "grade": 2,
                        "label": "B\u680b\u9876\u697c",
                        "value": "1627",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1627",
                            "groupId": 10,
                            "sortNum": 954,
                            "grade": 2,
                            "label": "B\u680b\u9876\u697c\u5e9f\u6c14\u5854",
                            "value": "1671",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1671",
                                "groupId": 10,
                                "sortNum": 955,
                                "grade": 2,
                                "label": "YZ_B4FFQ",
                                "value": "467",
                                "sourceId": 1907
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1591",
                        "groupId": 10,
                        "sortNum": 959,
                        "grade": 2,
                        "label": "B\u680b\u4e09\u697c",
                        "value": "1628",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 960,
                            "grade": 2,
                            "label": "B\u680b3F\u6d41\u6c34\u7ebf",
                            "value": "1661",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1661",
                                "groupId": 10,
                                "sortNum": 961,
                                "grade": 2,
                                "label": "YZ_BTZXA",
                                "value": "479",
                                "sourceId": 1908
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 964,
                            "grade": 2,
                            "label": "B\u680b3F\u5357\u7167\u660e",
                            "value": "1662",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1662",
                                "groupId": 10,
                                "sortNum": 965,
                                "grade": 2,
                                "label": "YZ_BZ3FN",
                                "value": "487",
                                "sourceId": 1909
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 968,
                            "grade": 2,
                            "label": "B\u680b3F\u7528\u6c34",
                            "value": "1663",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1663",
                                "groupId": 10,
                                "sortNum": 969,
                                "grade": 2,
                                "label": "AKMC_WATER5QCL",
                                "value": "840",
                                "sourceId": 1911
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 972,
                            "grade": 2,
                            "label": "B\u680b3F\u524d\u5904\u7406",
                            "value": "1664",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1664",
                                "groupId": 10,
                                "sortNum": 973,
                                "grade": 2,
                                "label": "YZ_BQCL",
                                "value": "484",
                                "sourceId": 1910
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 976,
                            "grade": 2,
                            "label": "B\u680b3F\u897f\u5317\u7167\u660e",
                            "value": "1665",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1665",
                                "groupId": 10,
                                "sortNum": 977,
                                "grade": 2,
                                "label": "YZ_BZ3FXB",
                                "value": "481",
                                "sourceId": 1912
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 980,
                            "grade": 2,
                            "label": "B\u680b3F\u62c9\u4e1d\u7ebf",
                            "value": "1666",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1666",
                                "groupId": 10,
                                "sortNum": 981,
                                "grade": 2,
                                "label": "YZ_BZDLS",
                                "value": "1170",
                                "sourceId": 1913
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 984,
                            "grade": 2,
                            "label": "B\u680b3F\u5939\u5c42\u7167\u660e",
                            "value": "1667",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1667",
                                "groupId": 10,
                                "sortNum": 985,
                                "grade": 2,
                                "label": "YZ_BZ3A",
                                "value": "457",
                                "sourceId": 1914
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 988,
                            "grade": 2,
                            "label": "B\u680b3F\u7a7a\u8c03\u7bb1",
                            "value": "1668",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1668",
                                "groupId": 10,
                                "sortNum": 989,
                                "grade": 2,
                                "label": "YZ_B3FKTX",
                                "value": "476",
                                "sourceId": 1733
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 992,
                            "grade": 2,
                            "label": "B\u680b3F\u4e1c\u5317\u7167\u660e",
                            "value": "1669",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1669",
                                "groupId": 10,
                                "sortNum": 993,
                                "grade": 2,
                                "label": "YZ_BZ3FDB",
                                "value": "459",
                                "sourceId": 1915
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1628",
                            "groupId": 10,
                            "sortNum": 996,
                            "grade": 2,
                            "label": "B\u680b3F\u7a7a\u538b\u6d41\u91cf",
                            "value": "1670",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1670",
                                "groupId": 10,
                                "sortNum": 997,
                                "grade": 2,
                                "label": "YZ_5B3FKY",
                                "value": "1281",
                                "sourceId": 1916
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 1002,
                    "grade": 2,
                    "label": "C\u680b",
                    "value": "1588",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1588",
                        "groupId": 10,
                        "sortNum": 1003,
                        "grade": 2,
                        "label": "C\u680b\u4e00\u697c",
                        "value": "1672",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1672",
                            "groupId": 10,
                            "sortNum": 1004,
                            "grade": 2,
                            "label": "C\u680b\u65b0\u589e\u6570\u63a7\u51b2\u5e8a",
                            "value": "1673",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1673",
                                "groupId": 10,
                                "sortNum": 1005,
                                "grade": 2,
                                "label": "YZ_JCF1",
                                "value": "501",
                                "sourceId": 1922
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1672",
                            "groupId": 10,
                            "sortNum": 1008,
                            "grade": 2,
                            "label": "C\u680b\u956d\u5c04\/\u6570\u63a7",
                            "value": "1674",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fC\/D\u680b\u52a8\u529b",
                                "parentId": "1674",
                                "groupId": 10,
                                "sortNum": 1009,
                                "grade": 2,
                                "label": "YZ_JCF",
                                "value": "521",
                                "sourceId": 3681
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 1014,
                    "grade": 2,
                    "label": "D\u680b",
                    "value": "1593",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1593",
                        "groupId": 10,
                        "sortNum": 1015,
                        "grade": 2,
                        "label": "D\u680b\u4e00\u697c",
                        "value": "1675",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1675",
                            "groupId": 10,
                            "sortNum": 1016,
                            "grade": 2,
                            "label": "D\u680b\u603b\u7528\u7535",
                            "value": "1676",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1676",
                                "groupId": 10,
                                "sortNum": 1017,
                                "grade": 2,
                                "label": "YZ_JCFB",
                                "value": "438",
                                "sourceId": 1921
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 1022,
                    "grade": 2,
                    "label": "\u4e94\u671f\u751f\u6d3b\u533a",
                    "value": "1589",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1589",
                        "groupId": 10,
                        "sortNum": 1023,
                        "grade": 2,
                        "label": "\u751f\u6d3b\u533a\u4e00\u697c",
                        "value": "1699",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1699",
                            "groupId": 10,
                            "sortNum": 1024,
                            "grade": 2,
                            "label": "\u751f\u6d3b\u533a\u5bbf\u820d2",
                            "value": "1700",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5bbf\u820d\u7167\u660e2",
                                "parentId": "1700",
                                "groupId": 10,
                                "sortNum": 1025,
                                "grade": 2,
                                "label": "YZ_SHQ2",
                                "value": "509",
                                "sourceId": 1917
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1699",
                            "groupId": 10,
                            "sortNum": 1028,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5bbf\u820d\u6c34\u8868",
                            "value": "1701",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1701",
                                "groupId": 10,
                                "sortNum": 1029,
                                "grade": 2,
                                "label": "AKMC_WATER5SS",
                                "value": "837",
                                "sourceId": 1854
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1699",
                            "groupId": 10,
                            "sortNum": 1032,
                            "grade": 2,
                            "label": "\u751f\u6d3b\u533a\u5bbf\u820d1",
                            "value": "1702",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5bbf\u820d\u7167\u660e1",
                                "parentId": "1702",
                                "groupId": 10,
                                "sortNum": 1033,
                                "grade": 2,
                                "label": "YZ_SHQ1",
                                "value": "495",
                                "sourceId": 1918
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1699",
                            "groupId": 10,
                            "sortNum": 1036,
                            "grade": 2,
                            "label": "\u751f\u6d3b\u533a\u5bbf\u820d3",
                            "value": "1703",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5bbf\u820d\u7167\u660e3",
                                "parentId": "1703",
                                "groupId": 10,
                                "sortNum": 1037,
                                "grade": 2,
                                "label": "YZ_SHQ3",
                                "value": "500",
                                "sourceId": 1919
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1699",
                            "groupId": 10,
                            "sortNum": 1040,
                            "grade": 2,
                            "label": "\u4e94\u671f\u98df\u5802\u6c34\u8868",
                            "value": "1704",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1704",
                                "groupId": 10,
                                "sortNum": 1041,
                                "grade": 2,
                                "label": "AKMC_WATER5ST",
                                "value": "838",
                                "sourceId": 1920
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "1411",
                    "groupId": 10,
                    "sortNum": 1046,
                    "grade": 2,
                    "label": "\u516c\u5171",
                    "value": "1590",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "1590",
                        "groupId": 10,
                        "sortNum": 1047,
                        "grade": 2,
                        "label": "\u516c\u5171\u4e00\u697c",
                        "value": "1677",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1048,
                            "grade": 2,
                            "label": "\u4e94\u671f\u84b8\u6c7d\u7528\u91cf",
                            "value": "1678",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1678",
                                "groupId": 10,
                                "sortNum": 1049,
                                "grade": 2,
                                "label": "ZQLLJ5\u5382",
                                "value": "1353",
                                "sourceId": 1923
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1052,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5371\u5316\u54c1\u4ed3\u7167\u660e",
                            "value": "1679",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1679",
                                "groupId": 10,
                                "sortNum": 1053,
                                "grade": 2,
                                "label": "YZ_CLC",
                                "value": "511",
                                "sourceId": 1924
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1056,
                            "grade": 2,
                            "label": "\u6cb9\u6f06\u4ed3\u5e93\u7a7a\u8c03",
                            "value": "1680",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1680",
                                "groupId": 10,
                                "sortNum": 1057,
                                "grade": 2,
                                "label": "YZ_WHCK",
                                "value": "514",
                                "sourceId": 1925
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1060,
                            "grade": 2,
                            "label": "\u4e94\u671f\u6cb9\u6f06\u4ed3\u5e93\u7528\u6c34",
                            "value": "1681",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1681",
                                "groupId": 10,
                                "sortNum": 1061,
                                "grade": 2,
                                "label": "AKMC_WATER5WK",
                                "value": "829",
                                "sourceId": 1926
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1064,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5e9f\u6c34\u5904\u7406",
                            "value": "1682",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1682",
                                "groupId": 10,
                                "sortNum": 1065,
                                "grade": 2,
                                "label": "YZ_FSZ2",
                                "value": "505",
                                "sourceId": 1927
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1068,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5e9f\u6c34\u7ad9\u7528\u6c341",
                            "value": "1683",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1683",
                                "groupId": 10,
                                "sortNum": 1069,
                                "grade": 2,
                                "label": "AKMC_WATER5FS1",
                                "value": "830",
                                "sourceId": 1928
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1072,
                            "grade": 2,
                            "label": "\u5317\u51b0\u6c34\u4e3b\u673a",
                            "value": "1684",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5317\u51b0\u6c34\u673a",
                                "parentId": "1684",
                                "groupId": 10,
                                "sortNum": 1073,
                                "grade": 2,
                                "label": "YZ_BSJ2",
                                "value": "503",
                                "sourceId": 1929
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1076,
                            "grade": 2,
                            "label": "\u4e94\u671f\u6d88\u9632\u7528\u6c34",
                            "value": "1685",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1685",
                                "groupId": 10,
                                "sortNum": 1077,
                                "grade": 2,
                                "label": "AKMC_WATER5XF",
                                "value": "843",
                                "sourceId": 1930
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1080,
                            "grade": 2,
                            "label": "\u8def\u706f\u7528\u7535",
                            "value": "1686",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1686",
                                "groupId": 10,
                                "sortNum": 1081,
                                "grade": 2,
                                "label": "YZ_LD",
                                "value": "522",
                                "sourceId": 1931
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1084,
                            "grade": 2,
                            "label": "\u7a7a\u8c03\u673a\u623f\u6c34\u6cf5",
                            "value": "1687",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1687",
                                "groupId": 10,
                                "sortNum": 1085,
                                "grade": 2,
                                "label": "YZ_KTSB",
                                "value": "516",
                                "sourceId": 1932
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1088,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5382\u533a\u603b\u7167\u660e",
                            "value": "1688",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1688",
                                "groupId": 10,
                                "sortNum": 1089,
                                "grade": 2,
                                "label": "YZ_ZZM",
                                "value": "512",
                                "sourceId": 3680
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1092,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5e9f\u6c34\u7ad9\u7528\u6c342",
                            "value": "1689",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1689",
                                "groupId": 10,
                                "sortNum": 1093,
                                "grade": 2,
                                "label": "AKMC_WATER5FS2",
                                "value": "828",
                                "sourceId": 1933
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1096,
                            "grade": 2,
                            "label": "\u53d1\u7535\u673a\u53d1\u7535\u91cf",
                            "value": "1690",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1690",
                                "groupId": 10,
                                "sortNum": 1097,
                                "grade": 2,
                                "label": "YZ_FDJ",
                                "value": "517",
                                "sourceId": 1934
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1100,
                            "grade": 2,
                            "label": "\u914d\u7535\u623f\u6392\u6c34\u6cf5",
                            "value": "1691",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1691",
                                "groupId": 10,
                                "sortNum": 1101,
                                "grade": 2,
                                "label": "YZ_XPDF",
                                "value": "524",
                                "sourceId": 3678
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1104,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5e9f\u6c34\u5904\u7406\u7167\u660e",
                            "value": "1692",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1692",
                                "groupId": 10,
                                "sortNum": 1105,
                                "grade": 2,
                                "label": "YZ_FSZ1",
                                "value": "518",
                                "sourceId": 1935
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1108,
                            "grade": 2,
                            "label": "\u4e94\u671f\u5916\u56f4\u7528\u6c34",
                            "value": "1693",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1693",
                                "groupId": 10,
                                "sortNum": 1109,
                                "grade": 2,
                                "label": "AKMC_WATER5SH",
                                "value": "832",
                                "sourceId": 3679
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1112,
                            "grade": 2,
                            "label": "\u4e94\u671f\u7a7a\u8c03\u673a\u623f\u7528\u6c34",
                            "value": "1694",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1694",
                                "groupId": 10,
                                "sortNum": 1113,
                                "grade": 2,
                                "label": "AKMC_WATER5KT",
                                "value": "835",
                                "sourceId": 1936
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1116,
                            "grade": 2,
                            "label": "\u6d88\u9632\/\u55b7\u6dcb\u6cf5",
                            "value": "1695",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1695",
                                "groupId": 10,
                                "sortNum": 1117,
                                "grade": 2,
                                "label": "YZ_XFBF",
                                "value": "497",
                                "sourceId": 1937
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1120,
                            "grade": 2,
                            "label": "\u4e94\u671f\u6d88\u9632\u6cf5\u623f\u7528\u6c34",
                            "value": "1696",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1696",
                                "groupId": 10,
                                "sortNum": 1121,
                                "grade": 2,
                                "label": "AKMC_WATER5BF",
                                "value": "841",
                                "sourceId": 1938
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1124,
                            "grade": 2,
                            "label": "\u5357\u51b0\u6c34\u4e3b\u673a",
                            "value": "1697",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5357\u51b0\u6c34\u673a",
                                "parentId": "1697",
                                "groupId": 10,
                                "sortNum": 1125,
                                "grade": 2,
                                "label": "YZ_BSJ1",
                                "value": "515",
                                "sourceId": 1939
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "1677",
                            "groupId": 10,
                            "sortNum": 1128,
                            "grade": 2,
                            "label": "\u914d\u7535\u623f\u7167\u660e",
                            "value": "1698",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "1698",
                                "groupId": 10,
                                "sortNum": 1129,
                                "grade": 2,
                                "label": "YZ_ZPDF",
                                "value": "523",
                                "sourceId": 1940
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "orgId": 1,
            "type": "park",
            "description": "",
            "parentId": "2873",
            "groupId": 20,
            "sortNum": 2,
            "grade": 2,
            "apm_org_id": "1",
            "apm_org_name": "Main Org.",
            "label": "\u6606\u5c71\u5236\u9020\u56ed\u533a",
            "value": "2874",
            "children": [
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 3,
                "grade": 2,
                "label": "\u677f\u5361\u5382",
                "value": "2875",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 4,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u4e00\u671f",
                    "value": "2992",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 5,
                        "grade": 2,
                        "label": "\u4e00\u671f\u7a7a\u8c03\u672b\u7aef",
                        "value": "2993",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2993",
                            "groupId": 20,
                            "sortNum": 6,
                            "grade": 2,
                            "label": "AKMC_1CKTX",
                            "value": "2625",
                            "sourceId": 3001
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 9,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e00\u671f\u7a7a\u538b",
                        "value": "2994",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2994",
                            "groupId": 20,
                            "sortNum": 10,
                            "grade": 2,
                            "label": "AKMC_1002",
                            "value": "2645",
                            "sourceId": 3000
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 13,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5382\u7167\u660e",
                        "value": "2995",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2995",
                            "groupId": 20,
                            "sortNum": 14,
                            "grade": 2,
                            "label": "AKMC_EPCAL",
                            "value": "2640",
                            "sourceId": 2999
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 17,
                        "grade": 2,
                        "label": "\u4e00\u671f\u7a7a\u8c03\u673a\u623f",
                        "value": "2996",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "\u4e00\u671f\u7a7a\u8c03\u7cfb\u7edf",
                            "parentId": "2996",
                            "groupId": 20,
                            "sortNum": 18,
                            "grade": 2,
                            "label": "AKMC_1001",
                            "value": "2697",
                            "sourceId": 3002
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 21,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e00\u671f1F",
                        "value": "2997",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2997",
                            "groupId": 20,
                            "sortNum": 22,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT\u4ed3\u5e93",
                            "value": "3017",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3017",
                                "groupId": 20,
                                "sortNum": 23,
                                "grade": 2,
                                "label": "AKMC_L1",
                                "value": "2698",
                                "sourceId": 3020
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2997",
                            "groupId": 20,
                            "sortNum": 26,
                            "grade": 2,
                            "label": "\u4e00\u671f\u6052\u6e29\u6052\u6e7f\u673a",
                            "value": "3018",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3018",
                                "groupId": 20,
                                "sortNum": 27,
                                "grade": 2,
                                "label": "AKMC_L9",
                                "value": "2708",
                                "sourceId": 3019
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2992",
                        "groupId": 20,
                        "sortNum": 31,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e00\u671f2F",
                        "value": "2998",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 32,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT 3\u7ebf",
                            "value": "3003",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3003",
                                "groupId": 20,
                                "sortNum": 33,
                                "grade": 2,
                                "label": "AKMC_SMT3",
                                "value": "2639",
                                "sourceId": 3011
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 36,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT 5\u53f7\u67dc",
                            "value": "3004",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3004",
                                "groupId": 20,
                                "sortNum": 37,
                                "grade": 2,
                                "label": "AKMC_L5",
                                "value": "2702",
                                "sourceId": 3012
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 40,
                            "grade": 2,
                            "label": "\u4e00\u671fDIP\u52a8\u529b",
                            "value": "3005",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3005",
                                "groupId": 20,
                                "sortNum": 41,
                                "grade": 2,
                                "label": "AKMC_L2",
                                "value": "2704",
                                "sourceId": 3016
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 44,
                            "grade": 2,
                            "label": "\u4e00\u671f\u70e7\u673a\u623f",
                            "value": "3006",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3006",
                                "groupId": 20,
                                "sortNum": 45,
                                "grade": 2,
                                "label": "AKMC_L7",
                                "value": "2706",
                                "sourceId": 3014
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 48,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT\u72ec\u7acb\u7a7a\u8c03",
                            "value": "3007",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3007",
                                "groupId": 20,
                                "sortNum": 49,
                                "grade": 2,
                                "label": "AKMC_L6",
                                "value": "2700",
                                "sourceId": 3010
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 52,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT\u70b9\u6599\u673a",
                            "value": "3008",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3008",
                                "groupId": 20,
                                "sortNum": 53,
                                "grade": 2,
                                "label": "AKMC_L8",
                                "value": "2707",
                                "sourceId": 3013
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2998",
                            "groupId": 20,
                            "sortNum": 56,
                            "grade": 2,
                            "label": "\u4e00\u671fSMT 3\u53f7\u67dc",
                            "value": "3009",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3009",
                                "groupId": 20,
                                "sortNum": 57,
                                "grade": 2,
                                "label": "AKMC_L3",
                                "value": "2703",
                                "sourceId": 3015
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 62,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u4e8c\u671f",
                    "value": "3021",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3021",
                        "groupId": 20,
                        "sortNum": 63,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e8c\u671f\u7a7a\u538b",
                        "value": "3085",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3085",
                            "groupId": 20,
                            "sortNum": 64,
                            "grade": 2,
                            "label": "AKMC_NB4P",
                            "value": "2691",
                            "sourceId": 3086
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 68,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u56db\u671f",
                    "value": "3022",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3022",
                        "groupId": 20,
                        "sortNum": 69,
                        "grade": 2,
                        "label": "\u677f\u5361\u56db\u671f4F",
                        "value": "3065",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3065",
                            "groupId": 20,
                            "sortNum": 70,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u52a8\u529b",
                            "value": "3066",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u56db\u671f\u56db\u697c\u52a8\u529b",
                                "parentId": "3066",
                                "groupId": 20,
                                "sortNum": 71,
                                "grade": 2,
                                "label": "AKMC_4S4FP",
                                "value": "2661",
                                "sourceId": 3075
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3065",
                            "groupId": 20,
                            "sortNum": 74,
                            "grade": 2,
                            "label": "\u56db\u671fSMT\u72ec\u7acb\u7a7a\u8c03",
                            "value": "3067",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3067",
                                "groupId": 20,
                                "sortNum": 75,
                                "grade": 2,
                                "label": "AKMC_4FCHAP",
                                "value": "2799",
                                "sourceId": 3071
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3065",
                            "groupId": 20,
                            "sortNum": 78,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u5357\u7a7a\u8c03",
                            "value": "3068",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3068",
                                "groupId": 20,
                                "sortNum": 79,
                                "grade": 2,
                                "label": "AKMC_4ACP6",
                                "value": "2811",
                                "sourceId": 3074
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3065",
                            "groupId": 20,
                            "sortNum": 82,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u7167\u660e",
                            "value": "3069",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3069",
                                "groupId": 20,
                                "sortNum": 83,
                                "grade": 2,
                                "label": "AKMC_4S4FL",
                                "value": "2666",
                                "sourceId": 3072
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3065",
                            "groupId": 20,
                            "sortNum": 86,
                            "grade": 2,
                            "label": "\u56db\u671f4F\u5317\u7a7a\u8c03",
                            "value": "3070",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3070",
                                "groupId": 20,
                                "sortNum": 87,
                                "grade": 2,
                                "label": "AKMC_4ACP5",
                                "value": "2798",
                                "sourceId": 3073
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 92,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u4e09\u671f",
                    "value": "3023",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3023",
                        "groupId": 20,
                        "sortNum": 93,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u671f4F",
                        "value": "3076",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3076",
                            "groupId": 20,
                            "sortNum": 94,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u5357\u7a7a\u8c03",
                            "value": "3077",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3077",
                                "groupId": 20,
                                "sortNum": 95,
                                "grade": 2,
                                "label": "AKMC_4ACP2",
                                "value": "2807",
                                "sourceId": 3082
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3076",
                            "groupId": 20,
                            "sortNum": 98,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u52a8\u529b",
                            "value": "3078",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3078",
                                "groupId": 20,
                                "sortNum": 99,
                                "grade": 2,
                                "label": "AKMC_3S6FWA",
                                "value": "2667",
                                "sourceId": 3084
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3076",
                            "groupId": 20,
                            "sortNum": 102,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u7167\u660e",
                            "value": "3079",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3079",
                                "groupId": 20,
                                "sortNum": 103,
                                "grade": 2,
                                "label": "AKMC_3S4FL",
                                "value": "2673",
                                "sourceId": 3081
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3076",
                            "groupId": 20,
                            "sortNum": 106,
                            "grade": 2,
                            "label": "\u4e09\u671f4F\u5317\u7a7a\u8c03",
                            "value": "3080",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3080",
                                "groupId": 20,
                                "sortNum": 107,
                                "grade": 2,
                                "label": "AKMC_4ACP1",
                                "value": "2790",
                                "sourceId": 3083
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 112,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u84b8\u6c7d",
                    "value": "3024",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3024",
                        "groupId": 20,
                        "sortNum": 113,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e00\u671f\u84b8\u6c7d",
                        "value": "3027",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3027",
                            "groupId": 20,
                            "sortNum": 114,
                            "grade": 2,
                            "label": "ZQLLJ",
                            "value": "2868",
                            "sourceId": 3030
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3024",
                        "groupId": 20,
                        "sortNum": 117,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u56db\u671f\u84b8\u6c7d",
                        "value": "3028",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3028",
                            "groupId": 20,
                            "sortNum": 118,
                            "grade": 2,
                            "label": "ZQLLJ:SIQ",
                            "value": "2869",
                            "sourceId": 3029
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 122,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u4e09\u56db\u671f",
                    "value": "3025",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3025",
                        "groupId": 20,
                        "sortNum": 123,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u56db\u671f\u7a7a\u8c03\u6cf5",
                        "value": "3037",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3037",
                            "groupId": 20,
                            "sortNum": 124,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671fP2\u6cf5",
                            "value": "3048",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3048",
                                "groupId": 20,
                                "sortNum": 125,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "2788",
                                "sourceId": 3056
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3037",
                            "groupId": 20,
                            "sortNum": 128,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671fP1\u6cf5",
                            "value": "3049",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3049",
                                "groupId": 20,
                                "sortNum": 129,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "2821",
                                "sourceId": 3057
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3037",
                            "groupId": 20,
                            "sortNum": 132,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u51b7\u5374\u6cf5",
                            "value": "3050",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3050",
                                "groupId": 20,
                                "sortNum": 133,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "2793",
                                "sourceId": 3053
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3037",
                            "groupId": 20,
                            "sortNum": 136,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u51b7\u70ed\u6cf5",
                            "value": "3051",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3051",
                                "groupId": 20,
                                "sortNum": 137,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "2819",
                                "sourceId": 3054
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3037",
                            "groupId": 20,
                            "sortNum": 140,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671fP3\u6cf5",
                            "value": "3052",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3052",
                                "groupId": 20,
                                "sortNum": 141,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "2791",
                                "sourceId": 3055
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3025",
                        "groupId": 20,
                        "sortNum": 145,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u56db\u671f\u51b0\u673a",
                        "value": "3038",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3038",
                            "groupId": 20,
                            "sortNum": 146,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u5317\u51b0\u673a",
                            "value": "3058",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3058",
                                "groupId": 20,
                                "sortNum": 147,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "2817",
                                "sourceId": 3061
                              },
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3058",
                                "groupId": 20,
                                "sortNum": 149,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "2813",
                                "sourceId": 3062
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3038",
                            "groupId": 20,
                            "sortNum": 152,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u5357\u51b0\u673a",
                            "value": "3059",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3059",
                                "groupId": 20,
                                "sortNum": 153,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "2825",
                                "sourceId": 3064
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3038",
                            "groupId": 20,
                            "sortNum": 156,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u4e2d\u51b0\u673a",
                            "value": "3060",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "3060",
                                "groupId": 20,
                                "sortNum": 157,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "2814",
                                "sourceId": 3063
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3025",
                        "groupId": 20,
                        "sortNum": 161,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u56db\u671f\u51b7\u5374\u5854",
                        "value": "3039",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3039",
                            "groupId": 20,
                            "sortNum": 162,
                            "grade": 2,
                            "label": "AKMC_AP10",
                            "value": "2803",
                            "sourceId": 3047
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3025",
                        "groupId": 20,
                        "sortNum": 165,
                        "grade": 2,
                        "label": "\u677f\u5361\u4e09\u56db\u671f\u7a7a\u538b",
                        "value": "3040",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3040",
                            "groupId": 20,
                            "sortNum": 166,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u7a7a\u538b1",
                            "value": "3042",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                                "parentId": "3042",
                                "groupId": 20,
                                "sortNum": 167,
                                "grade": 2,
                                "label": "AKMC_AIRC",
                                "value": "2794",
                                "sourceId": 3045
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3040",
                            "groupId": 20,
                            "sortNum": 170,
                            "grade": 2,
                            "label": "\u677f\u5361\u4e09\u56db\u671f\u7a7a\u538b2",
                            "value": "3043",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                                "parentId": "3043",
                                "groupId": 20,
                                "sortNum": 171,
                                "grade": 2,
                                "label": "AKMC_AIRC5",
                                "value": "2808",
                                "sourceId": 3044
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3025",
                        "groupId": 20,
                        "sortNum": 175,
                        "grade": 2,
                        "label": "4F\u4e2d\u95f4\u4ed3\u7a7a\u8c03",
                        "value": "3041",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3041",
                            "groupId": 20,
                            "sortNum": 176,
                            "grade": 2,
                            "label": "AKMC_4ACP4",
                            "value": "2824",
                            "sourceId": 3046
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2875",
                    "groupId": 20,
                    "sortNum": 180,
                    "grade": 2,
                    "label": "\u677f\u5361\u5382\u6c34\u8868",
                    "value": "3026",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3026",
                        "groupId": 20,
                        "sortNum": 181,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5382\u5357\u6c34\u8868",
                        "value": "3031",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3031",
                            "groupId": 20,
                            "sortNum": 182,
                            "grade": 2,
                            "label": "AKMC_WATER5",
                            "value": "2827",
                            "sourceId": 3035
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3026",
                        "groupId": 20,
                        "sortNum": 185,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5382\u5317\u6c34\u8868",
                        "value": "3032",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3032",
                            "groupId": 20,
                            "sortNum": 186,
                            "grade": 2,
                            "label": "AKMC_WATER6",
                            "value": "2833",
                            "sourceId": 3034
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3026",
                        "groupId": 20,
                        "sortNum": 189,
                        "grade": 2,
                        "label": "\u677f\u5361\u529e\u516c\u6c34\u8868",
                        "value": "3033",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3033",
                            "groupId": 20,
                            "sortNum": 190,
                            "grade": 2,
                            "label": "AKMC_WATER4",
                            "value": "2828",
                            "sourceId": 3036
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 195,
                "grade": 2,
                "label": "\u529e\u516c\u697c",
                "value": "2876",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 196,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c\u7167\u660e\u63d2\u5ea7",
                    "value": "2948",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "2948",
                        "groupId": 20,
                        "sortNum": 197,
                        "grade": 2,
                        "label": "AKMC_OFCA",
                        "value": "2650",
                        "sourceId": 2959
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 200,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c\u6c34\u8868",
                    "value": "2949",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "2949",
                        "groupId": 20,
                        "sortNum": 201,
                        "grade": 2,
                        "label": "AKMC_WATER4",
                        "value": "2828",
                        "sourceId": 2955
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 204,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c\u84b8\u6c7d",
                    "value": "2950",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "2950",
                        "groupId": 20,
                        "sortNum": 205,
                        "grade": 2,
                        "label": "ZQLLJ",
                        "value": "2868",
                        "sourceId": 2954
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 208,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c\u7a7a\u8c03\u672b\u7aef",
                    "value": "2951",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "2951",
                        "groupId": 20,
                        "sortNum": 209,
                        "grade": 2,
                        "label": "AKMC_OFKTX",
                        "value": "2648",
                        "sourceId": 2958
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 212,
                    "grade": 2,
                    "label": "\u529e\u516c\u697c\u7a7a\u8c03\u673a\u623f",
                    "value": "2952",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "\u4e00\u671f\u7a7a\u8c03\u7cfb\u7edf",
                        "parentId": "2952",
                        "groupId": 20,
                        "sortNum": 213,
                        "grade": 2,
                        "label": "AKMC_1001",
                        "value": "2697",
                        "sourceId": 2956
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2876",
                    "groupId": 20,
                    "sortNum": 216,
                    "grade": 2,
                    "label": "\u529e\u516c\u697cMIS\u673a\u623f",
                    "value": "2953",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "2953",
                        "groupId": 20,
                        "sortNum": 217,
                        "grade": 2,
                        "label": "AKMC_MISA",
                        "value": "2619",
                        "sourceId": 2957
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 221,
                "grade": 2,
                "label": "CTOS\u5382",
                "value": "2877",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 222,
                    "grade": 2,
                    "label": "CTOS\u4e00\u671f",
                    "value": "2883",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2883",
                        "groupId": 20,
                        "sortNum": 223,
                        "grade": 2,
                        "label": "CTOS\u4e00\u671f\u7a7a\u538b",
                        "value": "2916",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2916",
                            "groupId": 20,
                            "sortNum": 224,
                            "grade": 2,
                            "label": "AKMC_1002",
                            "value": "2645",
                            "sourceId": 2917
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 228,
                    "grade": 2,
                    "label": "CTOS\u56db\u671f",
                    "value": "2884",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2884",
                        "groupId": 20,
                        "sortNum": 229,
                        "grade": 2,
                        "label": "CTOS\u56db\u671f2F",
                        "value": "2889",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2889",
                            "groupId": 20,
                            "sortNum": 230,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u52a8\u529b",
                            "value": "2897",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2897",
                                "groupId": 20,
                                "sortNum": 231,
                                "grade": 2,
                                "label": "AKMC_4S2FP",
                                "value": "2676",
                                "sourceId": 2906
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2889",
                            "groupId": 20,
                            "sortNum": 234,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u7167\u660e",
                            "value": "2898",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2898",
                                "groupId": 20,
                                "sortNum": 235,
                                "grade": 2,
                                "label": "AKMC_4S2FL",
                                "value": "2677",
                                "sourceId": 2902
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2889",
                            "groupId": 20,
                            "sortNum": 238,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u529e\u516c\u7a7a\u8c03",
                            "value": "2899",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2899",
                                "groupId": 20,
                                "sortNum": 239,
                                "grade": 2,
                                "label": "AKMC_2ACP5",
                                "value": "2826",
                                "sourceId": 2903
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2889",
                            "groupId": 20,
                            "sortNum": 242,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u5357\u7a7a\u8c03",
                            "value": "2900",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2900",
                                "groupId": 20,
                                "sortNum": 243,
                                "grade": 2,
                                "label": "AKMC_2ACP4",
                                "value": "2800",
                                "sourceId": 2904
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2889",
                            "groupId": 20,
                            "sortNum": 246,
                            "grade": 2,
                            "label": "\u56db\u671f2F\u5317\u7a7a\u8c03",
                            "value": "2901",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2901",
                                "groupId": 20,
                                "sortNum": 247,
                                "grade": 2,
                                "label": "AKMC_2ACP3",
                                "value": "2804",
                                "sourceId": 2905
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2884",
                        "groupId": 20,
                        "sortNum": 251,
                        "grade": 2,
                        "label": "CTOS\u56db\u671f3F",
                        "value": "2890",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2890",
                            "groupId": 20,
                            "sortNum": 252,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u5357\u7a7a\u8c03",
                            "value": "2891",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2891",
                                "groupId": 20,
                                "sortNum": 253,
                                "grade": 2,
                                "label": "AKMC_3ACP6",
                                "value": "2815",
                                "sourceId": 2894
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2890",
                            "groupId": 20,
                            "sortNum": 256,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u7167\u660e",
                            "value": "2892",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2892",
                                "groupId": 20,
                                "sortNum": 257,
                                "grade": 2,
                                "label": "AKMC_4S3FL",
                                "value": "2659",
                                "sourceId": 2896
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2890",
                            "groupId": 20,
                            "sortNum": 260,
                            "grade": 2,
                            "label": "\u56db\u671f3F\u5317\u7a7a\u8c03",
                            "value": "2893",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2893",
                                "groupId": 20,
                                "sortNum": 261,
                                "grade": 2,
                                "label": "AKMC_3ACP5",
                                "value": "2823",
                                "sourceId": 2895
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 266,
                    "grade": 2,
                    "label": "CTOS\u4e09\u56db\u671f",
                    "value": "2885",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2885",
                        "groupId": 20,
                        "sortNum": 267,
                        "grade": 2,
                        "label": "CTOS\u4e09\u56db\u671f\u51b7\u5374\u5854",
                        "value": "2918",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2918",
                            "groupId": 20,
                            "sortNum": 268,
                            "grade": 2,
                            "label": "AKMC_AP10",
                            "value": "2803",
                            "sourceId": 2928
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2885",
                        "groupId": 20,
                        "sortNum": 271,
                        "grade": 2,
                        "label": "CTOS\u4e09\u56db\u671f\u51b0\u673a",
                        "value": "2919",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2919",
                            "groupId": 20,
                            "sortNum": 272,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u4e2d\u51b0\u673a",
                            "value": "2939",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "2939",
                                "groupId": 20,
                                "sortNum": 273,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "2814",
                                "sourceId": 2944
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2919",
                            "groupId": 20,
                            "sortNum": 276,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u5317\u51b0\u673a",
                            "value": "2940",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2940",
                                "groupId": 20,
                                "sortNum": 277,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "2813",
                                "sourceId": 2942
                              },
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2940",
                                "groupId": 20,
                                "sortNum": 279,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "2817",
                                "sourceId": 2943
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2919",
                            "groupId": 20,
                            "sortNum": 282,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u5357\u51b0\u673a",
                            "value": "2941",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2941",
                                "groupId": 20,
                                "sortNum": 283,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "2825",
                                "sourceId": 2945
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2885",
                        "groupId": 20,
                        "sortNum": 287,
                        "grade": 2,
                        "label": "CTOS\u4e09\u56db\u671f\u7a7a\u8c03\u6cf5",
                        "value": "2920",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2920",
                            "groupId": 20,
                            "sortNum": 288,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671fP3\u6cf5",
                            "value": "2929",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2929",
                                "groupId": 20,
                                "sortNum": 289,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "2791",
                                "sourceId": 2936
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2920",
                            "groupId": 20,
                            "sortNum": 292,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u51b7\u5374\u6cf5",
                            "value": "2930",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2930",
                                "groupId": 20,
                                "sortNum": 293,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "2793",
                                "sourceId": 2934
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2920",
                            "groupId": 20,
                            "sortNum": 296,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671fP1\u6cf5",
                            "value": "2931",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2931",
                                "groupId": 20,
                                "sortNum": 297,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "2821",
                                "sourceId": 2938
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2920",
                            "groupId": 20,
                            "sortNum": 300,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671fP2\u6cf5",
                            "value": "2932",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2932",
                                "groupId": 20,
                                "sortNum": 301,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "2788",
                                "sourceId": 2937
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2920",
                            "groupId": 20,
                            "sortNum": 304,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u51b7\u70ed\u6cf5",
                            "value": "2933",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2933",
                                "groupId": 20,
                                "sortNum": 305,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "2819",
                                "sourceId": 2935
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2885",
                        "groupId": 20,
                        "sortNum": 309,
                        "grade": 2,
                        "label": "CTOS\u4e09\u56db\u671f\u7a7a\u538b",
                        "value": "2921",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2921",
                            "groupId": 20,
                            "sortNum": 310,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u7a7a\u538b1",
                            "value": "2924",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                                "parentId": "2924",
                                "groupId": 20,
                                "sortNum": 311,
                                "grade": 2,
                                "label": "AKMC_AIRC",
                                "value": "2794",
                                "sourceId": 2927
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2921",
                            "groupId": 20,
                            "sortNum": 314,
                            "grade": 2,
                            "label": "CTOS\u4e09\u56db\u671f\u7a7a\u538b2",
                            "value": "2925",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                                "parentId": "2925",
                                "groupId": 20,
                                "sortNum": 315,
                                "grade": 2,
                                "label": "AKMC_AIRC5",
                                "value": "2808",
                                "sourceId": 2926
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2885",
                        "groupId": 20,
                        "sortNum": 319,
                        "grade": 2,
                        "label": "2F\u4e2d\u95f4\u4ed3\u7a7a\u8c03",
                        "value": "2922",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2922",
                            "groupId": 20,
                            "sortNum": 320,
                            "grade": 2,
                            "label": "AKMC_2ACP2",
                            "value": "2818",
                            "sourceId": 2923
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 324,
                    "grade": 2,
                    "label": "CTOS\u4e09\u671f",
                    "value": "2886",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2886",
                        "groupId": 20,
                        "sortNum": 325,
                        "grade": 2,
                        "label": "CTOS\u4e09\u671f1F",
                        "value": "2907",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2907",
                            "groupId": 20,
                            "sortNum": 326,
                            "grade": 2,
                            "label": "CTOS\u4e09\u671f1F\u7167\u660e",
                            "value": "2908",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2908",
                                "groupId": 20,
                                "sortNum": 327,
                                "grade": 2,
                                "label": "AKMC_3S1FL",
                                "value": "2662",
                                "sourceId": 2913
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2907",
                            "groupId": 20,
                            "sortNum": 330,
                            "grade": 2,
                            "label": "CTOS\u4e09\u671f2F\u70e7\u673a",
                            "value": "2909",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2909",
                                "groupId": 20,
                                "sortNum": 331,
                                "grade": 2,
                                "label": "AKMC_3S2FS",
                                "value": "2678",
                                "sourceId": 2911
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "2907",
                            "groupId": 20,
                            "sortNum": 334,
                            "grade": 2,
                            "label": "CTOS\u4e09\u671f2F\u7167\u660e",
                            "value": "2910",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "2910",
                                "groupId": 20,
                                "sortNum": 335,
                                "grade": 2,
                                "label": "AKMC_3S2FL",
                                "value": "2657",
                                "sourceId": 2912
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 340,
                    "grade": 2,
                    "label": "CTOS\u5382\u84b8\u6c7d",
                    "value": "2887",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2887",
                        "groupId": 20,
                        "sortNum": 341,
                        "grade": 2,
                        "label": "CTOS\u4e09\u56db\u671f\u84b8\u6c7d",
                        "value": "2946",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2946",
                            "groupId": 20,
                            "sortNum": 342,
                            "grade": 2,
                            "label": "ZQLLJ:SIQ",
                            "value": "2869",
                            "sourceId": 2947
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2877",
                    "groupId": 20,
                    "sortNum": 346,
                    "grade": 2,
                    "label": "CTOS\u4e8c\u671f",
                    "value": "2888",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "2888",
                        "groupId": 20,
                        "sortNum": 347,
                        "grade": 2,
                        "label": "CTOS\u4e8c\u671f\u7a7a\u538b",
                        "value": "2914",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "2914",
                            "groupId": 20,
                            "sortNum": 348,
                            "grade": 2,
                            "label": "AKMC_NB4P",
                            "value": "2691",
                            "sourceId": 2915
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 353,
                "grade": 2,
                "label": "\u7cfb\u7edf\u5382",
                "value": "2878",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2878",
                    "groupId": 20,
                    "sortNum": 354,
                    "grade": 2,
                    "label": "\u7cfb\u7edf\u5382\u6c34\u8868",
                    "value": "3087",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3087",
                        "groupId": 20,
                        "sortNum": 355,
                        "grade": 2,
                        "label": "AKMC_WATER7",
                        "value": "2858",
                        "sourceId": 3093
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2878",
                    "groupId": 20,
                    "sortNum": 358,
                    "grade": 2,
                    "label": "\u7cfb\u7edf\u5382\u4e8c\u671f",
                    "value": "3088",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3088",
                        "groupId": 20,
                        "sortNum": 359,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5357",
                        "value": "3098",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3098",
                            "groupId": 20,
                            "sortNum": 360,
                            "grade": 2,
                            "label": "\u4e8c\u671f2F\u5357\u52a8\u529b",
                            "value": "3103",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3103",
                                "groupId": 20,
                                "sortNum": 361,
                                "grade": 2,
                                "label": "AKMC_SB2P",
                                "value": "2688",
                                "sourceId": 3108
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3098",
                            "groupId": 20,
                            "sortNum": 364,
                            "grade": 2,
                            "label": "\u4e8c\u671f3F\u5357\u52a8\u529b",
                            "value": "3104",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3104",
                                "groupId": 20,
                                "sortNum": 365,
                                "grade": 2,
                                "label": "AKMC_SB3P",
                                "value": "2689",
                                "sourceId": 3107
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3098",
                            "groupId": 20,
                            "sortNum": 368,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u5357\u603b\u7167\u660e",
                            "value": "3105",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3105",
                                "groupId": 20,
                                "sortNum": 369,
                                "grade": 2,
                                "label": "AKMC_SBLA",
                                "value": "2694",
                                "sourceId": 3106
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3088",
                        "groupId": 20,
                        "sortNum": 373,
                        "grade": 2,
                        "label": "\u7cfb\u7edf\u4e8c\u671f\u7a7a\u538b",
                        "value": "3099",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3099",
                            "groupId": 20,
                            "sortNum": 374,
                            "grade": 2,
                            "label": "AKMC_NB4P",
                            "value": "2691",
                            "sourceId": 3117
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3088",
                        "groupId": 20,
                        "sortNum": 377,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u7a7a\u8c03\u4e3b\u673a",
                        "value": "3100",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "\u4e8c\u671f\u5382",
                            "parentId": "3100",
                            "groupId": 20,
                            "sortNum": 378,
                            "grade": 2,
                            "label": "AKMC_ALL",
                            "value": "2693",
                            "sourceId": 3116
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3088",
                        "groupId": 20,
                        "sortNum": 381,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u8d44\u8baf\u673a\u623f",
                        "value": "3101",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3101",
                            "groupId": 20,
                            "sortNum": 382,
                            "grade": 2,
                            "label": "AKMC_2FMIS",
                            "value": "2705",
                            "sourceId": 3115
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3088",
                        "groupId": 20,
                        "sortNum": 385,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5317",
                        "value": "3102",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3102",
                            "groupId": 20,
                            "sortNum": 386,
                            "grade": 2,
                            "label": "\u4e8c\u671f3F\u5317\u52a8\u529b",
                            "value": "3109",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3109",
                                "groupId": 20,
                                "sortNum": 387,
                                "grade": 2,
                                "label": "AKMC_NB3P",
                                "value": "2692",
                                "sourceId": 3113
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3102",
                            "groupId": 20,
                            "sortNum": 390,
                            "grade": 2,
                            "label": "\u4e8c\u671f2F\u5317\u52a8\u529b",
                            "value": "3110",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3110",
                                "groupId": 20,
                                "sortNum": 391,
                                "grade": 2,
                                "label": "AKMC_NB2P",
                                "value": "2695",
                                "sourceId": 3114
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3102",
                            "groupId": 20,
                            "sortNum": 394,
                            "grade": 2,
                            "label": "\u4e8c\u671f\u5317\u603b\u7167\u660e",
                            "value": "3111",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3111",
                                "groupId": 20,
                                "sortNum": 395,
                                "grade": 2,
                                "label": "AKMC_NBLA",
                                "value": "2690",
                                "sourceId": 3112
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2878",
                    "groupId": 20,
                    "sortNum": 400,
                    "grade": 2,
                    "label": "\u7cfb\u7edf\u5382\u4e00\u671f",
                    "value": "3089",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3089",
                        "groupId": 20,
                        "sortNum": 401,
                        "grade": 2,
                        "label": "\u7cfb\u7edf\u4e00\u671f\u7a7a\u538b",
                        "value": "3118",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3118",
                            "groupId": 20,
                            "sortNum": 402,
                            "grade": 2,
                            "label": "AKMC_1002",
                            "value": "2645",
                            "sourceId": 3119
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2878",
                    "groupId": 20,
                    "sortNum": 406,
                    "grade": 2,
                    "label": "\u7cfb\u7edf\u5382\u4e09\u56db\u671f",
                    "value": "3090",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3090",
                        "groupId": 20,
                        "sortNum": 407,
                        "grade": 2,
                        "label": "\u7cfb\u7edf\u4e09\u56db\u671f\u7a7a\u538b1",
                        "value": "3094",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                            "parentId": "3094",
                            "groupId": 20,
                            "sortNum": 408,
                            "grade": 2,
                            "label": "AKMC_AIRC",
                            "value": "2794",
                            "sourceId": 3097
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3090",
                        "groupId": 20,
                        "sortNum": 411,
                        "grade": 2,
                        "label": "\u7cfb\u7edf\u4e09\u56db\u671f\u7a7a\u538b2",
                        "value": "3095",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                            "parentId": "3095",
                            "groupId": 20,
                            "sortNum": 412,
                            "grade": 2,
                            "label": "AKMC_AIRC5",
                            "value": "2808",
                            "sourceId": 3096
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2878",
                    "groupId": 20,
                    "sortNum": 416,
                    "grade": 2,
                    "label": "\u7cfb\u7edf\u5382\u84b8\u6c7d",
                    "value": "3091",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3091",
                        "groupId": 20,
                        "sortNum": 417,
                        "grade": 2,
                        "label": "ZQLLJ:ERQ",
                        "value": "2870",
                        "sourceId": 3092
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 421,
                "grade": 2,
                "label": "\u516c\u5171\u533a",
                "value": "2879",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 422,
                    "grade": 2,
                    "label": "\u516c\u5171\u4e09\u671f",
                    "value": "3397",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3397",
                        "groupId": 20,
                        "sortNum": 423,
                        "grade": 2,
                        "label": "\u4e09\u671f3F\u5317\u7a7a\u8c03",
                        "value": "3452",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3452",
                            "groupId": 20,
                            "sortNum": 424,
                            "grade": 2,
                            "label": "AKMC_3ACP1",
                            "value": "2795",
                            "sourceId": 3458
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3397",
                        "groupId": 20,
                        "sortNum": 427,
                        "grade": 2,
                        "label": "\u4e09\u671f3F\u6210\u4ed3\u7167\u660e",
                        "value": "3453",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3453",
                            "groupId": 20,
                            "sortNum": 428,
                            "grade": 2,
                            "label": "AKMC_3S3FL",
                            "value": "2668",
                            "sourceId": 3460
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3397",
                        "groupId": 20,
                        "sortNum": 431,
                        "grade": 2,
                        "label": "\u4e09\u671f\u5ba2\u8d27\u68af",
                        "value": "3454",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3454",
                            "groupId": 20,
                            "sortNum": 432,
                            "grade": 2,
                            "label": "AKMC_3SLA",
                            "value": "2684",
                            "sourceId": 3461
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3397",
                        "groupId": 20,
                        "sortNum": 435,
                        "grade": 2,
                        "label": "\u4e09\u671f3F\u5357\u7a7a\u8c03",
                        "value": "3455",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3455",
                            "groupId": 20,
                            "sortNum": 436,
                            "grade": 2,
                            "label": "AKMC_3ACP2",
                            "value": "2816",
                            "sourceId": 3457
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3397",
                        "groupId": 20,
                        "sortNum": 439,
                        "grade": 2,
                        "label": "\u4e09\u671f6F\u6210\u4ed3\u7167\u660e",
                        "value": "3456",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3456",
                            "groupId": 20,
                            "sortNum": 440,
                            "grade": 2,
                            "label": "AKMC_3S6FL",
                            "value": "2660",
                            "sourceId": 3459
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 444,
                    "grade": 2,
                    "label": "\u516c\u5171\u4e09\u56db\u671f",
                    "value": "3398",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 445,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u516c\u5171\u51b0\u673a",
                        "value": "3407",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3407",
                            "groupId": 20,
                            "sortNum": 446,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671f\u5317\u51b0\u673a",
                            "value": "3428",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3428",
                                "groupId": 20,
                                "sortNum": 447,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "2817",
                                "sourceId": 3431
                              },
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3428",
                                "groupId": 20,
                                "sortNum": 449,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "2813",
                                "sourceId": 3432
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3407",
                            "groupId": 20,
                            "sortNum": 452,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671f\u4e2d\u51b0\u673a",
                            "value": "3429",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "3429",
                                "groupId": 20,
                                "sortNum": 453,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "2814",
                                "sourceId": 3433
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3407",
                            "groupId": 20,
                            "sortNum": 456,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671f\u5357\u51b0\u673a",
                            "value": "3430",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3430",
                                "groupId": 20,
                                "sortNum": 457,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "2825",
                                "sourceId": 3434
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 461,
                        "grade": 2,
                        "label": "3F\u4e2d\u95f4\u4ed3\u7a7a\u8c03",
                        "value": "3408",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3408",
                            "groupId": 20,
                            "sortNum": 462,
                            "grade": 2,
                            "label": "AKMC_3ACP4",
                            "value": "2802",
                            "sourceId": 3416
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 465,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u6d88\u9632\u98ce\u673a",
                        "value": "3409",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3409",
                            "groupId": 20,
                            "sortNum": 466,
                            "grade": 2,
                            "label": "AKMC_FIREF",
                            "value": "2672",
                            "sourceId": 3438
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 469,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u8d44\u8baf\u7a7a\u8c03",
                        "value": "3410",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3410",
                            "groupId": 20,
                            "sortNum": 470,
                            "grade": 2,
                            "label": "AKMC_3SMISA",
                            "value": "2679",
                            "sourceId": 3435
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 473,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u7f51\u7edc\u673a\u623f",
                        "value": "3411",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3411",
                            "groupId": 20,
                            "sortNum": 474,
                            "grade": 2,
                            "label": "AKMC_3SMIS2",
                            "value": "2674",
                            "sourceId": 3436
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 477,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u6d88\u9632\u6c34\u6cf5",
                        "value": "3412",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3412",
                            "groupId": 20,
                            "sortNum": 478,
                            "grade": 2,
                            "label": "AKMC_FIREP",
                            "value": "2685",
                            "sourceId": 3439
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 481,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u516c\u5171\u51b7\u5374\u5854",
                        "value": "3413",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3413",
                            "groupId": 20,
                            "sortNum": 482,
                            "grade": 2,
                            "label": "AKMC_AP10",
                            "value": "2803",
                            "sourceId": 3417
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 485,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u670d\u52a1\u5668\u673a\u623f",
                        "value": "3414",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3414",
                            "groupId": 20,
                            "sortNum": 486,
                            "grade": 2,
                            "label": "AKMC_3SMIS1",
                            "value": "2686",
                            "sourceId": 3437
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3398",
                        "groupId": 20,
                        "sortNum": 489,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u516c\u5171\u7a7a\u8c03\u6cf5",
                        "value": "3415",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3415",
                            "groupId": 20,
                            "sortNum": 490,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671fP3\u6cf5",
                            "value": "3418",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3418",
                                "groupId": 20,
                                "sortNum": 491,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "2791",
                                "sourceId": 3425
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3415",
                            "groupId": 20,
                            "sortNum": 494,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671f\u51b7\u5374\u6cf5",
                            "value": "3419",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3419",
                                "groupId": 20,
                                "sortNum": 495,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "2793",
                                "sourceId": 3423
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3415",
                            "groupId": 20,
                            "sortNum": 498,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671f\u51b7\u70ed\u6cf5",
                            "value": "3420",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3420",
                                "groupId": 20,
                                "sortNum": 499,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "2819",
                                "sourceId": 3424
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3415",
                            "groupId": 20,
                            "sortNum": 502,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671fP2\u6cf5",
                            "value": "3421",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3421",
                                "groupId": 20,
                                "sortNum": 503,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "2788",
                                "sourceId": 3426
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3415",
                            "groupId": 20,
                            "sortNum": 506,
                            "grade": 2,
                            "label": "\u516c\u5171\u4e09\u56db\u671fP1\u6cf5",
                            "value": "3422",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3422",
                                "groupId": 20,
                                "sortNum": 507,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "2821",
                                "sourceId": 3427
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 512,
                    "grade": 2,
                    "label": "\u516c\u5171\u56db\u671f",
                    "value": "3399",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 513,
                        "grade": 2,
                        "label": "\u56db\u671f\u52a8\u529b\u635f\u8017",
                        "value": "3440",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "\u56db\u671f\u603b\u52a8\u529b",
                            "parentId": "3440",
                            "groupId": 20,
                            "sortNum": 514,
                            "grade": 2,
                            "label": "AKMC_4SALLP",
                            "value": "2683",
                            "sourceId": 3447
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 517,
                        "grade": 2,
                        "label": "\u56db\u671f1F\u529e\u516c\u7a7a\u8c03",
                        "value": "3441",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3441",
                            "groupId": 20,
                            "sortNum": 518,
                            "grade": 2,
                            "label": "AKMC_1ACP2",
                            "value": "2792",
                            "sourceId": 3450
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 521,
                        "grade": 2,
                        "label": "\u56db\u671f6F\u673a\u623f\u7a7a\u8c03",
                        "value": "3442",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3442",
                            "groupId": 20,
                            "sortNum": 522,
                            "grade": 2,
                            "label": "AKMC_6ACP2",
                            "value": "2822",
                            "sourceId": 3448
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 525,
                        "grade": 2,
                        "label": "\u56db\u671f1F\u7167\u660e",
                        "value": "3443",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3443",
                            "groupId": 20,
                            "sortNum": 526,
                            "grade": 2,
                            "label": "AKMC_4S1FL",
                            "value": "2658",
                            "sourceId": 3451
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 529,
                        "grade": 2,
                        "label": "\u56db\u671f\u5ba2\u68af",
                        "value": "3444",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3444",
                            "groupId": 20,
                            "sortNum": 530,
                            "grade": 2,
                            "label": "AKMC_4SLA",
                            "value": "2680",
                            "sourceId": 3446
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3399",
                        "groupId": 20,
                        "sortNum": 533,
                        "grade": 2,
                        "label": "\u56db\u671f1F\u6210\u4ed3\u7a7a\u8c03",
                        "value": "3445",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3445",
                            "groupId": 20,
                            "sortNum": 534,
                            "grade": 2,
                            "label": "AKMC_1ACP3",
                            "value": "2809",
                            "sourceId": 3449
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 538,
                    "grade": 2,
                    "label": "\u516c\u5171\u84b8\u6c7d",
                    "value": "3400",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3400",
                        "groupId": 20,
                        "sortNum": 539,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u516c\u5171\u84b8\u6c7d",
                        "value": "3403",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3403",
                            "groupId": 20,
                            "sortNum": 540,
                            "grade": 2,
                            "label": "ZQLLJ:SIQ",
                            "value": "2869",
                            "sourceId": 3404
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 544,
                    "grade": 2,
                    "label": "\u516c\u5171\u5916\u56f4",
                    "value": "3401",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3401",
                        "groupId": 20,
                        "sortNum": 545,
                        "grade": 2,
                        "label": "\u4e00\u56db\u671f\u5317\u95e8\u536b",
                        "value": "3462",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3462",
                            "groupId": 20,
                            "sortNum": 546,
                            "grade": 2,
                            "label": "AKMC_NGROOM",
                            "value": "2623",
                            "sourceId": 3464
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3401",
                        "groupId": 20,
                        "sortNum": 549,
                        "grade": 2,
                        "label": "\u4e00\u56db\u671f\u5357\u95e8\u536b",
                        "value": "3463",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3463",
                            "groupId": 20,
                            "sortNum": 550,
                            "grade": 2,
                            "label": "AKMC_SGROOM",
                            "value": "2621",
                            "sourceId": 3465
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2879",
                    "groupId": 20,
                    "sortNum": 554,
                    "grade": 2,
                    "label": "\u516c\u5171\u4e94\u671f",
                    "value": "3402",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3402",
                        "groupId": 20,
                        "sortNum": 555,
                        "grade": 2,
                        "label": "\u4e94\u671f\u8def\u706f",
                        "value": "3405",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3405",
                            "groupId": 20,
                            "sortNum": 556,
                            "grade": 2,
                            "label": "YZ_LD",
                            "value": "2630",
                            "sourceId": 3406
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 561,
                "grade": 2,
                "label": "\u6a21\u7ec4\u5382",
                "value": "2880",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 562,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u4e09\u671f",
                    "value": "3306",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3306",
                        "groupId": 20,
                        "sortNum": 563,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u671f5F",
                        "value": "3348",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3348",
                            "groupId": 20,
                            "sortNum": 564,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u7167\u660e",
                            "value": "3356",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3356",
                                "groupId": 20,
                                "sortNum": 565,
                                "grade": 2,
                                "label": "AKMC_3S5FL",
                                "value": "2663",
                                "sourceId": 3363
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3348",
                            "groupId": 20,
                            "sortNum": 568,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u5357\u7a7a\u8c03",
                            "value": "3357",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3357",
                                "groupId": 20,
                                "sortNum": 569,
                                "grade": 2,
                                "label": "AKMC_5ACP1",
                                "value": "2812",
                                "sourceId": 3360
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3348",
                            "groupId": 20,
                            "sortNum": 572,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u5317\u7a7a\u8c03",
                            "value": "3358",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3358",
                                "groupId": 20,
                                "sortNum": 573,
                                "grade": 2,
                                "label": "AKMC_5ACP2",
                                "value": "2801",
                                "sourceId": 3361
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3348",
                            "groupId": 20,
                            "sortNum": 576,
                            "grade": 2,
                            "label": "\u4e09\u671f5F\u52a8\u529b",
                            "value": "3359",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3359",
                                "groupId": 20,
                                "sortNum": 577,
                                "grade": 2,
                                "label": "AKMC_3S5FP",
                                "value": "2665",
                                "sourceId": 3362
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3306",
                        "groupId": 20,
                        "sortNum": 581,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u671f6F",
                        "value": "3349",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3349",
                            "groupId": 20,
                            "sortNum": 582,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u671f6F\u7167\u660e",
                            "value": "3350",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3350",
                                "groupId": 20,
                                "sortNum": 583,
                                "grade": 2,
                                "label": "AKMC_3S6FL",
                                "value": "2660",
                                "sourceId": 3355
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3349",
                            "groupId": 20,
                            "sortNum": 586,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u671f6F\u529e\u516c\u7a7a\u8c03",
                            "value": "3351",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3351",
                                "groupId": 20,
                                "sortNum": 587,
                                "grade": 2,
                                "label": "AKMC_6ACP1",
                                "value": "2810",
                                "sourceId": 3353
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3349",
                            "groupId": 20,
                            "sortNum": 590,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u671f6F\u4ed3\u5e93\u7a7a\u8c03",
                            "value": "3352",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3352",
                                "groupId": 20,
                                "sortNum": 591,
                                "grade": 2,
                                "label": "AKMC_6FPAI",
                                "value": "2681",
                                "sourceId": 3354
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 596,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u56db\u671f",
                    "value": "3307",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3307",
                        "groupId": 20,
                        "sortNum": 597,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u56db\u671f5F",
                        "value": "3341",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3341",
                            "groupId": 20,
                            "sortNum": 598,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u5357\u7a7a\u8c03",
                            "value": "3342",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3342",
                                "groupId": 20,
                                "sortNum": 599,
                                "grade": 2,
                                "label": "AKMC_5ACP6",
                                "value": "2796",
                                "sourceId": 3345
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3341",
                            "groupId": 20,
                            "sortNum": 602,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u7167\u660e",
                            "value": "3343",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3343",
                                "groupId": 20,
                                "sortNum": 603,
                                "grade": 2,
                                "label": "AKMC_4S5FL",
                                "value": "2671",
                                "sourceId": 3347
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3341",
                            "groupId": 20,
                            "sortNum": 606,
                            "grade": 2,
                            "label": "\u56db\u671f5F\u5317\u7a7a\u8c03",
                            "value": "3344",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3344",
                                "groupId": 20,
                                "sortNum": 607,
                                "grade": 2,
                                "label": "AKMC_5ACP5",
                                "value": "2806",
                                "sourceId": 3346
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 612,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u4e8c\u671f",
                    "value": "3308",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3308",
                        "groupId": 20,
                        "sortNum": 613,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e8c\u671f\u7a7a\u538b",
                        "value": "3364",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3364",
                            "groupId": 20,
                            "sortNum": 614,
                            "grade": 2,
                            "label": "AKMC_NB4P",
                            "value": "2691",
                            "sourceId": 3365
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 618,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u5382\u84b8\u6c7d",
                    "value": "3309",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "bems\u865a\u62df\u8868\u8282\u70b9",
                        "parentId": "3309",
                        "groupId": 20,
                        "sortNum": 619,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u84b8\u6c7d",
                        "value": "3466"
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 622,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u4e00\u671f",
                    "value": "3310",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3310",
                        "groupId": 20,
                        "sortNum": 623,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e00\u671f\u7a7a\u538b",
                        "value": "3366",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3366",
                            "groupId": 20,
                            "sortNum": 624,
                            "grade": 2,
                            "label": "AKMC_1002",
                            "value": "2645",
                            "sourceId": 3367
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2880",
                    "groupId": 20,
                    "sortNum": 628,
                    "grade": 2,
                    "label": "\u6a21\u7ec4\u4e09\u56db\u671f",
                    "value": "3311",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3311",
                        "groupId": 20,
                        "sortNum": 629,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u51b7\u5374\u5854",
                        "value": "3313",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3313",
                            "groupId": 20,
                            "sortNum": 630,
                            "grade": 2,
                            "label": "AKMC_AP10",
                            "value": "2803",
                            "sourceId": 3323
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3311",
                        "groupId": 20,
                        "sortNum": 633,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u7a7a\u8c03\u6cf5",
                        "value": "3314",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3314",
                            "groupId": 20,
                            "sortNum": 634,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671fP3\u6cf5",
                            "value": "3324",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3324",
                                "groupId": 20,
                                "sortNum": 635,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "2791",
                                "sourceId": 3331
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3314",
                            "groupId": 20,
                            "sortNum": 638,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671fP1\u6cf5",
                            "value": "3325",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3325",
                                "groupId": 20,
                                "sortNum": 639,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "2821",
                                "sourceId": 3333
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3314",
                            "groupId": 20,
                            "sortNum": 642,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671fP2\u6cf5",
                            "value": "3326",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3326",
                                "groupId": 20,
                                "sortNum": 643,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "2788",
                                "sourceId": 3332
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3314",
                            "groupId": 20,
                            "sortNum": 646,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u51b7\u70ed\u6cf5",
                            "value": "3327",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3327",
                                "groupId": 20,
                                "sortNum": 647,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "2819",
                                "sourceId": 3330
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3314",
                            "groupId": 20,
                            "sortNum": 650,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u51b7\u5374\u6cf5",
                            "value": "3328",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3328",
                                "groupId": 20,
                                "sortNum": 651,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "2793",
                                "sourceId": 3329
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3311",
                        "groupId": 20,
                        "sortNum": 655,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u51b0\u673a",
                        "value": "3315",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3315",
                            "groupId": 20,
                            "sortNum": 656,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u4e2d\u51b0\u673a",
                            "value": "3334",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "3334",
                                "groupId": 20,
                                "sortNum": 657,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "2814",
                                "sourceId": 3339
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3315",
                            "groupId": 20,
                            "sortNum": 660,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u5357\u51b0\u673a",
                            "value": "3335",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3335",
                                "groupId": 20,
                                "sortNum": 661,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "2825",
                                "sourceId": 3340
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3315",
                            "groupId": 20,
                            "sortNum": 664,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u5317\u51b0\u673a",
                            "value": "3336",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3336",
                                "groupId": 20,
                                "sortNum": 665,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "2813",
                                "sourceId": 3337
                              },
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3336",
                                "groupId": 20,
                                "sortNum": 667,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "2817",
                                "sourceId": 3338
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3311",
                        "groupId": 20,
                        "sortNum": 671,
                        "grade": 2,
                        "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u7a7a\u538b",
                        "value": "3316",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3316",
                            "groupId": 20,
                            "sortNum": 672,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u7a7a\u538b2",
                            "value": "3319",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                                "parentId": "3319",
                                "groupId": 20,
                                "sortNum": 673,
                                "grade": 2,
                                "label": "AKMC_AIRC5",
                                "value": "2808",
                                "sourceId": 3321
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3316",
                            "groupId": 20,
                            "sortNum": 676,
                            "grade": 2,
                            "label": "\u6a21\u7ec4\u4e09\u56db\u671f\u7a7a\u538b1",
                            "value": "3320",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                                "parentId": "3320",
                                "groupId": 20,
                                "sortNum": 677,
                                "grade": 2,
                                "label": "AKMC_AIRC",
                                "value": "2794",
                                "sourceId": 3322
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3311",
                        "groupId": 20,
                        "sortNum": 681,
                        "grade": 2,
                        "label": "5F\u4e2d\u95f4\u4ed3\u7a7a\u8c03",
                        "value": "3317",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3317",
                            "groupId": 20,
                            "sortNum": 682,
                            "grade": 2,
                            "label": "AKMC_5ACP4",
                            "value": "2820",
                            "sourceId": 3318
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 687,
                "grade": 2,
                "label": "\u673a\u7bb1\u5382",
                "value": "2881",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 688,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u84b8\u6c7d",
                    "value": "3120",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3120",
                        "groupId": 20,
                        "sortNum": 689,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u56db\u671f\u84b8\u6c7d",
                        "value": "3216",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3216",
                            "groupId": 20,
                            "sortNum": 690,
                            "grade": 2,
                            "label": "ZQLLJ:SIQ",
                            "value": "2869",
                            "sourceId": 3219
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3120",
                        "groupId": 20,
                        "sortNum": 693,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e94\u671f\u84b8\u6c7d",
                        "value": "3217",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3217",
                            "groupId": 20,
                            "sortNum": 694,
                            "grade": 2,
                            "label": "ZQLLJ:5Q",
                            "value": "2871",
                            "sourceId": 3218
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 698,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u4e09\u56db\u671f",
                    "value": "3121",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3121",
                        "groupId": 20,
                        "sortNum": 699,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u56db\u671f\u51b0\u673a",
                        "value": "3159",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3159",
                            "groupId": 20,
                            "sortNum": 700,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u5357\u51b0\u673a",
                            "value": "3178",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3178",
                                "groupId": 20,
                                "sortNum": 701,
                                "grade": 2,
                                "label": "AKMC_AP1",
                                "value": "2825",
                                "sourceId": 3184
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3159",
                            "groupId": 20,
                            "sortNum": 704,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u4e2d\u51b0\u673a",
                            "value": "3179",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e09\u56db\u671f\u7279\u7075\u7a7a\u8c03\u4e3b\u673a",
                                "parentId": "3179",
                                "groupId": 20,
                                "sortNum": 705,
                                "grade": 2,
                                "label": "AKMC_AP2",
                                "value": "2814",
                                "sourceId": 3183
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3159",
                            "groupId": 20,
                            "sortNum": 708,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u5317\u51b0\u673a",
                            "value": "3180",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3180",
                                "groupId": 20,
                                "sortNum": 709,
                                "grade": 2,
                                "label": "AKMC_AP31",
                                "value": "2817",
                                "sourceId": 3181
                              },
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3180",
                                "groupId": 20,
                                "sortNum": 711,
                                "grade": 2,
                                "label": "AKMC_AP32",
                                "value": "2813",
                                "sourceId": 3182
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3121",
                        "groupId": 20,
                        "sortNum": 715,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u56db\u671f\u51b7\u5374\u5854",
                        "value": "3160",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3160",
                            "groupId": 20,
                            "sortNum": 716,
                            "grade": 2,
                            "label": "AKMC_AP10",
                            "value": "2803",
                            "sourceId": 3167
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3121",
                        "groupId": 20,
                        "sortNum": 719,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u56db\u671f\u7a7a\u8c03\u6cf5",
                        "value": "3161",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3161",
                            "groupId": 20,
                            "sortNum": 720,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671fP2\u6cf5",
                            "value": "3168",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3168",
                                "groupId": 20,
                                "sortNum": 721,
                                "grade": 2,
                                "label": "AKMC_AP7",
                                "value": "2788",
                                "sourceId": 3176
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3161",
                            "groupId": 20,
                            "sortNum": 724,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u51b7\u70ed\u6cf5",
                            "value": "3169",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3169",
                                "groupId": 20,
                                "sortNum": 725,
                                "grade": 2,
                                "label": "AKMC_AP8",
                                "value": "2819",
                                "sourceId": 3174
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3161",
                            "groupId": 20,
                            "sortNum": 728,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671fP1\u6cf5",
                            "value": "3170",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3170",
                                "groupId": 20,
                                "sortNum": 729,
                                "grade": 2,
                                "label": "AKMC_AP5",
                                "value": "2821",
                                "sourceId": 3177
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3161",
                            "groupId": 20,
                            "sortNum": 732,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u51b7\u5374\u6cf5",
                            "value": "3171",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3171",
                                "groupId": 20,
                                "sortNum": 733,
                                "grade": 2,
                                "label": "AKMC_AP9",
                                "value": "2793",
                                "sourceId": 3173
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3161",
                            "groupId": 20,
                            "sortNum": 736,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671fP3\u6cf5",
                            "value": "3172",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3172",
                                "groupId": 20,
                                "sortNum": 737,
                                "grade": 2,
                                "label": "AKMC_AP6",
                                "value": "2791",
                                "sourceId": 3175
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3121",
                        "groupId": 20,
                        "sortNum": 741,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u56db\u671f\u7a7a\u538b",
                        "value": "3162",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3162",
                            "groupId": 20,
                            "sortNum": 742,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u7a7a\u538b2",
                            "value": "3163",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad92",
                                "parentId": "3163",
                                "groupId": 20,
                                "sortNum": 743,
                                "grade": 2,
                                "label": "AKMC_AIRC5",
                                "value": "2808",
                                "sourceId": 3165
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3162",
                            "groupId": 20,
                            "sortNum": 746,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u56db\u671f\u7a7a\u538b1",
                            "value": "3164",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e00~\u56db\u671f\u7a7a\u538b\u7ad91",
                                "parentId": "3164",
                                "groupId": 20,
                                "sortNum": 747,
                                "grade": 2,
                                "label": "AKMC_AIRC",
                                "value": "2794",
                                "sourceId": 3166
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 752,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u4e94\u671f",
                    "value": "3122",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3122",
                        "groupId": 20,
                        "sortNum": 753,
                        "grade": 2,
                        "label": "\u4e94\u671fB\u680b",
                        "value": "3127",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 754,
                            "grade": 2,
                            "label": "B\u680b1F\u629b\u4e38\u673a",
                            "value": "3220",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3220",
                                "groupId": 20,
                                "sortNum": 755,
                                "grade": 2,
                                "label": "YZ_BPWJ",
                                "value": "2762",
                                "sourceId": 3245
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 758,
                            "grade": 2,
                            "label": "B\u680b\u8d27\u68af",
                            "value": "3221",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3221",
                                "groupId": 20,
                                "sortNum": 759,
                                "grade": 2,
                                "label": "YZ_BDT",
                                "value": "2727",
                                "sourceId": 3238
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 762,
                            "grade": 2,
                            "label": "B\u680b\u603b\u52a8\u529b",
                            "value": "3222",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fB\u680b\u603b\u52a8\u529b",
                                "parentId": "3222",
                                "groupId": 20,
                                "sortNum": 763,
                                "grade": 2,
                                "label": "YZ_BDLZ",
                                "value": "2769",
                                "sourceId": 3253
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 766,
                            "grade": 2,
                            "label": "B\u680b1F\u6298\u5f2f1",
                            "value": "3223",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3223",
                                "groupId": 20,
                                "sortNum": 767,
                                "grade": 2,
                                "label": "YZ_BZW1",
                                "value": "2746",
                                "sourceId": 3251
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 770,
                            "grade": 2,
                            "label": "B\u680b1F\u94b3\u5de5",
                            "value": "3224",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3224",
                                "groupId": 20,
                                "sortNum": 771,
                                "grade": 2,
                                "label": "YZ_BQGQ",
                                "value": "2767",
                                "sourceId": 3249
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 774,
                            "grade": 2,
                            "label": "B\u680b1F\u7a7a\u8c03",
                            "value": "3225",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3225",
                                "groupId": 20,
                                "sortNum": 775,
                                "grade": 2,
                                "label": "YZ_B1FKTX",
                                "value": "2737",
                                "sourceId": 3247
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 778,
                            "grade": 2,
                            "label": "B\u680b3F\u7a7a\u8c03",
                            "value": "3226",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3226",
                                "groupId": 20,
                                "sortNum": 779,
                                "grade": 2,
                                "label": "YZ_B3FKTX",
                                "value": "2770",
                                "sourceId": 3240
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 782,
                            "grade": 2,
                            "label": "B\u680b\u697c\u9876\u5e9f\u6c14",
                            "value": "3227",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3227",
                                "groupId": 20,
                                "sortNum": 783,
                                "grade": 2,
                                "label": "YZ_B4FFQ",
                                "value": "2757",
                                "sourceId": 3239
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 786,
                            "grade": 2,
                            "label": "B\u680b\u8336\u6c34\u95f4\u7a7a\u8c03",
                            "value": "3228",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3228",
                                "groupId": 20,
                                "sortNum": 787,
                                "grade": 2,
                                "label": "YZ_BCSJKT",
                                "value": "2758",
                                "sourceId": 3237
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 790,
                            "grade": 2,
                            "label": "B\u680b1F\u51b2\u5e8a",
                            "value": "3229",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3229",
                                "groupId": 20,
                                "sortNum": 791,
                                "grade": 2,
                                "label": "YZ_BCC",
                                "value": "2749",
                                "sourceId": 3252
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 794,
                            "grade": 2,
                            "label": "B\u680b1F\u94c6\u9489",
                            "value": "3230",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3230",
                                "groupId": 20,
                                "sortNum": 795,
                                "grade": 2,
                                "label": "YZ_BMDQ",
                                "value": "2755",
                                "sourceId": 3248
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 798,
                            "grade": 2,
                            "label": "B\u680b1F\u70b9\u710a\u673a",
                            "value": "3231",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3231",
                                "groupId": 20,
                                "sortNum": 799,
                                "grade": 2,
                                "label": "YZ_BDH",
                                "value": "2753",
                                "sourceId": 3246
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 802,
                            "grade": 2,
                            "label": "B\u680b2F\u7a7a\u8c03",
                            "value": "3232",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3232",
                                "groupId": 20,
                                "sortNum": 803,
                                "grade": 2,
                                "label": "YZ_B2FKTX",
                                "value": "2765",
                                "sourceId": 3241
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 806,
                            "grade": 2,
                            "label": "B\u680b1F\u62c9\u4e1d\u673a",
                            "value": "3233",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3233",
                                "groupId": 20,
                                "sortNum": 807,
                                "grade": 2,
                                "label": "YZ_BLSJ",
                                "value": "2644",
                                "sourceId": 3244
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 810,
                            "grade": 2,
                            "label": "B\u680b2F\u5e73\u53f0\u5e9f\u6c14",
                            "value": "3234",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3234",
                                "groupId": 20,
                                "sortNum": 811,
                                "grade": 2,
                                "label": "YZ_B2FFQ",
                                "value": "2744",
                                "sourceId": 3242
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 814,
                            "grade": 2,
                            "label": "B\u680b1F\u65b0\u589e\u7a7a\u8c03",
                            "value": "3235",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3235",
                                "groupId": 20,
                                "sortNum": 815,
                                "grade": 2,
                                "label": "YZ_B1FXKT",
                                "value": "2635",
                                "sourceId": 3243
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3127",
                            "groupId": 20,
                            "sortNum": 818,
                            "grade": 2,
                            "label": "B\u680b1F\u6298\u5f2f2",
                            "value": "3236",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3236",
                                "groupId": 20,
                                "sortNum": 819,
                                "grade": 2,
                                "label": "YZ_BZW2",
                                "value": "2754",
                                "sourceId": 3250
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3122",
                        "groupId": 20,
                        "sortNum": 823,
                        "grade": 2,
                        "label": "\u4e94\u671fC\u680b",
                        "value": "3128",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3128",
                            "groupId": 20,
                            "sortNum": 824,
                            "grade": 2,
                            "label": "C\u680b\u65b0\u589e\u52a8\u529b",
                            "value": "3254",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3254",
                                "groupId": 20,
                                "sortNum": 825,
                                "grade": 2,
                                "label": "YZ_JCF1",
                                "value": "2726",
                                "sourceId": 3256
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3128",
                            "groupId": 20,
                            "sortNum": 828,
                            "grade": 2,
                            "label": "C\/D\u680b\u7528\u7535",
                            "value": "3255",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fC\/D\u680b\u52a8\u529b",
                                "parentId": "3255",
                                "groupId": 20,
                                "sortNum": 829,
                                "grade": 2,
                                "label": "YZ_JCF",
                                "value": "2626",
                                "sourceId": 3257
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3122",
                        "groupId": 20,
                        "sortNum": 833,
                        "grade": 2,
                        "label": "\u4e94\u671f\u516c\u8f85",
                        "value": "3129",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 834,
                            "grade": 2,
                            "label": "\u4e94\u671f\u65b0\u5382\u603b\u7167\u660e",
                            "value": "3258",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3258",
                                "groupId": 20,
                                "sortNum": 835,
                                "grade": 2,
                                "label": "YZ_ZZM",
                                "value": "2728",
                                "sourceId": 3275
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 838,
                            "grade": 2,
                            "label": "\u7a7a\u8c03\u6c34\u6cf5\u51b7\u5374\u5854",
                            "value": "3259",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3259",
                                "groupId": 20,
                                "sortNum": 839,
                                "grade": 2,
                                "label": "YZ_KTSB",
                                "value": "2714",
                                "sourceId": 3270
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 842,
                            "grade": 2,
                            "label": "\u914d\u7535\u623f\u7167\u660e2",
                            "value": "3260",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3260",
                                "groupId": 20,
                                "sortNum": 843,
                                "grade": 2,
                                "label": "YZ_XPDF",
                                "value": "2651",
                                "sourceId": 3273
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 846,
                            "grade": 2,
                            "label": "\u5317\u51b0\u6c34\u4e3b\u673a",
                            "value": "3261",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5317\u51b0\u6c34\u673a",
                                "parentId": "3261",
                                "groupId": 20,
                                "sortNum": 847,
                                "grade": 2,
                                "label": "YZ_BSJ2",
                                "value": "2716",
                                "sourceId": 3272
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 850,
                            "grade": 2,
                            "label": "\u7532\u7c7b\u4ed3\u5e93\u7a7a\u8c03",
                            "value": "3262",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3262",
                                "groupId": 20,
                                "sortNum": 851,
                                "grade": 2,
                                "label": "YZ_WHCK",
                                "value": "2725",
                                "sourceId": 3269
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 854,
                            "grade": 2,
                            "label": "\u914d\u7535\u623f\u7167\u660e1",
                            "value": "3263",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3263",
                                "groupId": 20,
                                "sortNum": 855,
                                "grade": 2,
                                "label": "YZ_ZPDF",
                                "value": "2627",
                                "sourceId": 3274
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 858,
                            "grade": 2,
                            "label": "\u5e9f\u6c34\u7ad9\u52a8\u529b",
                            "value": "3264",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3264",
                                "groupId": 20,
                                "sortNum": 859,
                                "grade": 2,
                                "label": "YZ_FSZ2",
                                "value": "2718",
                                "sourceId": 3268
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 862,
                            "grade": 2,
                            "label": "\u6d88\u9632\u6cf5\u623f",
                            "value": "3265",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3265",
                                "groupId": 20,
                                "sortNum": 863,
                                "grade": 2,
                                "label": "YZ_XFBF",
                                "value": "2717",
                                "sourceId": 3267
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3129",
                            "groupId": 20,
                            "sortNum": 866,
                            "grade": 2,
                            "label": "\u5357\u51b0\u6c34\u4e3b\u673a",
                            "value": "3266",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671f\u5357\u51b0\u6c34\u673a",
                                "parentId": "3266",
                                "groupId": 20,
                                "sortNum": 867,
                                "grade": 2,
                                "label": "YZ_BSJ1",
                                "value": "2710",
                                "sourceId": 3271
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3122",
                        "groupId": 20,
                        "sortNum": 871,
                        "grade": 2,
                        "label": "\u4e94\u671fA\u680b",
                        "value": "3130",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 872,
                            "grade": 2,
                            "label": "\u5e94\u6025\u6c34\u6c60\u5e9f\u6c14",
                            "value": "3131",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3131",
                                "groupId": 20,
                                "sortNum": 873,
                                "grade": 2,
                                "label": "YZ_FQ3",
                                "value": "2723",
                                "sourceId": 3155
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 876,
                            "grade": 2,
                            "label": "A2F\u6db2\u70e4\u52a8\u529b",
                            "value": "3132",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3132",
                                "groupId": 20,
                                "sortNum": 877,
                                "grade": 2,
                                "label": "YZ_A2FYTX",
                                "value": "2787",
                                "sourceId": 3152
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 880,
                            "grade": 2,
                            "label": "A1F\u55b7\u623f",
                            "value": "3133",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3133",
                                "groupId": 20,
                                "sortNum": 881,
                                "grade": 2,
                                "label": "YZ_A1FFX",
                                "value": "2777",
                                "sourceId": 3156
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 884,
                            "grade": 2,
                            "label": "A\u680b\u8d27\u68af",
                            "value": "3134",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3134",
                                "groupId": 20,
                                "sortNum": 885,
                                "grade": 2,
                                "label": "YZ_ADT",
                                "value": "2719",
                                "sourceId": 3158
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 888,
                            "grade": 2,
                            "label": "A2F\u6253\u6837\u52a8\u529b",
                            "value": "3135",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3135",
                                "groupId": 20,
                                "sortNum": 889,
                                "grade": 2,
                                "label": "YZ_A2FES",
                                "value": "2782",
                                "sourceId": 3151
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 892,
                            "grade": 2,
                            "label": "A3F\u7a7a\u538b\u673a",
                            "value": "3136",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3136",
                                "groupId": 20,
                                "sortNum": 893,
                                "grade": 2,
                                "label": "YZ_AKYJ",
                                "value": "2785",
                                "sourceId": 3145
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 896,
                            "grade": 2,
                            "label": "A3F\u5e9f\u6c141",
                            "value": "3137",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fA\u680b\u6db2\u70e4\u5e9f\u6c141",
                                "parentId": "3137",
                                "groupId": 20,
                                "sortNum": 897,
                                "grade": 2,
                                "label": "YZ_FQ1",
                                "value": "2715",
                                "sourceId": 3147
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 900,
                            "grade": 2,
                            "label": "A1F\u6f06\u6e23\u623f",
                            "value": "3138",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3138",
                                "groupId": 20,
                                "sortNum": 901,
                                "grade": 2,
                                "label": "YZ_AQZF",
                                "value": "2779",
                                "sourceId": 3157
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 904,
                            "grade": 2,
                            "label": "A2F\u7a7a\u8c03\u7bb1",
                            "value": "3139",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3139",
                                "groupId": 20,
                                "sortNum": 905,
                                "grade": 2,
                                "label": "YZ_A2FKTX",
                                "value": "2774",
                                "sourceId": 3148
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 908,
                            "grade": 2,
                            "label": "A3F\u5e9f\u6c142",
                            "value": "3140",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "\u4e94\u671fA\u680b\u6db2\u70e4\u5e9f\u6c142",
                                "parentId": "3140",
                                "groupId": 20,
                                "sortNum": 909,
                                "grade": 2,
                                "label": "YZ_FQ2",
                                "value": "2720",
                                "sourceId": 3146
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 912,
                            "grade": 2,
                            "label": "A1F\u7a7a\u8c03\u7bb1",
                            "value": "3141",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3141",
                                "groupId": 20,
                                "sortNum": 913,
                                "grade": 2,
                                "label": "YZ_A1FKTX",
                                "value": "2775",
                                "sourceId": 3153
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 916,
                            "grade": 2,
                            "label": "A1F\u5faa\u73af\u6c34\u6c60",
                            "value": "3142",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3142",
                                "groupId": 20,
                                "sortNum": 917,
                                "grade": 2,
                                "label": "YZ_AXHSC",
                                "value": "2786",
                                "sourceId": 3154
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 920,
                            "grade": 2,
                            "label": "A2F\u4e1d\u53702\u7ebf",
                            "value": "3143",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3143",
                                "groupId": 20,
                                "sortNum": 921,
                                "grade": 2,
                                "label": "YZ_ASY2",
                                "value": "2778",
                                "sourceId": 3149
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3130",
                            "groupId": 20,
                            "sortNum": 924,
                            "grade": 2,
                            "label": "A2F\u4e1d\u53701\u7ebf",
                            "value": "3144",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3144",
                                "groupId": 20,
                                "sortNum": 925,
                                "grade": 2,
                                "label": "YZ_ASY1",
                                "value": "2772",
                                "sourceId": 3150
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 930,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u4e00\u671f",
                    "value": "3123",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3123",
                        "groupId": 20,
                        "sortNum": 931,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e00\u671f\u7a7a\u538b",
                        "value": "3214",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3214",
                            "groupId": 20,
                            "sortNum": 932,
                            "grade": 2,
                            "label": "AKMC_1002",
                            "value": "2645",
                            "sourceId": 3215
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 936,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u6c34\u8868",
                    "value": "3124",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 937,
                        "grade": 2,
                        "label": "\u5371\u5316\u54c1\u4ed3\u7528\u6c34",
                        "value": "3276",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3276",
                            "groupId": 20,
                            "sortNum": 938,
                            "grade": 2,
                            "label": "AKMC_WATER5WK",
                            "value": "2837",
                            "sourceId": 3288
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 941,
                        "grade": 2,
                        "label": "\u6d88\u9632\u6cf5\u623f\u7528\u6c34",
                        "value": "3277",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3277",
                            "groupId": 20,
                            "sortNum": 942,
                            "grade": 2,
                            "label": "AKMC_WATER5BF",
                            "value": "2843",
                            "sourceId": 3303
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 945,
                        "grade": 2,
                        "label": "B\u680b\u7528\u6c34",
                        "value": "3278",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3278",
                            "groupId": 20,
                            "sortNum": 946,
                            "grade": 2,
                            "label": "B\u680b\u524d\u5904\u7406\u7528\u6c34",
                            "value": "3289",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3289",
                                "groupId": 20,
                                "sortNum": 947,
                                "grade": 2,
                                "label": "AKMC_WATER5QCL",
                                "value": "2841",
                                "sourceId": 3296
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3278",
                            "groupId": 20,
                            "sortNum": 950,
                            "grade": 2,
                            "label": "B\u680b\u751f\u6d3b\u7528\u6c34",
                            "value": "3290",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3290",
                                "groupId": 20,
                                "sortNum": 951,
                                "grade": 2,
                                "label": "AKMC_WATER5BZ",
                                "value": "2842",
                                "sourceId": 3298
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3278",
                            "groupId": 20,
                            "sortNum": 954,
                            "grade": 2,
                            "label": "B\u680b2F\u751f\u4ea7\u7528\u6c34",
                            "value": "3291",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3291",
                                "groupId": 20,
                                "sortNum": 955,
                                "grade": 2,
                                "label": "AKMC_WATER5B2F",
                                "value": "2845",
                                "sourceId": 3294
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3278",
                            "groupId": 20,
                            "sortNum": 958,
                            "grade": 2,
                            "label": "B\u680b\u5176\u4ed6\u7528\u6c34",
                            "value": "3292",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3292",
                                "groupId": 20,
                                "sortNum": 959,
                                "grade": 2,
                                "label": "AKMC_WATER5BSC",
                                "value": "2840",
                                "sourceId": 3297
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3278",
                            "groupId": 20,
                            "sortNum": 962,
                            "grade": 2,
                            "label": "B\u680b1F\u751f\u4ea7\u7528\u6c34",
                            "value": "3293",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3293",
                                "groupId": 20,
                                "sortNum": 963,
                                "grade": 2,
                                "label": "AKMC_WATER5B1F",
                                "value": "2839",
                                "sourceId": 3295
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 967,
                        "grade": 2,
                        "label": "\u4e09\u671f\u524d\u5904\u7406\u7528\u6c34",
                        "value": "3279",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3279",
                            "groupId": 20,
                            "sortNum": 968,
                            "grade": 2,
                            "label": "AKMC_WATER3",
                            "value": "2830",
                            "sourceId": 3305
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 971,
                        "grade": 2,
                        "label": "A\u680b\u7528\u6c34",
                        "value": "3280",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3280",
                            "groupId": 20,
                            "sortNum": 972,
                            "grade": 2,
                            "label": "A\u680b\u751f\u4ea7\u7528\u6c34",
                            "value": "3299",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3299",
                                "groupId": 20,
                                "sortNum": 973,
                                "grade": 2,
                                "label": "AKMC_WATER5ASC",
                                "value": "2836",
                                "sourceId": 3302
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3280",
                            "groupId": 20,
                            "sortNum": 976,
                            "grade": 2,
                            "label": "A\u680b\u751f\u6d3b\u7528\u6c34",
                            "value": "3300",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3300",
                                "groupId": 20,
                                "sortNum": 977,
                                "grade": 2,
                                "label": "AKMC_WATER5ASH",
                                "value": "2829",
                                "sourceId": 3301
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 981,
                        "grade": 2,
                        "label": "\u5e9f\u6c34\u7ad9\u7528\u6c342",
                        "value": "3281",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3281",
                            "groupId": 20,
                            "sortNum": 982,
                            "grade": 2,
                            "label": "AKMC_WATER5FS2",
                            "value": "2831",
                            "sourceId": 3285
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 985,
                        "grade": 2,
                        "label": "\u4e94\u671f\u7a7a\u8c03\u7528\u6c34",
                        "value": "3282",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3282",
                            "groupId": 20,
                            "sortNum": 986,
                            "grade": 2,
                            "label": "AKMC_WATER5KT",
                            "value": "2848",
                            "sourceId": 3287
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 989,
                        "grade": 2,
                        "label": "\u5e9f\u6c34\u7ad9\u7528\u6c341",
                        "value": "3283",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3283",
                            "groupId": 20,
                            "sortNum": 990,
                            "grade": 2,
                            "label": "AKMC_WATER5FS1",
                            "value": "2838",
                            "sourceId": 3286
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3124",
                        "groupId": 20,
                        "sortNum": 993,
                        "grade": 2,
                        "label": "\u4e94\u671f\u6d88\u9632\u7528\u6c34",
                        "value": "3284",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3284",
                            "groupId": 20,
                            "sortNum": 994,
                            "grade": 2,
                            "label": "AKMC_WATER5XF",
                            "value": "2847",
                            "sourceId": 3304
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 998,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u4e09\u671f",
                    "value": "3125",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3125",
                        "groupId": 20,
                        "sortNum": 999,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u671f6F",
                        "value": "3185",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3185",
                            "groupId": 20,
                            "sortNum": 1000,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u671f6F\u7167\u660e",
                            "value": "3188",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3188",
                                "groupId": 20,
                                "sortNum": 1001,
                                "grade": 2,
                                "label": "AKMC_3S6FL",
                                "value": "2660",
                                "sourceId": 3197
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3185",
                            "groupId": 20,
                            "sortNum": 1004,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u671f6F\u8f66\u95f4\u7a7a\u8c03",
                            "value": "3189",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3189",
                                "groupId": 20,
                                "sortNum": 1005,
                                "grade": 2,
                                "label": "AKMC_6FPAI",
                                "value": "2681",
                                "sourceId": 3194
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3185",
                            "groupId": 20,
                            "sortNum": 1008,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u52a8\u529b1",
                            "value": "3190",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3190",
                                "groupId": 20,
                                "sortNum": 1009,
                                "grade": 2,
                                "label": "AKMC_3S6F1",
                                "value": "2682",
                                "sourceId": 3196
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3185",
                            "groupId": 20,
                            "sortNum": 1012,
                            "grade": 2,
                            "label": "\u4e09\u671f6F\u52a8\u529b2",
                            "value": "3191",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3191",
                                "groupId": 20,
                                "sortNum": 1013,
                                "grade": 2,
                                "label": "AKMC_3S6F2",
                                "value": "2675",
                                "sourceId": 3195
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3185",
                            "groupId": 20,
                            "sortNum": 1016,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u671f6F\u529e\u516c\u7a7a\u8c03",
                            "value": "3192",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3192",
                                "groupId": 20,
                                "sortNum": 1017,
                                "grade": 2,
                                "label": "AKMC_6ACP1",
                                "value": "2810",
                                "sourceId": 3193
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3125",
                        "groupId": 20,
                        "sortNum": 1021,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u671f2F",
                        "value": "3186",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3186",
                            "groupId": 20,
                            "sortNum": 1022,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u671f2F\u7167\u660e",
                            "value": "3198",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3198",
                                "groupId": 20,
                                "sortNum": 1023,
                                "grade": 2,
                                "label": "AKMC_3S2FL",
                                "value": "2657",
                                "sourceId": 3201
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3186",
                            "groupId": 20,
                            "sortNum": 1026,
                            "grade": 2,
                            "label": "\u4e09\u671f2F\u529e\u516c\u7a7a\u8c03",
                            "value": "3199",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3199",
                                "groupId": 20,
                                "sortNum": 1027,
                                "grade": 2,
                                "label": "AKMC_2ACP1",
                                "value": "2797",
                                "sourceId": 3200
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3125",
                        "groupId": 20,
                        "sortNum": 1031,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e09\u671f1F",
                        "value": "3187",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3187",
                            "groupId": 20,
                            "sortNum": 1032,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u52a8\u529b2",
                            "value": "3202",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3202",
                                "groupId": 20,
                                "sortNum": 1033,
                                "grade": 2,
                                "label": "AKMC_3S1F2",
                                "value": "2670",
                                "sourceId": 3209
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3187",
                            "groupId": 20,
                            "sortNum": 1036,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u52a8\u529b1",
                            "value": "3203",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3203",
                                "groupId": 20,
                                "sortNum": 1037,
                                "grade": 2,
                                "label": "AKMC_3S1F1",
                                "value": "2687",
                                "sourceId": 3210
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3187",
                            "groupId": 20,
                            "sortNum": 1040,
                            "grade": 2,
                            "label": "\u673a\u7bb1\u4e09\u671f1F\u7167\u660e",
                            "value": "3204",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3204",
                                "groupId": 20,
                                "sortNum": 1041,
                                "grade": 2,
                                "label": "AKMC_3S1FL",
                                "value": "2662",
                                "sourceId": 3211
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3187",
                            "groupId": 20,
                            "sortNum": 1044,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u529e\u516c\u7a7a\u8c03",
                            "value": "3205",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3205",
                                "groupId": 20,
                                "sortNum": 1045,
                                "grade": 2,
                                "label": "AKMC_1ACP1",
                                "value": "2805",
                                "sourceId": 3207
                              }
                            ]
                          },
                          {
                            "orgId": 1,
                            "type": "node",
                            "description": "",
                            "parentId": "3187",
                            "groupId": 20,
                            "sortNum": 1048,
                            "grade": 2,
                            "label": "\u4e09\u671f1F\u8f66\u95f4\u7a7a\u8c03",
                            "value": "3206",
                            "children": [
                              {
                                "orgId": 1,
                                "type": "Machine",
                                "description": "",
                                "parentId": "3206",
                                "groupId": 20,
                                "sortNum": 1049,
                                "grade": 2,
                                "label": "AKMC_1ACP1H",
                                "value": "2789",
                                "sourceId": 3208
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2881",
                    "groupId": 20,
                    "sortNum": 1054,
                    "grade": 2,
                    "label": "\u673a\u7bb1\u5382\u4e8c\u671f",
                    "value": "3126",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3126",
                        "groupId": 20,
                        "sortNum": 1055,
                        "grade": 2,
                        "label": "\u673a\u7bb1\u4e8c\u671f\u7a7a\u538b",
                        "value": "3212",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3212",
                            "groupId": 20,
                            "sortNum": 1056,
                            "grade": 2,
                            "label": "AKMC_NB4P",
                            "value": "2691",
                            "sourceId": 3213
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "orgId": 1,
                "type": "node",
                "description": "",
                "parentId": "2874",
                "groupId": 20,
                "sortNum": 1061,
                "grade": 2,
                "label": "\u751f\u6d3b\u533a",
                "value": "2882",
                "children": [
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2882",
                    "groupId": 20,
                    "sortNum": 1062,
                    "grade": 2,
                    "label": "\u516c\u5bd3\u697c\u98df\u5802\u63d2\u5ea7",
                    "value": "3368",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3368",
                        "groupId": 20,
                        "sortNum": 1063,
                        "grade": 2,
                        "label": "AKMC_SBOCDP",
                        "value": "2701",
                        "sourceId": 3396
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2882",
                    "groupId": 20,
                    "sortNum": 1066,
                    "grade": 2,
                    "label": "\u751f\u6d3b\u533a\u4e00\u56db\u671f\u7167\u660e",
                    "value": "3369",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "\u751f\u6d3b\u533a",
                        "parentId": "3369",
                        "groupId": 20,
                        "sortNum": 1067,
                        "grade": 2,
                        "label": "AKMC_DORM",
                        "value": "2699",
                        "sourceId": 3395
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2882",
                    "groupId": 20,
                    "sortNum": 1070,
                    "grade": 2,
                    "label": "\u751f\u6d3b\u533a\u6c34\u8868",
                    "value": "3370",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1071,
                        "grade": 2,
                        "label": "\u4e94\u671f\u5bbf\u820d\u7528\u6c34",
                        "value": "3374",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3374",
                            "groupId": 20,
                            "sortNum": 1072,
                            "grade": 2,
                            "label": "AKMC_WATER5SS",
                            "value": "2846",
                            "sourceId": 3386
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1075,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5bbf\u820d\u5973\u6c34\u8868",
                        "value": "3375",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3375",
                            "groupId": 20,
                            "sortNum": 1076,
                            "grade": 2,
                            "label": "AKMC_WATER12",
                            "value": "2855",
                            "sourceId": 3388
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1079,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5bbf\u820d\u5973\u6c34\u8868",
                        "value": "3376",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3376",
                            "groupId": 20,
                            "sortNum": 1080,
                            "grade": 2,
                            "label": "AKMC_WATER10",
                            "value": "2852",
                            "sourceId": 3390
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1083,
                        "grade": 2,
                        "label": "\u4e00\u671f\u5bbf\u820d\u7537\u6c34\u8868",
                        "value": "3377",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3377",
                            "groupId": 20,
                            "sortNum": 1084,
                            "grade": 2,
                            "label": "AKMC_WATER9",
                            "value": "2851",
                            "sourceId": 3391
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1087,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u5bbf\u820d\u7537\u6c34\u8868",
                        "value": "3378",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3378",
                            "groupId": 20,
                            "sortNum": 1088,
                            "grade": 2,
                            "label": "AKMC_WATER11",
                            "value": "2856",
                            "sourceId": 3389
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1091,
                        "grade": 2,
                        "label": "\u4e94\u671f\u70ed\u56de\u6536\u6c34",
                        "value": "3379",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3379",
                            "groupId": 20,
                            "sortNum": 1092,
                            "grade": 2,
                            "label": "AKMC_CLRTER5A",
                            "value": "2867",
                            "sourceId": 3383
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1095,
                        "grade": 2,
                        "label": "\u4e94\u671f\u98df\u5802\u7528\u6c34",
                        "value": "3380",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3380",
                            "groupId": 20,
                            "sortNum": 1096,
                            "grade": 2,
                            "label": "AKMC_WATER5ST",
                            "value": "2835",
                            "sourceId": 3387
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1099,
                        "grade": 2,
                        "label": "\u4e8c\u671f\u70ed\u56de\u6536\u6c34",
                        "value": "3381",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3381",
                            "groupId": 20,
                            "sortNum": 1100,
                            "grade": 2,
                            "label": "AKMC_CLRTER1",
                            "value": "2866",
                            "sourceId": 3385
                          }
                        ]
                      },
                      {
                        "orgId": 1,
                        "type": "node",
                        "description": "",
                        "parentId": "3370",
                        "groupId": 20,
                        "sortNum": 1103,
                        "grade": 2,
                        "label": "\u4e09\u56db\u671f\u70ed\u56de\u6536\u6c34",
                        "value": "3382",
                        "children": [
                          {
                            "orgId": 1,
                            "type": "Machine",
                            "description": "",
                            "parentId": "3382",
                            "groupId": 20,
                            "sortNum": 1104,
                            "grade": 2,
                            "label": "AKMC_CLRTER",
                            "value": "2865",
                            "sourceId": 3384
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2882",
                    "groupId": 20,
                    "sortNum": 1108,
                    "grade": 2,
                    "label": "\u751f\u6d3b\u533a\u4e94\u671f\u7167\u660e",
                    "value": "3371",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3371",
                        "groupId": 20,
                        "sortNum": 1109,
                        "grade": 2,
                        "label": "YZ_SHQ3",
                        "value": "2731",
                        "sourceId": 3392
                      },
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3371",
                        "groupId": 20,
                        "sortNum": 1111,
                        "grade": 2,
                        "label": "YZ_SHQ1",
                        "value": "2730",
                        "sourceId": 3393
                      },
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3371",
                        "groupId": 20,
                        "sortNum": 1113,
                        "grade": 2,
                        "label": "YZ_SHQ2",
                        "value": "2732",
                        "sourceId": 3394
                      }
                    ]
                  },
                  {
                    "orgId": 1,
                    "type": "node",
                    "description": "",
                    "parentId": "2882",
                    "groupId": 20,
                    "sortNum": 1116,
                    "grade": 2,
                    "label": "\u751f\u6d3b\u533a\u84b8\u6c7d",
                    "value": "3372",
                    "children": [
                      {
                        "orgId": 1,
                        "type": "Machine",
                        "description": "",
                        "parentId": "3372",
                        "groupId": 20,
                        "sortNum": 1117,
                        "grade": 2,
                        "label": "ZQLLJ:SHQ",
                        "value": "2872",
                        "sourceId": 3373
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  // 获取区域
  {
    url: '/getAlarmRuleListDataApi',
    method: 'get',
    timeout,
    response: () => {
      return {
        code: result_code,
        data: [
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          },
          {
            alarmName: 'warning_month_serious_電',
            alarmLevel: 'serious',
            alarmType: '警告',
            alarmDescription: '月電量異常告警',
            alarmSwitch: true
          }
        ]
      }
    }
  }
]
