import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosError,
  AxiosResponse
} from 'axios'

import qs from 'qs'

import { configs } from '@/config/axios/config'
import utils from '@/utils/cookie'
import { ElMessage } from 'element-plus'
import router from '@/router'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retry?: number // 添加 'retry' 属性
  retryDelay?: number
  retryCount?: number
}

const { base_url } = configs
export const PATH_URL = base_url[import.meta.env.VITE_API_BASEPATH]

const axiosConfig: CustomAxiosRequestConfig = {
  baseURL: PATH_URL, // api 的 base_url
  timeout: configs.request_timeout, // 请求超时时间
  retry: 0,
  retryDelay: 1000
}
// 创建axios实例
const service: AxiosInstance = axios.create(axiosConfig)

// request拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const bool =
      (config.headers as AxiosRequestHeaders)['Content-Type'] ===
      'application/x-www-form-urlencoded'
    if (config.method === 'post' && bool) {
      config.data = qs.stringify(config.data)
    }
    // get参数编码
    if (config.method === 'get' && config.params) {
      let url = config.url as string
      url += '?'
      const keys = Object.keys(config.params)
      for (const key of keys) {
        if (config.params[key] !== void 0 && config.params[key] !== null) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
      config.url = url
    }
    config.withCredentials = true
    parseBaseFromPath(config)

    if (window.location.ancestorOrigins?.length > 0) {
      const EIToken = sessionStorage.getItem('EIToken')
      config.headers.Authorization = 'Bearer ' + EIToken
    }
    return config
  },
  (error: AxiosError) => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse<Recordable>) => {
    if (response.status === 200 || response.status === 201) {
      return response
    } else {
      ElMessage.error(response.data.message)
    }
  },
  (error: AxiosError) => {
    // 超时处理 error.config是一个对象，包含上方create中设置的三个参数
    const config: CustomAxiosRequestConfig = error.config

    if (config.url.includes('/auth/api/v1/refreshtoken')) {
      config.retry = 3
    }

    // retryCount用来记录当前是第几次发送请求
    config.retryCount = config.retryCount || 0

    // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
    if (config.retryCount >= config.retry) {
      if (error.message === 'Network Error') {
        //message为"Network Error"代表断网了
        ElMessage.error('网络连接已断开，请检查网络')
        return
      } else if (error.message === 'timeout of 60000ms exceeded') {
        //网太慢了，60秒内没有接收到数据，这里的60000ms对应上方timeout设置的值
        ElMessage.error('请求超时，请检查网络')
        return
      } else {
        //除以上两种以外的所有错误，包括接口报错 400 500 之类的
        // 处理响应错误
        if (error.response.status) {
          switch (error.response.status) {
            // 未登录，跳转到登录页并携带当前页路径，登录成功后跳转
            case 401:
              localStorage.setItem('userRole', null)
              // @ts-ignore
              localStorage.setItem('userRoleList', [])
              router.replace({
                path: '/login',
                query: { redirect: router.currentRoute.value.fullPath }
              })
              ElMessage({
                message: error.response.data.message,
                duration: 3000,
                type: 'error'
              })
              break
            // token过期，对用户提示然后清除本地token再跳转
            case 403:
              localStorage.setItem('userRole', null)
              // @ts-ignore
              localStorage.setItem('userRoleList', [])
              ElMessage({
                message: t('portal.axios.loginExpires'),
                duration: 3000
              })
              sessionStorage.removeItem('EIToken')
              setTimeout(() => {
                router.replace({
                  path: '/login',
                  query: { redirect: router.currentRoute.value.fullPath }
                })
              }, 1000)
              break
            //请求不存在
            case 404:
            // ElMessage({
            //   message: '请求不存在',
            //   duration: 3000
            // })
            // break

            // 其他错误，提示错误
            default:
              return Promise.reject(error)
            // ElMessage.error({
            //   message: error?.response?.data?.message || error?.response?.data?.msg,
            //   duration: 3000
            // })
          }
        }
      }
    }

    // 记录请求次数+1
    config.retryCount += 1

    // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
    const backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })

    if (config.retryCount <= config.retry) {
      // 再次发送请求
      return backoff.then(function () {
        return service(config)
      })
    }
  }
)
const parseBaseFromPath = (config) => {
  const portal = location.origin.includes('portal') ? location.origin : ''
  const nsArray = ['portal-ibms', 'portal-hvac', 'portal-carbonr']
  if (portal) {
    const portalPrefix = nsArray.find((p) => portal.includes(p))
    if (portalPrefix) {
      if (/^\/auth/.test(config.url)) {
        config.withCredentials = true
        config.baseURL = portal.replace(portalPrefix, 'portal-apm')
      }
      if (/^\/api-apm/.test(config.url)) {
        config.withCredentials = true
        config.baseURL = portal.replace(portalPrefix, 'portal-apm')
      }
      if (/^\/alarm-process/.test(config.url)) {
        config.withCredentials = true
        config.baseURL = portal.replace(portalPrefix, 'portal-apm')
      }
      if (/^\/iems/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/iems/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-bee')
      }
      if (/^\/sharing/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/sharing/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-eco-apportion')
      }
      if (/^\/api-enterprise/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-enterprise/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-enterprise')
      }
      if (/^\/measure-apm/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/measure-apm/, '')
        config.baseURL = portal.replace(portalPrefix, 'portal-apm')
      }
      if (/^\/loginSet/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/loginSet/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-eco-common')
      }
      if (/^\/api-maintenance/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-maintenance/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-eco-common')
      }
      if (/^\/api-fmcs/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-fmcs/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-eco-common')
      }
      if (/^\/api-ibms/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-ibms/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-ibms')
      }
      if (/^\/log-api/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/log-api/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-eco-common')
      }
      if (/^\/saas-composer/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/saas-composer/, '')
        config.baseURL = portal.replace(portalPrefix, 'saas-composer')
      }
      if (/^\/api-fm/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-fm/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-fm')
      }
      if (/^\/dashboard/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/dashboard/, '')
        config.baseURL = portal.replace(portalPrefix, 'dashboard')
      }
      if (/^\/api-hvac/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-hvac/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-hvac')
      }
      if (/^\/api-carbonr/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-carbonr/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-carbonr')
      }
      if (/^\/api-carbons/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-carbons/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-carbons')
      }
      if (/^\/api-ecoenterprise/.test(config.url)) {
        config.withCredentials = true
        config.url = config.url.replace(/^\/api-ecoenterprise/, '')
        config.baseURL = portal.replace(portalPrefix, 'api-ecoenterprise')
      }
    }
  } else {
    if (/^\/saas-composer/.test(config.url)) {
      // const portal = 'https://portal-apm-ibms-fmcs-cluster01.axa-dev.wise-paas.top/'
      const portal = 'https://portal-apm-iems-portal-cluster01.axa-qa.wise-paas.top/'
      config.withCredentials = true
      config.url = config.url.replace(/^\/saas-composer/, '')
      config.baseURL = portal.replace('portal-apm', 'saas-composer')
      // 本地开发环境使用
      const cookieToken = utils.getCookie('EIToken')
      config.headers.Authorization = 'Bearer ' + cookieToken
    }
  }
}
export { service }
