<script setup lang="ts">
import { computed, ref } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { isDark } from '@/utils/is'
import { useDesign } from '@/hooks/web/useDesign'
import { getCustomsApi, getCustomsConfigApi, getVersion } from '@/api/login'
import { getMapKey, getOrgSwitchType } from '@/api/auth'
import { setCssVar } from '@/utils'
const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')
const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

const loading = ref(false)
let siteTitle = computed(() => appStore.getTitle)

let siteFavivon = computed(() => appStore.getFavicon)
const version = ref('')
const getAllVersion = () => {
  getVersion().then((res) => {
    // 将获取到的框架版本直接赋值 ECOWatch版本存到session
    version.value = res?.data?.data?.fmcs_framework
    sessionStorage.setItem('v-portal', version.value)
    if (version.value) {
      // 判断版本号是否是以v开头 不是则加v
      if (version.value[0] !== 'v') version.value = 'v' + version.value
    }
    sessionStorage.setItem(
      'v-ECOWatch',
      res?.data?.data['api-bee'] ? res?.data?.data['api-bee'] : ''
    )
  })
}
const isJson = (str) => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj == 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

// get 获取个性化数据
const getCustoms = () => {
  if (window.location.hash.includes('login') || window.location.hash === '#/') {
    console.log('loading')
    loading.value = true
  }
  getCustomsConfigApi()
    .then((resFirst) => {
      let flagDefault = true
      if (resFirst.data.data && resFirst.data.data[0]?.type === 'custom') {
        flagDefault = false
      }
      getCustomsApi()
        .then((res) => {
          if (res.data) {
            if (res.data.length === 0) {
              // 新增
            } else {
              let data = res.data
              let index
              if (flagDefault) {
                index = data.findIndex((item) => {
                  return item.type === 'default'
                })
              } else {
                index = data.findIndex((item) => {
                  return item.type === 'custom'
                })
              }
              // 编辑
              let logoInfo = JSON.parse(data[index].logo)
              let faviconInfo = JSON.parse(data[index].favicon)
              let backInfo = JSON.parse(data[index].backgroundImage)
              let advlogo = JSON.parse(data[index].advlogo)
              if (isJson(data[index].loginBoxCSS) && data[index].CSSEnable) {
                let obj = JSON.parse(data[index].loginBoxCSS)
                for (const key in obj) {
                  setCssVar(`--login-${key}`, obj[key])
                }
              } else {
                let obj = {
                  top: '50%',
                  right: '330px',
                  height: '32rem',
                  background: '#ffffff'
                }
                for (const key in obj) {
                  setCssVar(`--login-${key}`, obj[key])
                }
              }
              appStore.setTitle(data[index].siteTitleIn.zh)
              appStore.setCopyRight(data[index].copyright)
              setTimeout(() => {
                loading.value = false
              }, 1000)
              appStore.setPlatName(data[index].sitNameIn.zh)
              localStorage.setItem('PlatName', data[index].sitNameIn.zh)
              if (logoInfo.type && logoInfo.url) {
                appStore.setwiseLogo(logoInfo.type + ',' + logoInfo.url)
              }
              if (advlogo.type && advlogo.url) {
                appStore.setAdvlogo(advlogo.type + ',' + advlogo.url)
              }
              if (faviconInfo.type && faviconInfo.url) {
                appStore.setfavicon(faviconInfo.type + ',' + faviconInfo.url)
              }
              if (backInfo.type && backInfo.url) {
                appStore.setback(backInfo.type + ',' + backInfo.url)
              }
              localStorage.setItem('backUrl', logoInfo.type + ',' + logoInfo.url)
            }
            getFavision()
          }
        })
        .catch((error) => {
          setTimeout(() => {
            loading.value = false
          }, 1000)
          console.log(error)
        })
    })
    .catch(() => {
      setTimeout(() => {
        loading.value = false
      }, 1000)
    })
}
const getMapKeyAPI = () => {
  getMapKey().then((res) => {
    console.log('res:', res.data)
    if (res.data.length !== 0) {
      if (res.data[0]?.content !== '') {
        localStorage.setItem('MapKey', res.data[0]?.content)
      }
    }
  })
}
const getOrgSwitchTypeAPI = () => {
  getOrgSwitchType().then((res) => {
    console.log('res:', res.data)
    if (res.data.length !== 0) {
      if (res.data[0]?.content !== '') {
        localStorage.setItem('id', res.data[0]?.id)
        localStorage.setItem('name', res.data[0]?.name)
        localStorage.setItem('type', res.data[0]?.type)
        localStorage.setItem('content', res.data[0]?.content)
      }
    }
  })
}
// 动态修改网页favision
const getFavision = () => {
  let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  if (siteFavivon.value == ',') {
    link.href = '/favicon.ico'
  } else {
    link.href = siteFavivon.value // 为后端返回的数据 需为base64 路径
  }
  document.getElementsByTagName('head')[0].appendChild(link)
  document.title = siteTitle.value
}

// 根据浏览器当前主题设置系统主题色
const setDefaultTheme = () => {
  // getCustoms()
  getCustoms()
  const isDarkTheme = isDark()
  appStore.setIsDark(isDarkTheme)
}
getMapKeyAPI()
getOrgSwitchTypeAPI()
getAllVersion()
setDefaultTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" v-loading="loading" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}
#v-menu .el-scrollbar__view {
  background-color: var(--left-menu-bg-color) !important;
}

html,
body {
  font-family: 'Microsoft JhengHei' !important;
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }

  .el-card__body {
    padding: var(--el-card-padding-self);
    height: inherit;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}
.el-button:not(.is-text) {
  //background: #008cd6 !important;
}
.el-card__header {
  padding: calc(var(--el-card-padding) - 15px) calc(var(--el-card-padding) - 15px) !important;
  background: #f3f3f3;
}
#v-menu .el-scrollbar__view {
  background-color: var(--left-menu-bg-color) !important;
}
</style>
<style scoped>
:deep(.el-loading-mask) {
  background: var(--card-bg) !important;
}
</style>
