import { i18n } from '@/plugins/vueI18n'

type I18nGlobalTranslation = {
  (key: string): string
  (key: string, locale: string): string
  (key: string, locale: string, list: unknown[]): string
  (key: string, locale: string, named: Record<string, unknown>): string
  (key: string, list: unknown[]): string
  (key: string, named: Record<string, unknown>): string
}

type I18nGlobalTranslationTm = {
  (key: string): any
  (key: string, locale: string): any
  (key: string, locale: string, list: unknown[]): any
  (key: string, locale: string, named: Record<string, unknown>): any
  (key: string, list: unknown[]): any
  (key: string, named: Record<string, unknown>): any
}

type I18nTranslationRestParameters = [string, any]

const getKey = (namespace: string | undefined, key: string) => {
  if (!namespace) {
    return key
  }
  if (key.startsWith(namespace)) {
    return key
  }
  return `${namespace}.${key}`
}

export const useI18n = (
  namespace?: string
): {
  t: I18nGlobalTranslation
  tm: I18nGlobalTranslationTm
} => {
  const normalFn = {
    t: (key: string) => {
      return getKey(namespace, key)
    },
    tm: (key: string) => {
      return getKey(namespace, key)
    }
  }

  if (!i18n) {
    return normalFn
  }

  const { ...methods } = i18n.global

  const fn = (type: 't' | 'tm'): I18nGlobalTranslation | I18nGlobalTranslationTm => {
    return (key: string, ...arg: any[]) => {
      if (!key) return ''
      if (!key.includes('.') && !namespace) return key
      return i18n.global[type](getKey(namespace, key), ...(arg as I18nTranslationRestParameters))
    }
  }
  return {
    ...methods,
    t: fn('t'),
    tm: fn('tm')
  }
}

export const t = (key: string) => key
