// import Vue from 'vue'
import Vuex from 'vuex'
// import { i18n, useI18n } from '@/js/lang/lang'
const CONFIG = {
  url: location.origin.includes('portal')
    ? location.origin
    : 'https://portal-ibms-system-cluster01.qa-ecowatch.wise-paas.top',
  prefix: 'portal-ibms',
  menuframePrefix: 'portal-menuframe',
  version: '1.15.6',
  product: 'BEMS',
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3VudHJ5IjoiIiwiY3JlYXRpb25UaW1lIjoxNjg0NDcyODI1LCJleHAiOjE2ODQ0NzY0MjUsImZpcnN0TmFtZSI6ImFkbWluIiwiaWF0IjoxNjg0NDcyODI1LCJpZCI6ImNkZDAyNGM3LTZkYzctNDNkYS1iMDMwLWZjMjQxMmVhYzBiZiIsImlzcyI6Indpc2UtcGFhcyIsImxhc3RNb2RpZmllZFRpbWUiOjE2NTY2NDA1NjUsImxhc3ROYW1lIjoiYWR2YW50ZWNoIiwicmVmcmVzaFRva2VuIjoiZGI5OTQzMTYtYWU3Yi00ZmY3LWFkYWMtYzhhZjQ4YmM1NDVmIiwic3RhdHVzIjoiIiwidXNlcm5hbWUiOiJhZG1pbkBhZHZhbnRlY2guY29tLmNuIn0.YN1WQB56XwIC4Yv9ESspaFt33niVXZdvpoh1RgTPy6Y'
}
// import common from '../js/common'

// Vue.use(Vuex)
// useI18n(Vue)

// const vm = new Vue({
//   i18n
// })
const $lang = function (name) {
  if (window.vm && window.vm.$t) {
    return window.vm.$t('store.' + name)
  }
}

export default new Vuex.Store({
  state: {
    organizationOptions: [],
    classifyOptions: [],
    tableData: '',
    tabsData: [],
    bemsOrgId: 'ibms',
    bemsImported: false,
    selectedId: '',
    hourTableData: [],
    isCollapse: false,

    // 通知群组
    infoOptions: [],

    // 单位
    classifyItemStore: '01000',
    measureValueStore: 0,
    indicatorValueStore: 0,
    SASS_BASE: location.origin.replace(CONFIG.prefix, 'saas-composer') + '/', // sass基础地址
    DASHBOARD_BASE: location.origin.replace(CONFIG.prefix, 'dashboard'), // dashboard基础地址
    // 权限
    rightJson: {},
    // 外面传进来的apmOrgId
    apmOrgId: '',
    unitStore: ''
  },

  getters: {
    classifyOptionsAll({ classifyOptions }) {
      return classifyOptions
      // let target = []
      // if (classifyOptions) {
      //   target = JSON.parse(JSON.stringify(classifyOptions))
      //   if (target && target.length) {
      //     target.push({
      //       label: $lang('zongYongNeng'),
      //       value: '00000',
      //       unit: 'kgce',
      //       disabled: false
      //     })
      //   }
      // }

      // return target
    },

    classifyValue2Item(state, { classifyOptionsAll }) {
      const target = {}
      const _classifyOptions = classifyOptionsAll
      if (_classifyOptions && _classifyOptions.length) {
        fnEachClassifyOptions(_classifyOptions, target)
      }

      function fnEachClassifyOptions(options, target) {
        options.forEach((val) => {
          const _obj = {
            value: val.value,
            label: val.label,
            unit: val.unit,
            name: val.name
          }
          target[val.value] = _obj

          if (val.children && val.children.length) {
            fnEachClassifyOptions(val.children, target)
          }
        })
      }
      target['00000'] = {
        label: '',
        name: '',
        value: '00000',
        unit: 'kgce',
        disabled: false
      }
      return target
    },
    classifyUnit({ classifyItemStore }, { classifyValue2Item }) {
      console.log('classifyItemStore', classifyItemStore)
      console.log('classifyValue2Item', classifyValue2Item)
      // @ts-ignore
      return classifyValue2Item[classifyItemStore]?.unit || ''
    },

    shareTypeOptions() {
      return [
        {
          label: $lang('area'),
          // label: '面积',
          value: 0
        },
        {
          label: $lang('rate'),
          value: 1
        },
        {
          label: $lang('number'),
          value: 2
        },
        {
          label: $lang('eDingGongLv'),
          value: 3
        }
      ]
    },

    meterStatusOptions() {
      return [
        {
          label: $lang('zhengChang'),
          value: 0
        },
        {
          label: $lang('zhengChang'),
          value: 1
        }
      ]
    },

    organizationOptionsTarget({ organizationOptions }) {
      const target = JSON.parse(JSON.stringify(organizationOptions))
      // common.mySort(target)
      return target
    },

    organizationParent({ organizationOptions }) {
      const target = {}
      const arr = []
      function fnValue2Label(input, arr) {
        input.forEach((val) => {
          const newArr = [...arr]
          target[val.value] = arr
          newArr.push(val.value)
          if (val.children) {
            fnValue2Label(val.children, newArr)
          }
        })
      }
      fnValue2Label(organizationOptions, arr)
      return target
    },

    locationValue2Building({ organizationOptions }) {
      const target = {}
      function fnTransformBuilding(options, label) {
        if (options && options.length) {
          options.forEach((val) => {
            if (!label) {
              target[val.value] = val.label
            } else {
              target[val.value] = label
            }
            fnTransformBuilding(val.children, target[val.value])
          })
        }
      }
      organizationOptions.forEach((val) => {
        target[val.value] = val.label
        fnTransformBuilding(val.children)
      })
      return target
    },

    locationValue2nodeType({ organizationOptions }) {
      const target = {}
      function fnTransformBodeType(options) {
        if (options && options.length) {
          options.forEach((val) => {
            target[val.value] = val.type

            fnTransformBodeType(val.children)
          })
        }
      }
      organizationOptions.forEach((val) => {
        target[val.value] = val.type
        fnTransformBodeType(val.children)
      })
      return target
    }

    // unitStore ({ classifyItemStore, measureValueStore, indicatorValueStore }) {
    //   // 根据分类分项判断
    //   let target = 'kWh'
    //   let _classifyItemStore = classifyItemStore
    //   if (!_classifyItemStore) {
    //     _classifyItemStore = '01000'
    //   }
    //   const _arr = _classifyItemStore.split('')
    //   const [, second] = _arr
    //   if (second === '0') {
    //     target = 'kWh'
    //   } else if (second === '1') {
    //     target = 'kWh'
    //   } else if (second === '2') {
    //     target = 't'
    //   } else if (second === '3') {
    //     target = 'm³'
    //   }

    //   // 根据对标项判断
    //   if (measureValueStore === 0) {
    //   } else if (measureValueStore === 2) {
    //     target = 'kWh'
    //   } else if (measureValueStore === 4) {
    //     target = 'kW'
    //   } else if (measureValueStore === 1) {
    //     target = 'tce'
    //   } else if (measureValueStore === 3) {
    //     target = 'kg'
    //   }

    //   if (indicatorValueStore === 4) {
    //     target = '%'
    //   }
    //   return target
    // }
  },

  mutations: {
    changeState(state, { type, value }) {
      state[type] = value
    }
  },

  actions: {},

  modules: {}
})
