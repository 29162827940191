import { useAxios } from '@/hooks/web/useAxios'

const request = useAxios()

const AUTH_PREFIX = '/auth/api/v1'
const ORG = '/org'
const ACCOUNT = '/account'
const ROLE = '/role'
const SERVICE = '/service'
const RESOURCE = '/resource'

// 新角色接口
export const newGetRole = (orgId, name, index, count) => {
  return request.get({
    url: `/loginSet/role?name=${name}&index=${index}&count=${count}`,
    headers: {
      'org-id': orgId
    }
  })
}
// 主题色
export const getTheme = () => {
  return request.get({
    url: `/loginSet/theme`
  })
}
export const getMapKey = () => {
  // return request.get({ url: 'http://192.168.11.101:9099/custom/config/items' })
  return request.get({ url: '/loginSet/custom/config/items?type=mapKey' })
}
// 獲取組織顯示模式
export const getOrgSwitchType = () => {
  // return request.get({ url: 'http://192.168.11.101:9099/custom/config/items' })
  return request.get({ url: '/loginSet/custom/config/items?type=org' })
}
// 主题色
export const addTheme = (data) => {
  return request.post({
    url: `/loginSet/theme`,
    data
  })
}

// 主题色
export const fixTheme = (data) => {
  return request.put({
    url: `/loginSet/theme`,
    data
  })
}

export const deleteTheme = (ids) => {
  return request.delete({
    url: `/loginSet/theme`,
    data: ids
  })
}
// 指定当前默认主题色
export const defTheme = (id) => {
  return request.put({
    url: `/loginSet/theme/set/${id}`
  })
}

// 指定当前默认主题色
export const getSystem = (lang, orgId) => {
  let actulLang = ''
  if (lang === 'zh-cn') {
    actulLang = 'zh'
  } else if (lang === 'zh-tw') {
    actulLang = 'zt'
  } else {
    actulLang = 'en'
  }
  return request.get({
    url: `/loginSet/system?queryType=all&lang=${actulLang}`,
    headers: {
      'org-id': orgId
    }
  })
}
export const getSystemSelf = (lang, orgId) => {
  let actulLang = ''
  if (lang === 'zh-cn') {
    actulLang = 'zh'
  } else if (lang === 'zh-tw') {
    actulLang = 'zt'
  } else {
    actulLang = 'en'
  }
  return request.get({
    url: `/loginSet/system?queryType=self&lang=${actulLang}`,
    headers: {
      'org-id': orgId
    }
  })
}

// map : custom config
export const getMap = (type, name) => {
  return request.get({ url: `/loginSet/custom/config/items?type=${type}&name=${name}` })
}

export const addMap = (data) => {
  return request.post({ url: '/loginSet/custom/config/items', data })
}

export const editMap = (data) => {
  return request.put({ url: '/loginSet/custom/config/items', data })
}

//
export const getOrg = (name) => {
  return request.get({
    url: `${AUTH_PREFIX}${ORG}?index=0&count=1000&name=${name}&includeAdmin=true`
  })
}
export const createOrg = (data) => {
  return request.post({
    url: `${AUTH_PREFIX}${ORG}`,
    data
  })
}
// 新增組織顯示模式
export const createOrgSwitchType = (data) => {
  return request.post({
    url: `/loginSet/custom/config/items`,
    data
  })
}
// 更新組織顯示模式
export const updateOrgSwitchType = (data) => {
  return request.put({
    url: `/loginSet/custom/config/items`,
    data
  })
}
// 绿电录入：取绿电列表
export const getGreenElectricity = (id, params, lang) => {
  return request.get({
    url: `/iems/v1/kpi/greenElectricity/${id}`,
    params,
    headers: {
      language: lang
    }
  })
}

// 绿电录入：新增绿电
export const addGreenElectricity = (data, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/greenElectricity`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 绿电录入-编辑：保存
export const editGreenElectricity = (data) => {
  return request.put({
    url: `/iems/v1/kpi/greenElectricity`,
    data
  })
}

// 能耗录入-删除绿电
export const deleteGreenElectricity = (data) => {
  return request.delete({
    url: `/iems/v1/kpi/greenElectricity`,
    data
  })
}

export const updateOrg = (data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ORG}`,
    data
  })
}
export const deleteOrg = (id) => {
  return request.delete({
    url: `${AUTH_PREFIX}${ORG}/byid?id=${id}`
  })
}

// 获取组织详情，主要是获取服务
export const getOrgById = (id) => {
  return request.get({
    url: `${AUTH_PREFIX}${ORG}/byid?id=${id}`
  })
}

// 获取所有服务
export const getServiceList = (type) => {
  return request.get({
    url: `${AUTH_PREFIX}/service?queryType=${type}&index=0&count=1000`
  })
}
// 给org配置可用服务
export const configOrgService = (data) => {
  return request.post({
    url: `${AUTH_PREFIX}${ORG}/service`,
    data
  })
}

// 获取当前登录账号权限
export const getAccountMe = () => {
  return request.get({
    url: `${AUTH_PREFIX}${ACCOUNT}/me`
  })
}

export const getAccount = (orgId, data) => {
  return request.post({
    url: `${AUTH_PREFIX}${ACCOUNT}/list`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}
export const getAccountAll = (orgId) => {
  return request.get({
    url: `${AUTH_PREFIX}${ACCOUNT}?index=0&count=100&userName=`,
    headers: {
      'org-id': orgId
    }
  })
}

// 创建用户。添加account到多org下，可以不绑定角色
export const createAccount = (data) => {
  return request.post({
    url: `${AUTH_PREFIX}${ACCOUNT}/crossorg`,
    data
  })
}

export const createGroup = (data, orgId) => {
  return request.post({
    url: `${AUTH_PREFIX}/accountgroup`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}
export const getGroupList = (orgId, name, index, count) => {
  return request.get({
    url: `${AUTH_PREFIX}/accountgroup?name=${name}&index=${index}&count=${count}`,
    headers: {
      'org-id': orgId
    }
  })
}
export const editGroup = (data, orgId) => {
  return request.put({
    url: `${AUTH_PREFIX}/accountgroup`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}
export const deleteGroup = (groupID, orgId) => {
  return request.delete({
    url: `${AUTH_PREFIX}/accountgroup?id=${groupID}`,
    headers: {
      'org-id': orgId
    }
  })
}
export const getTemplate = (orgId) => {
  return request.get({
    url: `/api-apm/api/v1/template`,
    headers: {
      'org-id': orgId
    }
  })
}
// 更新用户。添加account到多org下，可以不绑定角色
export const updateAccount = (data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ACCOUNT}/crossorg`,
    data
  })
}

// 查询用户详细信息
export const getAccountById = (id) => {
  return request.get({
    url: `${AUTH_PREFIX}${ACCOUNT}/crossorg/byid?id=${id}`
  })
}

// 获取指定org下的资源
export const getResourcesByOrgId = (id) => {
  return request.get({
    url: `${AUTH_PREFIX}${ACCOUNT}/byid?id=${id}`
  })
}

// 批量删除用户
export const deleteAccountList = (orgId, ids) => {
  return request.delete({
    url: `${AUTH_PREFIX}${ACCOUNT}/list`,
    data: { ids: ids },
    headers: {
      'org-id': orgId
    }
  })
}
// 批量修改用户状态
export const changeAccountStatus = (orgId, data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ACCOUNT}/list/status`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

// 查询绑定或者没有绑定某些角色的账户列表
export const getAccountByRole = (orgId, role, userName, index, count, isBindRole) => {
  return request.get({
    url: `${AUTH_PREFIX}${ACCOUNT}/single/byrole?roleName=${role}&userName=${userName}&index=${index}&count=${count}&bindRole=${isBindRole}`,
    headers: {
      'org-id': orgId
    }
  })
}

// 批量绑定用户
export const bindRoleList = (orgId, data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ACCOUNT}/list/bindrole`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

// 批量解绑用户
export const unbindRoleList = (orgId, data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ACCOUNT}/list/unbindrole`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

export const getRoleList = (orgId, name, index, count) => {
  return request.get({
    url: `${AUTH_PREFIX}${ROLE}?name=${name}&index=${index}&count=${count}`,
    headers: {
      'org-id': orgId
    }
  })
}

export const getRoleById = (orgId, roleId) => {
  return request.get({
    url: `${AUTH_PREFIX}${ROLE}/byid?id=${roleId}`,
    headers: {
      'org-id': orgId
    }
  })
}

export const deleteRoleById = (orgId, roleId) => {
  return request.delete({
    url: `${AUTH_PREFIX}${ROLE}?id=${roleId}`,
    headers: {
      'org-id': orgId
    }
  })
}

export const addRole = (orgId, data) => {
  return request.post({
    url: `${AUTH_PREFIX}${ROLE}`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

export const updateRole = (orgId, data) => {
  return request.put({
    url: `${AUTH_PREFIX}${ROLE}`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

export const deleteRole = (orgId, data) => {
  return request.delete({
    url: `${AUTH_PREFIX}${ROLE}/list`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

//  获取org下的服务
export const getOrgService = (orgId) => {
  return request.get({
    url: `${AUTH_PREFIX}${SERVICE}`,
    headers: {
      'org-id': orgId
    }
  })
}
// 查询该组织某一服务的资源类别
export const getResourceType = (orgId, service) => {
  return request.get({
    url: `${AUTH_PREFIX}${RESOURCE}/type?service=${service}`,
    headers: {
      'org-id': orgId
    }
  })
}
// 获取服务下的资源
export const getResource = (orgId, service, type, index, count, group?, name?) => {
  let url = `${AUTH_PREFIX}${RESOURCE}?service=${service}&type=${type}&index=${index}&count=${count}`
  if (group) {
    url = url + `&group=${group}`
  }
  if (name) {
    url = url + `&name=${name}`
  }
  return request.get({
    url: url,
    headers: {
      'org-id': orgId
    }
  })
}

// 获取多group下资源
export const getResourceByGroup = (orgId, data) => {
  return request.post({
    url: `${AUTH_PREFIX}${RESOURCE}/bygroups`,
    data,
    headers: {
      'org-id': orgId
    }
  })
}

export const getServiceRoleStatus = (service, orgId, roleId) => {
  let url = `/loginSet/role/area/status?orgId=${orgId}&roleId=${roleId}`
  if (service) {
    url = url + `&service=${service}`
  }
  // return request.get({
  //   url: url,
  //   headers: {
  //     'org-id': orgId
  //   }
  // })
  return new Promise((resolve) => {
    if (typeof orgId === 'undefined' || typeof roleId === 'undefined') {
      resolve({})
      return false
    }
    request
      .get({
        url: url,
        headers: {
          'org-id': orgId
        }
      })
      .then((res) => {
        if (res?.data?.data) {
          resolve({ data: res.data.data })
        } else {
          resolve({})
        }
      })
    // resolve({
    //   url: url,
    //   code: 0,
    //   data: {
    //     ECOWatch: 'config',
    //     CarbonR: 'read',
    //     HVAC: 'write'
    //   },
    //   message: 'abcde'
    // })
  })
}

// export const getResourceByGroup = (orgId, data) => {
//   let groups = ''
//   data.groups.forEach((item) => {
//     console.log('item: ', item)
//     groups = groups + '&group=' + item
//   })
//   return request.get({
//     url: `${AUTH_PREFIX}${RESOURCE}?service=${data.service}&type=${data.type}${groups}&index=${data.index}&count=${data.count}`,
//     headers: {
//       'org-id': orgId
//     }
//   })
// }

export const deleteResource = (orgId, id) => {
  return request.delete({
    url: `${AUTH_PREFIX}${RESOURCE}?id=${id}`,
    headers: {
      'org-id': orgId
    }
  })
}

export const addResource = (data) => {
  return request.post({
    url: `${AUTH_PREFIX}${RESOURCE}`,
    data
  })
}

export const updateResource = (data) => {
  return request.put({
    url: `${AUTH_PREFIX}${RESOURCE}`,
    data
  })
}

export const getSaaSOrg = () => {
  return request.get({
    url: `saas-composer/api/org`
  })
}

export const getSaaSPath = (orgId) => {
  return request.get({
    url: `saas-composer/api/sketchboard?org_id=${orgId}`
  })
}

export const getDashFolder = (id) => {
  return request.get({
    url: `dashboard/api/search?folderIds=${id}`
  })
}

export const getAllProject = (orgId) => {
  return request.get({
    url: `/api-apm/api/v1/project?index=0&count=1000`,
    headers: {
      'org-id': orgId
    }
  })
}

export const getgroupsByProject = (orgId, projectId) => {
  return request.get({
    url: `/api-apm/api/v1/project/groups?id=${projectId}&index=0&count=1000`,
    headers: {
      'org-id': orgId
    }
  })
}

// 获取资产组使用的模板
export const getTempByGroup = (orgId, groupId) => {
  return request.get({
    url: `/api-apm/api/v1/template/bygroup?groupId=${groupId}&withOutImage=true`,
    headers: {
      'org-id': orgId
    }
  })
}

// S3：测试连接
export const testConnect = (data) => {
  // return request.post({ url: '/iems/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/testhttps?minioService=${data.minioService}&minioAccessKey=${data.minioAccessKey}&minioSecretKey=${data.minioSecretKey}`
  })
}

// S3：测试连接http
export const testConnectHttp = (data) => {
  // return request.post({ url: '/mock/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/testhttp?minioService=${data.minioService}&minioAccessKey=${data.minioAccessKey}&minioSecretKey=${data.minioSecretKey}`
  })
}

// S3：测试连接
export const testConnectDb = () => {
  // return request.post({ url: '/iems/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/testdbs3`
  })
}

// S3：测试账号、密码
export const testUser = (data) => {
  // return request.post({ url: '/mock/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/testuser`,
    params: data
  })
}

// S3：测试账号、密码Db
export const testUserDb = () => {
  // return request.post({ url: '/mock/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/testdbuser`
  })
}

// S3：保存
export const s3Save = (data) => {
  // return request.post({ url: '/iems/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.post({
    url: `/iems/v1/set/variables`,
    data
  })
}

// S3：创建minio实例
export const s3CreateInstance = (data) => {
  // return request.post({ url: '/iems/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.get({
    url: `/iems/v1/s3/create?minioService=${data.minioService}&minioAccessKey=${data.minioAccessKey}&minioSecretKey=${data.minioSecretKey}`
  })
}

// S3：获取账密
export const s3GetInfo = () => {
  // return request.post({ url: '/iems/v1/simplejson/query/v1', data, headers: { orgid: 'ibms' } })
  return request.post({
    url: `/iems/v1/custom/variables`,
    data: {
      keys: ['minioService', 'autoReportUserName']
      // keys: ['minioService', 'minioAccessKey', 'minioSecretKey']
    }
  })
}

// 能源介质管理：获取分类分项数据
export const getSubitemApi = (options) => {
  return request.get({
    url: `/iems/v1/resources/newsubitems?language=${options['language']}&apmOrgId=${options['apmOrgId']}`
  })
}

// 能源介质管理：新增分类分项
export const addSubitem = (data) => {
  return request.post({
    url: `/iems/v1/resource`,
    data
  })
}

// 能源介质管理：修改分类分项
export const editSubitem = (id, data) => {
  return request.put({
    url: `/iems/v1/resources/${id}`,
    data
  })
}

// 能源介质管理：删除分类分项
export const deleteSubitem = (data) => {
  return request.delete({
    url: `/iems/v1/resources`,
    data
  })
}

// 能源介质管理：获取转换系数
export const getCoefficient = ({ subitem_code, apmOrgId }) => {
  return request.get({
    url: `/iems/v1/coefficients?subitem_code=${subitem_code}&apmOrgId=${apmOrgId}`
  })
}

// 能源介质管理：获取父级转换系数
export const getParentCoefficient = (data) => {
  return request.post({
    url: `/iems/v1/coefficients`,
    data
  })
}

// 能源介质管理：新增转换系数
export const addCoefficient = (data) => {
  return request.post({
    url: `/iems/v1/coefficient`,
    data
  })
}

// 能源介质管理：删除转换系数
export const deleteCoefficient = (id) => {
  return request.delete({
    url: `/iems/v1/coefficient`,
    data: { id }
  })
}

// 能源介质管理：编辑转换系数
export const editCoefficient = (id, data) => {
  return request.put({
    url: `/iems/v1/coefficients/${id}`,
    data
  })
}

// 能耗录入：取组织（区域、部门)
export const getOrganizationPatition = (orgId) => {
  return request.get({
    url: `/iems/v1/resources/neworganizations`,
    headers: {
      'org-id': orgId
    }
  })
}

// 面积录入-上传模板（面积录入）
export const uploadTemplateBuildingArea = (resourceId, data, apmOrgId, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/area/upload/${resourceId}`,
    data,
    headers: {
      'org-id': apmOrgId,
      language: i18n
    }
  })
}

// 面积录入：取面积列表
export const getBuildingArea = (id, params, lang) => {
  return request.get({
    url: `/iems/v1/kpi/area/${id}`,
    params,
    headers: {
      language: lang
    }
  })
}

// 面积录入：新增面积列表
export const addBuildingArea = (data) => {
  return request.post({
    url: `/iems/v1/kpi/area`,
    data
  })
}

// 面积录入：编辑面积列表
export const editBuildingArea = (data) => {
  return request.put({
    url: `/iems/v1/kpi/area`,
    data
  })
}

// 能耗录入-删除面积
export const deleteBuildingArea = (data) => {
  return request.delete({
    url: `/iems/v1/kpi/area`,
    data
  })
}

// 人数录入：取人数列表
export const getPopulation = (id, params, lang) => {
  return request.get({
    url: `/iems/v1/kpi/people/${id}`,
    params,
    headers: {
      language: lang
    }
  })
}

// 人数录入：新增人数列表
export const addPopulation = (data) => {
  return request.post({
    url: `/iems/v1/kpi/people`,
    data
  })
}

// 人数录入：编辑人数列表
export const editPopulation = (data) => {
  return request.put({
    url: `/iems/v1/kpi/people`,
    data
  })
}

// 能耗录入-删除人数
export const deletePopulation = (data) => {
  return request.delete({
    url: `/iems/v1/kpi/people`,
    data
  })
}

// 人数-上传模板
export const uploadTemplatePopulation = (resourceId, data, apmOrgId, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/people/upload/${resourceId}`,
    data,
    headers: {
      'org-id': apmOrgId,
      language: i18n
    }
  })
}

// 绿电录入-编辑：取绿电详情
export const getGreenElectricityDetail = (data) => {
  return request.post({
    url: `/iems/v1/kpi/greenElectricity/detail`,
    data
  })
}

// 能耗录入：取能耗列表
export const getEnergyConsumption = (id, lang) => {
  return request.get({
    url: `/iems/v1/kpi/energy/${id}`,
    headers: {
      language: lang
    }
  })
}

// 能耗录入-编辑：取能耗详情
export const getEnergyConsumptionDetail = (data) => {
  return request.post({
    url: `/iems/v1/kpi/energy/detail`,
    data
  })
}

// 能耗录入-新增能耗
export const addEnergy = (data, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/energy`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 能耗录入-编辑能耗
export const editEnergy = (data) => {
  return request.put({
    url: `/iems/v1/kpi/energy`,
    data
  })
}

// 能耗录入-删除能耗
export const deleteEnergy = (data) => {
  return request.delete({
    url: `/iems/v1/kpi/energy`,
    data
  })
}

// 能耗录入-下载模板（能耗、产值产量）
export const downloadTemplate = ({ language, type }, { responseType }) => {
  return request.get({
    url: `/iems/v1/kpi/energy/downtemplate?language=${language}&type=${type}`,
    responseType: responseType
  })
}

// 能耗录入-上传模板
export const uploadTemplate = (resourceId, data, apmOrgId, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/energy/upload/${resourceId}`,
    data,
    headers: {
      'org-id': apmOrgId,
      language: i18n
    }
  })
}

// 能耗录入：取产值产量列表
export const getOutputValue = (id, params, lang) => {
  return request.get({
    url: `/iems/v1/kpi/output/${id}`,
    params,
    headers: {
      language: lang
    }
  })
}

// 能耗录入-编辑：取产值产量详情
export const getOutputValueDetail = (data, lang) => {
  return request.post({
    url: `/iems/v1/kpi/output/detail`,
    data,
    headers: {
      language: lang
    }
  })
}

// 能耗录入-新增产值产量
export const addOutputValue = (data, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/output`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 能耗录入-编辑产值产量
export const editOutputValue = (data) => {
  return request.put({
    url: `/iems/v1/kpi/output`,
    data
  })
}

// 能耗录入-删除产值产量
export const deleteOutputValue = (data, i18n) => {
  return request.delete({
    url: `/iems/v1/kpi/output`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 能耗录入-上传模板（产值产量）
export const uploadTemplateOutput = (resourceId, data, apmOrgId, i18n) => {
  return request.post({
    url: `/iems/v1/kpi/output/upload/${resourceId}`,
    data,
    headers: {
      'org-id': apmOrgId,
      language: i18n
    }
  })
}

// 能耗录入：取单位信息值
export const getUnitInfo = (headers?) => {
  return request.get({
    url: `/iems/v1/kpi/output/unit`,
    headers
  })
}

// 能耗录入-编辑单位信息
export const editUnitInfo = (data) => {
  return request.post({
    url: `/iems/v1/kpi/output/unit`,
    data
  })
}

// 第三方接口列表 /interface
export const getInterface = (orgId) => {
  return request.get({
    url: `/iems/v1/interface?orgId=${orgId}`
  })
}

// 第三方接口测试链接
export const getInterfaceTest = (data, i18n) => {
  return request.post({
    url: `/iems/v1/interface/test/connect`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 第三方接口添加接口
export const addInterface = (data, i18n) => {
  return request.post({
    url: `/iems/v1/interface`,
    data,
    headers: {
      language: i18n
    }
  })
}

// 第三方接口编辑
export const editInterface = (data) => {
  return request.put({
    url: `/iems/v1/interface`,
    data
  })
}

// 第三方接口删除
export const deleteInterface = (data) => {
  return request.delete({
    url: `/iems/v1/interface`,
    data
  })
}

// 用户切换组织
export const changeOrgApi = (orgId) => {
  return request.put({
    url: `${AUTH_PREFIX}${ACCOUNT}/changeorg?orgId=${orgId}`
  })
}


