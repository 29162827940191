import { generateRoutesMTFn2 } from '../../../app/adv-wise-maintenance-front/ts/routerHelper'
import router from '@/router'
import { usePersonalConfig } from '../../../app/adv-wise-ecowatch-front/hooks/UsePersonalConfig'
import { generateRoutesECOFn2 } from '../../../app/adv-wise-ecowatch-front/ts/routerHelper'
// 注释代码为南侨路由
// import { generateRoutesEMSCockpitFn2 } from '../../../app/adv-wise-demo-front/ts/routerHelper'
import { defineStore } from 'pinia'
import {
  asyncRouterMapFront,
  constantRouterMapFront,
  mapRouterMapFront,
  overviewRouterMapFront,
  portalRouterMapFront,
  carbonrSelectRouterMapFront,
  cockpitRouterMapFront,
  videoMonitorRouterMapFront,
  ascFrontPortal
} from '@/router'
// import { powerRouterMapFront } from '../../../app/adv-wise-electric-front/router'
import { generateRoutesFn1, generateRoutesFn2, flatMultiLevelRoutes } from '@/utils/routerHelper'
import { store } from '@/store'
import { cloneDeep } from 'lodash-es'
import { computed, unref } from 'vue'
import { ElMessage } from 'element-plus'
import { useLocaleStore } from '@/store/modules/locale'
import { useAppStore } from "@/store/modules/app";
// import { ecoRouterMapFront } from '../../../app/adv-wise-ecowatch-front/router'
// import { acsRouterMapFront } from '../../../app/adv-wise-acs-front/router'
// import { mtRouterMapFront } from '../../../app/adv-wise-maintenance-front/router'
const localeStore = useLocaleStore()
const currentLang = computed(() => localeStore.getCurrentLocale)
const appStore = useAppStore()
export interface PermissionState {
  routers: AppRouteRecordRaw[]
  addRouters: AppRouteRecordRaw[]
  isAddRouters: boolean
  menuTabRouters: AppRouteRecordRaw[]
}

export const usePermissionStore = defineStore({
  id: 'permission',
  state: (): PermissionState => ({
    routers: [],
    addRouters: [],
    isAddRouters: false,
    menuTabRouters: []
  }),
  persist: {
    enabled: true
  },
  getters: {
    getRouters(): AppRouteRecordRaw[] {
      return this.routers
    },
    getAddRouters(): AppRouteRecordRaw[] {
      return flatMultiLevelRoutes(cloneDeep(this.addRouters))
    },
    getIsAddRouters(): boolean {
      return this.isAddRouters
    },
    getMenuTabRouters(): AppRouteRecordRaw[] {
      return this.menuTabRouters
    }
  },
  actions: {
    generateRoutes(
      type: 'admin' | 'test' | 'none',
      routers?: AppCustomRouteRecordRaw[] | string[],
      goWhere?: string
    ): Promise<unknown> {
      return new Promise<void>((resolve) => {
        let routerMap: AppRouteRecordRaw[] = []
        // let powerRouterMap: AppRouteRecordRaw[] = []
        // let fmRouterMap: AppRouteRecordRaw[] = []
        console.log('generateRoutes', type, routers, goWhere)
        if (type === 'admin') {
          // 模拟后端过滤菜单
          routerMap = generateRoutesFn2(routers as AppCustomRouteRecordRaw[])
        } else if (type === 'test') {
          // 模拟前端过滤菜单
          routerMap = generateRoutesFn1(cloneDeep(asyncRouterMapFront), routers as string[])
        } else {
        }
        // 进入智慧厂务管理平台逻辑开始
        this.addRouters = []
        let userRoleList = []
        if (localStorage.getItem('userRole') !== 'undefined') {
          userRoleList = JSON.parse(localStorage.getItem('userRole'))
        }

        const roleNames: string[] = []
        userRoleList?.forEach((item) => {
          roleNames.push(item.name)
        })

        // 应用中心页面portal: 判断用户是否为superAdmin、admin，是则进入应用中心页面；否，则跳过进入下一页。
        if (goWhere.includes('/portal') || goWhere === '/' || goWhere.includes('/login')) {
          // if (roleNames.includes('Admin') || roleNames.includes('SuperAdmin')) {
          console.log('/portal')
          this.addRouters = this.addRouters.concat(portalRouterMapFront)
          appStore.setCollapse(true)
          // }
        }
        // 地图页面map：跟用户角色权限没有关系，只需要判断用户是否多于一个org？
        // 是，则进入地图页面，
        // 否，则跳过进入下一页。
        if (goWhere.includes('/map')) {
          this.addRouters = this.addRouters.concat(mapRouterMapFront)
          appStore.setCollapse(true)
        }
        // carbonr选择页：跟用户角色权限没有关系，只需要判断是否多个carbonr的srp？
        // 是，则进入选择页，
        // 否，则直接进入carbonr页面。
        if (goWhere.includes('/carbonr-select')) {
          this.addRouters = this.addRouters.concat(carbonrSelectRouterMapFront)
        }

        // 工厂应用页面：
        // ①首先判断，该工厂是一个多SRP还是单个SRP的应用，如果是单个SRP，无论任何角色用户直接进入SRP首页；否则，判断第二条；
        // ②判断该用户的可用资源权限是否包含多个SRP？是，则进入此页；否，则直接跳入单一的SRP首页。
        // if (goWhere.includes('/overview')) {
        //   this.addRouters = this.addRouters.concat(overviewRouterMapFront)
        // }

        if (goWhere.includes('/overview')) {
          console.log(' ', overviewRouterMapFront)
          this.addRouters = this.addRouters.concat(overviewRouterMapFront)
          appStore.setCollapse(true)
        }
        // else {
        //   this.addRouters = this.addRouters.concat(overviewRouterMapFront)
        // }
        const collapse = computed(() => appStore.getCollapse)
        goWhere = goWhere.toLowerCase()


        // 系统设置路由
        if (goWhere.includes('frame')) {
          appStore.setCollapse(true)
          setTimeout(() => {
            appStore.setCollapse(false)
          })
          if (userRoleList.length === 1 && userRoleList[0].name === 'SuperAdmin') {
            asyncRouterMapFront[0].children[3].meta.hidden = false
            asyncRouterMapFront[1].children[0].meta.hidden = false
          } else {
            asyncRouterMapFront[0].children[3].meta.hidden = true
            asyncRouterMapFront[1].children[0].meta.hidden = true
          }
          this.addRouters = this.addRouters.concat(asyncRouterMapFront)
        } else {
          appStore.setCollapse(unref(collapse))
        }
        /* 驾驶舱 */
        if (goWhere.includes('cockpit') && !goWhere.includes('emscockpit')) {
          this.addRouters = this.addRouters.concat(cockpitRouterMapFront)
        }

        /* 视频监控 */
        if (goWhere.includes('videomonitor')) {
          this.addRouters = this.addRouters.concat(videoMonitorRouterMapFront)
        }
        // 重点设备设施
        if (goWhere.includes('facility')) {
          // 前端静态路由
          // routerMap = cloneDeep(powerRouterMapFront)
          // 接口获取动态路由
          const list = JSON.parse(localStorage.getItem('facilityRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesFacFn2
          this.addRouters = this.addRouters.concat(routerMap)
          if (routerMap.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }
        /* 空压站 菜单 acs开头 */
        if (goWhere.includes('compressor') && !goWhere.includes('facility')) {
          // 前端静态路由
          // routerMap = cloneDeep(acsRouterMapFront)
          // 接口获取动态路由
          const list = JSON.parse(localStorage.getItem('acsRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesACSFn2
          // 追加静态资源

          if (routerMap.length !== 0) {
            routerMap = routerMap.concat(ascFrontPortal)
          }
          this.addRouters = this.addRouters.concat(routerMap)
          if (routerMap.length === 0 || routerMap.length === 1) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /* ECOWatch 菜单 eco开头 */
        if (goWhere.includes('ecowatch')) {
          // 前端静态路由
          // routerMap = cloneDeep(ecoRouterMapFront)
          // 接口获取动态路由
          console.log('collapse', collapse)
          linkId = 0
          const listJson = localStorage.getItem('ecoRouters');
          let list = [];
          if (listJson) {
            try {
              list = JSON.parse(listJson);
            } catch (error) {
              console.log('Invalid JSON in localStorage:', error);
            }
          }
          dealLanguage(list, goWhere)
          // generateRoutesECOFn2
routerMap = cloneDeep(generateRoutesECOFn2(list))
          this.addRouters = this.addRouters.concat(routerMap)

          if (routerMap.length === 0) {
            // 威视特殊处理
            if (window.location.ancestorOrigins?.length !== 0) {
              localStorage.clear()
              window.location.reload()
            }
            appStore.setCollapse(unref(collapse))
            return
          }
          /* ECOWatch 全局配置 */
router.beforeEach((to, from, next) => {if (to.fullPath && to.fullPath.includes('ecowatch')) {usePersonalConfig(next) } else {next()}})
        }

        /* 电力系统 菜单 electric 开头 */
        if (goWhere.includes('power')) {
          // 前端静态路由
          // routerMap = cloneDeep(powerRouterMapFront)
          // 接口获取动态路由
          linkId = 0
          const list = JSON.parse(localStorage.getItem('powerRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesElectricFn2
          this.addRouters = this.addRouters.concat(routerMap)
          if (routerMap.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /* 暖通空调 菜单 hvac 开头 */
        if (goWhere.includes('hvac')) {
          // 接口获取动态路由
          linkId = 0
          const listJson = localStorage.getItem('hvacRouters');
          let list = [];
          if (listJson) {
            try {
              list = JSON.parse(listJson);
            } catch (error) {
              console.log('Invalid JSON in localStorage:', error);
            }
          }
          dealLanguage(list, goWhere)
          // generateRoutesHVACFn2
          this.addRouters = this.addRouters.concat(routerMap)

          if (routerMap.length === 0) {
            // 威视特殊处理
            if (window.location.ancestorOrigins?.length !== 0) {
              localStorage.clear()
              window.location.reload()
            }
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /* carbonrIso 菜单 carbonrIso 开头 */
        if (goWhere.includes('carbonr')) {
          linkId = 0
          const list = JSON.parse(localStorage.getItem('carbonrIsoRouters'))

          dealLanguage(list, goWhere)
          // generateRoutesCarbonRIsoFn3
          this.addRouters = this.addRouters.concat(routerMap)
          if (list.length === 0 || this.addRouters.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /* ecoenterprise 菜單 */
        if (goWhere.includes('ecoenterprise')) {
          linkId = 0
          const list = JSON.parse(localStorage.getItem('enterpriseRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesEcoEnterpriseFn3
          this.addRouters = this.addRouters.concat(routerMap)
          if (list.length === 0 || this.addRouters.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /*carbonrStd 菜单carbonrStd 开头*/
        if (goWhere.includes('carbons')) {
          linkId = 0
          const list = JSON.parse(localStorage.getItem('carbonrStdRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesCarbonRStdFn2
          this.addRouters = this.addRouters.concat(routerMap)
          if (routerMap.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }

        /* 运维管理 菜单maintenance 开头*/
        if (goWhere.includes('maintenance')) {
          const middleCollapse = unref(collapse)
          appStore.setCollapse(true)
          setTimeout(() => {
            appStore.setCollapse(middleCollapse)
          })
          // 前端静态路由
          // routerMap = cloneDeep(mtRouterMapFront)
          // 接口获取动态路由
          linkId = 0
          const list = JSON.parse(localStorage.getItem('maintenanceRouters'))
          dealLanguage(list, goWhere)
          // generateRoutesMTFn2
routerMap = cloneDeep(generateRoutesMTFn2(list))
          this.addRouters = this.addRouters.concat(routerMap)
          console.log('this.addRouters', this.addRouters)
          if (routerMap.length === 0) {
            // 威视特殊处理
            if (window.location.ancestorOrigins?.length !== 0) {
              localStorage.clear()
              window.location.reload()
            }
            appStore.setCollapse(unref(collapse))
            return
          }
        }
        if (goWhere.includes('emscockpit')) {
          const middleCollapse = unref(collapse)
          appStore.setCollapse(true)
          setTimeout(() => {
            appStore.setCollapse(middleCollapse)
          })
          linkId = 0
          const list = JSON.parse(localStorage.getItem('EMSCockpitRouters'))
          dealLanguage(list, goWhere)
          // EMSCockpitRouters
          // routerMap = cloneDeep(generateRoutesEMSCockpitFn2(list))
          this.addRouters = this.addRouters.concat(routerMap)
          if (routerMap.length === 0) {
            appStore.setCollapse(unref(collapse))
            return
          }
        }
        // 动态路由，404一定要放到最后面
        this.addRouters = this.addRouters.concat([
          {
            path: '/:path(.*)*',
            redirect: '/404',
            name: '404Page',
            meta: {
              hidden: true,
              breadcrumb: false
            }
          }
        ])
        // 渲染菜单的所有路由
        this.routers = cloneDeep(constantRouterMapFront).concat(this.addRouters)
        resolve()
      })
    },
    setIsAddRouters(state: boolean): void {
      this.isAddRouters = state
    },
    setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
      this.menuTabRouters = routers
    }
  }
})
let linkId = 0
const dealLanguage = (routers, goWhere) => {
  console.log('dealLanguage', routers)
  // debugger
  if (routers && routers.length !== 0) {
    routers = routers?.sort(function (a, b) {
      return a.orderNum - b.orderNum
    })
  }
  const goWhereAll = [
    'maintenance',
    'ecowatch',
    'carbonr',
    'carbons',
    'power',
    'compressor',
    'hvac',
    'facility',
    'ecoenterprise',
    'emscockpit'
  ]
  return routers?.forEach((r) => {
    // debugger
    if (r.children && r.children.length !== 0) {
      dealLanguage(r.children, goWhere)
    }
    r.meta = {
      title: '',
      scUrl: '',
      nameZH: r.nameZH || r.nameEN || r.nameZT || r.nameJP,
      nameTW: r.nameZT || r.nameEN || r.nameZH || r.nameJP,
      nameEN: r.nameEN || r.nameZH || r.nameZT || r.nameJP,
      nameJP: r.nameJP || r.nameEN || r.nameZH || r.nameZT,
      fullFlag: typeof r.isFull !== 'undefined' ? r.isFull : true,
      chatBoxFlag: typeof r.showChatBot !== 'undefined' ? r.showChatBot : false
    }

    if (currentLang.value.lang === 'zh-cn') {
      r.meta.title = r.nameZH || r.nameEN || r.nameZT || r.nameJP
    } else if (currentLang.value.lang === 'zh-tw') {
      r.meta.title = r.nameZT || r.nameEN || r.nameZH || r.nameJP
    } else if (currentLang.value.lang.includes('en')) {
      r.meta.title = r.nameEN || r.nameZH || r.nameZT || r.nameJP
    } else {
      r.meta.title = r.nameJP || r.nameEN || r.nameZH || r.nameJP
    }
    r.name = r.componentName + r.meta.title
    if (r.pathType === 'iEMS') {
      r.name = r.componentName
    }
    if (r.pathType === 'iEMS' && r.level === 1) {
      if (r.component === '' || r.componentName === '') {
        ElMessage.info('提示：组件丢失，菜单资源错误')
      }
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
      }
      r.path = r.menuPath
      if (r.menuPath.startsWith('/')) {
        // debugger
        console.log('r.component:', r.component)
        if (r.component.includes('views')) {
          goWhereAll.forEach((item) => {
            if (goWhere.includes(item)) {
              r.path = `/${item}`
            }
          })
          r.meta.alwaysShow = false
          r.menuPath = r.menuPath.replace(`${r.path}/`, '')
          if (r.children && r.children.length !== 0) {
          } else {
            r.children = [
              {
                path: `${r.menuPath}`,
                component: `${r.component}`,
                name: r.name,
                meta: {
                  name: r.meta.title,
                  title: r.meta.title,
                  alwaysShow: false,
                  chatBoxFlag: r.meta.chatBoxFlag
                }
              }
            ]
          }
          r.component = '#'
          r.redirect = goWhere + '/' + r.menuPath
        } else if (r.component === '#') {
          if (r.children && r.children.length === 0) {
            r.redirect = ''
          }
        }
      } else {
        console.log('r.component', r.component)
        goWhereAll.forEach((item) => {
          if (goWhere.includes(item)) {
            r.path = `/${item}`
          }
        })
        if (r.children && r.children.length !== 0) {
          r.children = [
            {
              path: `${r.menuPath}`,
              component: `${r.component}`,
              name: r.name,
              meta: {
                name: r.meta.title,
                title: r.meta.title,
                chatBoxFlag: r.meta.chatBoxFlag
              },
              children: r.children
            }
          ]
        } else {
          r.children = [
            {
              path: `${r.menuPath}`,
              component: `${r.component}`,
              name: r.name,
              meta: {
                name: r.meta.title,
                title: r.meta.title,
                chatBoxFlag: r.meta.chatBoxFlag
              }
            }
          ]
        }
        r.component = '#'
      }
      // r.path = r.menuPath + r.id
    } else if (r.pathType === 'iEMS' && r.level !== 1) {
      if (r.component === '' || r.componentName === '') {
        ElMessage.info('提示：组件丢失，菜单资源错误')
        return
      }
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
      }
      if (r.menuPath.startsWith('/')) {
        console.log('r.menuPath', r.menuPath)
        r.path = r.menuPath.slice(1)
        if (r.component === '#') {
          r.component = '##'
        }
      } else {
        console.log('r.menuPath2', r.menuPath)
        r.path = r.menuPath
      }
    } else if (r.pathType === 'URL' && r.urlType === 'outer' && r.level === 1) {
      goWhereAll.forEach((item) => {
        if (goWhere.includes(item)) {
          r.path = `/${goWhere}/outer`
        }
      })
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = [
          {
            name: r.name + `${r.urlPage}`,
            path: `${r.urlPage}`,
            meta: { name: r.meta.title, title: r.meta.title, chatBoxFlag: r.meta.chatBoxFlag }
          }
        ]
      }
    } else if (r.pathType === 'URL' && r.urlType === 'innerPlatform' && r.level === 1) {
      goWhereAll.forEach((item) => {
        if (goWhere.includes(item)) {
          r.path = `/${goWhere}/innerPlatform`
        }
      })
      r.component = '#'
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = [
          {
            path: `Inner-link`,
            component: 'views/SC/Index',
            name: r.name + 'Inner-link',
            meta: {
              name: r.meta.title,
              title: r.meta.title,
              scUrl: `${r.urlPage}`,
              type: 'URL'
            }
          }
        ]
      }
    } else if (r.pathType === 'URL' && r.urlType === 'innerPlatform' && r.level !== 1) {
      r.path = `innerPlatformLevel`
      r.component = '#'
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = [
          {
            path: `Inner-link`,
            component: 'views/SC/Index',
            name: r.name + 'Inner-link',
            meta: {
              name: r.meta.title,
              title: r.meta.title,
              scUrl: `${r.urlPage}`,
              type: 'URL'
            }
          }
        ]
      }
    } else if (r.pathType === 'SaaS Composer' && r.level === 1) {
      let scJson = ''
      let tag = ''
      linkId++
      if (currentLang.value.lang === 'zh-cn') {
        scJson = r.scPathZH || r.scPathEN || r.scPathZT || r.scPathJP
        if (r.scPathZH !== '' && r.scPathZH.includes('/')) {
          tag = r.scPathZH.split('/')[0]
        }
      } else if (currentLang.value.lang === 'zh-tw') {
        scJson = r.scPathZT || r.scPathEN || r.scPathZH || r.scPathJP
        if (r.scPathZT !== '' && r.scPathZT.includes('/')) {
          tag = r.scPathZT.split('/')[0]
        }
      } else if (currentLang.value.lang.includes('en')) {
        scJson = r.scPathEN || r.scPathZH || r.scPathZT || r.scPathJP
        if (r.scPathZT !== '' && r.scPathEN.includes('/')) {
          tag = r.scPathEN.split('/')[0]
        }
      } else {
        scJson = r.scPathJP || r.scPathEN || r.scPathZH || r.scPathZT
        if (r.scPathZT !== '' && r.scPathJP.includes('/')) {
          tag = r.scPathJP.split('/')[0]
        }
      }
      scJson =
        window.location.origin.replace('portal-ibms', 'saas-composer') +
        '/' +
        `sceneAndDisplay.html` +
        '?' +
        `org_id=${r.urlPage}&` +
        `tag=${scJson}`
      console.log('r.children', r.scPathZH, tag)
      goWhereAll.forEach((item) => {
        if (goWhere.includes(item)) {
          r.path = `/${goWhere}/SC`
        }
      })
      r.component = '#'
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = []
        const copiedArray = cloneDeep([
          {
            name: `SC-link${linkId}`,
            path: `SC-link${linkId}`,
            component: 'views/SC/Index',
            meta: {
              name: r.meta.title,
              title: r.meta.title,
              type: 'SC',
              scUrl: scJson,
              fullFlag: true
            }
          }
        ])
        r.children = copiedArray
      }
      console.log('r.children', r.children)
    } else if (r.pathType === 'SaaS Composer' && r.level !== 1) {
      let scJson = ''
      let tag = ''
      linkId++
      console.log('SaaS Composer', r.scPathZH.split('/')[0])
      if (currentLang.value.lang === 'zh-cn') {
        scJson = r.scPathZH || r.scPathEN || r.scPathZT || r.scPathJP
        if (r.scPathZH !== '' && r.scPathZH.includes('/')) {
          tag = r.scPathZH.split('/')[0]
        }
      } else if (currentLang.value.lang === 'zh-tw') {
        scJson = r.scPathZT || r.scPathEN || r.scPathZH || r.scPathJP
        if (r.scPathZT !== '' && r.scPathZT.includes('/')) {
          tag = r.scPathZT.split('/')[0]
        }
      } else if (currentLang.value.lang.includes('en')) {
        scJson = r.scPathEN || r.scPathZH || r.scPathZT || r.scPathJP
        if (r.scPathZT !== '' && r.scPathEN.includes('/')) {
          tag = r.scPathEN.split('/')[0]
        }
      } else {
        scJson = r.scPathJP || r.scPathEN || r.scPathZH || r.scPathZT
        if (r.scPathZT !== '' && r.scPathJP.includes('/')) {
          tag = r.scPathJP.split('/')[0]
        }
      }
      //  console.log('r.children', window.location.origin.replace('portal-ibms', 'saas-composer'))

      scJson =
        window.location.origin.replace('portal-ibms', 'saas-composer') +
        '/' +
        `sceneAndDisplay.html` +
        '?' +
        `org_id=${r.urlPage}&` +
        `tag=${scJson}`
      console.log('scJson', scJson, tag)
      r.path = `SC`
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = [
          {
            name: `SC-link${linkId}`,
            path: `SC-link${linkId}`,
            component: 'views/SC/Index',
            meta: {
              name: r.meta.title,
              title: r.meta.title,
              type: 'SC',
              scUrl: scJson,
              fullFlag: true
            }
          }
        ]
      }
    } else if (r.pathType === 'URL' && r.urlType === 'outer' && r.level !== 1) {
      r.path = `outer`
      if (r.children && r.children.length !== 0) {
        r.meta.alwaysShow = true
        r.component = '#'
      } else {
        r.children = [
          {
            name: r.name + `${r.urlPage}`,
            path: `${r.urlPage}`,
            meta: { name: r.meta.title, title: r.meta.title }
          }
        ]
      }
    }
    if (r.children && r.children.length !== 0) {
      r.children.forEach((item) => {
        if (item.menuPath?.includes('saas-composer')) {
          if (r.children?.length === 1) {
            r.meta = {}
          }
          item.component = 'views/SC/Index'
          // 针对各SRP第一个组件作为SC展示需要做的特殊处理 2023.3.2
          r.redirect = goWhere + '/' + item.menuPath
        }
        // 旺旺案场电力数据查询通过菜单配置添加的资源需呈现一级目录显示
        if (item.menuPath === 'electricityDataQuery') {
          if (r.children?.length === 1) {
            r.meta = {}
          }
        }

        r.redirect = r.path + '/' + r?.children[0]?.path
        if (r?.children[0]?.children && r?.children[0]?.children.length !== 0) {
          let redirect = r.path + '/' + r.children[0].path
          let target = r.children[0]
          while (target.children && target.children.length !== 0) {
            redirect += `/${target.children[0].path}`
            target = target.children[0]
          }
          r.redirect = redirect
        }
      })
    }
  })
}

export const usePermissionStoreWithOut = () => {
  return usePermissionStore(store)
}
