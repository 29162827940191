import { useAxios } from '@/hooks/web/useAxios'

const request = useAxios()

// 获取全局配置
export const getWholeConfig = (params) => {
  return request.get({
    url: `/iems/v1/screenbox/variable`,
    params
  })
}

// 获取个性化设置
export const getPersonalConfig = (params) => {
  return request.get({
    url: `/iems/v1/personal/variable`,
    params
  })
}

// 保存某个页面的配置
export const postPersonConfig = (data) => {
  return request.post({ url: `/iems/v1/personal/variables`, data })
}
