import router, { resetRouter } from './router'
import { useAppStoreWithOut } from '@/store/modules/app'
import { useCache } from '@/hooks/web/useCache'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import utils from '@/utils/cookie'

const permissionStore = usePermissionStoreWithOut()

const appStore = useAppStoreWithOut()

const { wsCache } = useCache()

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()
const whiteList = ['/login', '/forgetPassword'] // 不重定向白名单


// @ts-ignore
router.beforeEach(async (to, from, next) => {
  // localStorage.setItem('isSetting', 'eco')
  // @ts-ignore
  start()
  loadStart()

  if (
    to?.path === '/ecowatch/performance/regionSingleAnalysis' ||
    to?.path === '/ecowatch/energyConsumptionAnalysis'
  ) {
    const data = {
      srp: 'ecowatch',
      goalMenu: 'router',
      command: 'changeMenu'
    }
    data.goalMenu = to.path
    window.top.postMessage(data, window.location.ancestorOrigins[0])
  }

  let userRoleList = []
  if (localStorage.getItem('userRole') !== 'undefined') {
    userRoleList = JSON.parse(localStorage.getItem('userRole'))
    console.info("🚀 ~ file:permission method: line:45 userRoleList-----", userRoleList)
  }

  let cookieToken = utils.getCookie('EIToken')
  const userInfo = wsCache.get(appStore.getUserInfo)

  console.info("🚀 ~ file:permission method: line:56 userInfo-----", userInfo)
  
  if (window.location.ancestorOrigins?.length > 0) {
    if (window.document.cookie.includes(';')) {
      const substrings = window.document.cookie.split(';');
      let EITokenSubstring;
      for (let i = 0; i < substrings.length; i++) {
          if (substrings[i].includes('EIToken=')) {
              EITokenSubstring = substrings[i];
              break;
          }
      }

      // 提取EIToken的值
      const EITokenValue = EITokenSubstring.split('=')[1];
      cookieToken = EITokenValue
    }
    
    const cookieTokenOld = sessionStorage.getItem('EIToken') || utils.getCookie('EIToken')
    // 在被威视嵌入的情况 如果角色不匹配会重新登录
    if (window.location.ancestorOrigins[0].includes('vishay')) {
      if (cookieToken) {
        if (cookieTokenOld !== cookieToken) {
          sessionStorage.setItem('EIToken', cookieToken)
          localStorage.clear()
          window.location.reload()
        }
      }
    }
    console.info("🚀 ~ file:permission method: line:51 cookieToken-----", cookieToken)
  }
  if (userInfo && userRoleList?.length > 0 && cookieToken) {
    // 去登录页面不拦截直接跳转登录页
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (
        to.name === 'NoFind' &&
        to.redirectedFrom &&
        [
          '/',
          '/map',
          '/overview',
          '/frame',
          '/setting',
          '/compressor',
          '/ecowatch',
          '/power',
          '/hvac',
          '/enterprise',
          '/carbonr',
          '/carbons',
          '/maintenance',
          '/swagger',
          '/cockpit',
          '/videoMonitor'
        ].includes('/' + to?.redirectedFrom?.fullPath?.split('/')[1])
      ) {
        if (to.redirectedFrom.fullPath.includes('/swagger')) {
          let url = window.location.origin
          if (to.redirectedFrom.fullPath.includes('/ecowatch')) {
            url = url.replace('portal-ibms', 'api-bee') + '/swagger'
          } else if (to.redirectedFrom.fullPath.includes('/enterprise')) {
            url = url.replace('portal-ibms', 'api-enterprise') + '/swagger'
          } else if (to.redirectedFrom.fullPath.includes('/eco-common')) {
            url = url.replace('portal-ibms', 'api-eco-common') + '/swagger/index.html'
          }
          window.location.href = url
        }

        resetRouter()
        await permissionStore.generateRoutes('none', [] as string[], to.redirectedFrom.fullPath)
        permissionStore.getAddRouters.forEach((route) => {
          router.addRoute(route as unknown as RouteRecordRaw) // 动态添加可访问路由表
        })
        next(to.redirectedFrom.fullPath)
        return
      }
      if (to.redirectedFrom && to.redirectedFrom?.fullPath.includes('/swagger')) {
        let url = window.location.origin
        if (to.redirectedFrom.fullPath.includes('/ecowatch')) {
          url = url.replace('portal-ibms', 'api-bee') + '/swagger'
        } else if (to.redirectedFrom.fullPath.includes('/enterprise')) {
          url = url.replace('portal-ibms', 'api-enterprise') + '/swagger'
        } else if (to.redirectedFrom.fullPath.includes('/eco-common')) {
          url = url.replace('portal-ibms', 'api-eco-common') + '/swagger/index.html'
        }
        window.open(url)
        // window.location.href = url
      }
      if (permissionStore.getIsAddRouters) {
        next()
        // setCssVar('--app-content-padding', '4px')
        // if (!to.redirectedFrom?.fullPath.includes('/auth')) {
        //   setCssVar('--app-content-padding', '0px')
        // }
        return
      }

      // 开发者可根据实际情况进行修改
      const roleRouters = []
      const userInfo = wsCache.get(appStore.getUserInfo)
      //test2none刷新少菜单
      userInfo?.role === 'admin'
        ? await permissionStore.generateRoutes('admin', roleRouters as AppCustomRouteRecordRaw[])
        : await permissionStore.generateRoutes('none', roleRouters as string[], to.fullPath)

      permissionStore.getAddRouters.forEach((route) => {
        router.addRoute(route as unknown as RouteRecordRaw) // 动态添加可访问路由表
      })
      const redirectPath = from.query.redirect || to.path
      let redirect = ''
      // 配电系统图从厂务平台登录进去后无法默认呈现的问题 2023.3.3 power
      if (!to?.path.includes('saas-composer')) {
        redirect = decodeURIComponent(redirectPath as string)
      } else {
        redirect = redirectPath as string
      }
      const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
      permissionStore.setIsAddRouters(true)
      next(nextData)
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (to.path !== '/portal') {
        next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      } else {
        next(`/login`) // 否则全部重定向到登录页
      }
    }
  }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})
