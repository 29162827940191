import type { App, Plugin } from 'vue'
import { isEmpty } from '@/utils/is'
import dayjs from 'dayjs'

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
  return str.replace(/\-(\w)/g, (_, letter: string) => {
    return letter.toUpperCase()
  })
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary)
    if (ret) {
      index = i
      return ret
    }
  })
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}
/**
 * Unix时间戳转为真实时间
 */
export function unixTime(unixTime: any) {
  const time = new Date(unixTime)
  const y = time.getFullYear()
  const m = time.getMonth() + 1
  const d = time.getDate()
  const h = time.getHours()
  const mm = time.getMinutes()
  const s = time.getSeconds()
  return (
    y +
    '-' +
    addZero(m) +
    '-' +
    addZero(d) +
    ' ' +
    addZero(h) +
    ':' +
    addZero(mm) +
    ':' +
    addZero(s)
  )
}
function addZero(m: string | number) {
  return m < 10 ? '0' + m : m
}
/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString()
  })
  return str
}

/**
 * 生成UUID
 */
export function getUuid() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID()
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c)
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(
          16
        )
      }
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, callback)
    }
  }
  let timestamp = new Date().getTime()
  let perforNow =
    (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0
      timestamp = Math.floor(timestamp / 16)
    } else {
      random = (perforNow + random) % 16 | 0
      perforNow = Math.floor(perforNow / 16)
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
  })
}

/**
 * 处理年 月 日
 */
export function convertTime(type: string, param: any) {
  if (isEmpty(param)) {
    return
  }
  const result: object = {
    searchTimes: []
  }
  let startDate = 0
  let endDate = 0
  if (type === 'year') {
    result['searchTimes'].push(new Date(param[0]).toISOString())
    const endDateTime = getYearLastDay(param[1])
    result['searchTimes'].push(endDateTime.toISOString())
  } else if (type === 'month') {
    const startDateTime = new Date(param[0])
    startDate = startDateTime.setDate(startDateTime.getDate())
    // result['searchTimes'].push(new Date(+new Date(startDate) + 8 * 3600 * 1000).toISOString())
    result['searchTimes'].push(new Date(startDate).toISOString())
    const endDateTime = new Date(param[1])
    endDateTime.setMonth(endDateTime.getMonth() + 1)
    endDate = endDateTime.setDate(endDateTime.getDate())
    // result['searchTimes'].push(new Date(+new Date(endDate) + 8 * 3600 * 1000).toISOString())
    result['searchTimes'].push(new Date(endDate).toISOString())
  } else {
    const startDateTime = new Date(param[0])
    startDateTime.setHours(0, 0, 0, 0)
    result['searchTimes'].push(startDateTime.toISOString())

    const endDateTime = new Date(param[1])
    endDateTime.setHours(24, 0, 0, 0)
    result['searchTimes'].push(endDateTime.toISOString())
  }
  return result
}

/**
 * 处理年 月 日
 */
export function convertXTime(type: string, param: string) {
  if (isEmpty(param)) {
    return
  }
  if (type === 'year') {
    const xDateTime = new Date(param)
    return xDateTime.getFullYear()
  } else if (type === 'month') {
    const xDateTime = new Date(param)
    return xDateTime.getFullYear() + '/' + addZero(xDateTime.getMonth() + 1)
  } else if (type === 'day') {
    const xDateTime = new Date(param)
    return addZero(xDateTime.getMonth() + 1) + '/' + addZero(xDateTime.getDate())
  } else if (type === 'hour' || type === 'minute') {
    // 2022-05-29 02:00:00
    return param
  }
}
/**
 * 获取当前年份的第一天和最后一天
 * @returns {string} 例如 2019-01-01~2019-12-31
 */
// function getYearFirstDay(param: string) {
//   const firstDay = new Date(param)
//   firstDay.setHours(0, 0, 0, 0)
//   firstDay.setDate(2)
//   firstDay.setMonth(0)
//   return firstDay
// }
function getYearLastDay(param: string) {
  const lastDay = new Date(param)
  // lastDay.setHours(23, 59, 59, 999)
  lastDay.setFullYear(lastDay.getFullYear() + 1)
  lastDay.setDate(lastDay.getDate())
  return lastDay
}
// 获取当前月第一天和最后一天
export function getFirstAndLastDate() {
  //获取当前时间
  const date = new Date()
  //获取当前月的第一天
  const monthStart = date.setDate(1)
  //获取当前月
  let currentMonth = date.getMonth()
  //获取到下一个月，++currentMonth表示本月+1，一元运算
  const nextMonth = ++currentMonth
  //获取到下个月的第一天
  const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
  //一天时间的毫秒数
  const oneDay = 1000 * 60 * 60 * 24
  //获取当前月第一天和最后一天
  const firstDay = new Date(dayjs(monthStart).format('YYYY-MM-DD'))
  //nextMonthFirstDay-oneDay表示下个月的第一天减一天时间的毫秒数就是本月的最后一天
  // @ts-ignore
  const lastDay = new Date(dayjs(nextMonthFirstDay - oneDay).format('YYYY-MM-DD'))

  return [firstDay, lastDay]
}
// 根据年、月、日、时、分 计算任意两个日期之间的年数、月数、天数、小时数、分钟数
export function getCount(interval: string, time: any) {
  let count = 30
  if (interval === 'year') {
    count = 10
  } else if (interval === 'month') {
    count = 12
  } else if (interval === 'day') {
    count = dayjs(time[1]).diff(time[0], 'days')
  } else if (interval === 'hour') {
    count =
      dayjs(new Date(time[1])).diff(new Date(time[0]), 'hours') === 0
        ? 24
        : dayjs(new Date(time[1])).diff(new Date(time[0]), 'hours')
  } else if (interval === 'minute') {
    count =
      dayjs(time[1]).diff(time[0], 'minutes', true) / 15 === 0
        ? 96
        : dayjs(time[1]).diff(time[0], 'minutes', true) / 15
  }
  return count
}

export function dealEndTime(interval: string, time: any) {
  if (time.length === 2) {
    let endTimestamp
    const timestamp = new Date(time[1])
    if (interval === 'year') {
      endTimestamp = new Date(timestamp.getFullYear() + 1 + '').getTime() - 8 * 60 * 60 * 1000
    } else if (interval === 'month') {
      let year = timestamp.getFullYear()
      let month = timestamp.getMonth() + 2
      if (month > 12) {
        year++
        month -= 12
      }
      endTimestamp = new Date(year + '/' + BEMS_COMMON.toDouble(month)).getTime()
    } else if (interval === 'day' || interval === 'hour' || interval === 'minute') {
      endTimestamp = timestamp.getTime() + 24 * 60 * 60 * 1000
    }
    return new Date(endTimestamp).toISOString()
  }
}
