import { service } from '@/config/axios'

import { AxiosPromise } from 'axios'

import { configs } from '@/config/axios/config'

const { default_headers } = configs

// @ts-ignore
const request = <T>(option: x): AxiosPromise<T> => {
  const { url, method, params, data, headersType, responseType, headers } = option
  return service({
    url: url,
    method,
    params,
    data,
    responseType: responseType,
    headers: {
      'Content-Type': headersType || default_headers,
      ...{
        //   orgid: 'ibms',
      },
      ...headers
    }
  })
}

function getFn<T = any>(option: {
  '&componentName=StaticProperty'?: undefined
  headers?: {
    'org-id'?: number
    language?: string
    Host?: string
    Connection?: string
    Authorization?: string
  }
  url: string
  params?
  responseType?
}): AxiosPromise<T> {
  return request<T>({ method: 'get', ...option })
}

function postFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request<T>({ method: 'post', ...option })
}

function deleteFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request<T>({ method: 'delete', ...option })
}

function putFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request<T>({ method: 'put', ...option })
}

export const useAxios = () => {
  return {
    get: getFn,
    post: postFn,
    delete: deleteFn,
    put: putFn
  }
}
