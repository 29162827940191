// import { ref } from 'vue'
// import axios from 'axios'
import { getWholeConfig } from '../api/personalConfig'

// 获取页面org-id
const getOrgId = () => {
  const orgId: any = JSON.parse(localStorage.getItem('currentOrgIdMarker'))
  if (orgId) {
    return Number(orgId)
  } else {
    return orgId == 0 ? 0 : 1
  }
}

const getAllConfig = (callback) => {
  const reqWhole = {
    // creator: localStorage.getItem('validUser'),
    // creator: 'bian.chen@advantech.com.cn',
    apm_org_id: getOrgId()
  }
  const promiseWhole = getWholeConfig(reqWhole)
  // const promisPersonal = getPersonalConfig()

  promiseWhole
    .then(({ status, data }) => {
      if (status && status == 200) {
        const local = data.condition
        if (local && Object.keys(local).length) {
          localStorage.setItem('ecowatch_wholeConfig', JSON.stringify(local))
        } else {
          // 接口没数据给默认值
          localStorage.setItem(
            'ecowatch_wholeConfig',
            JSON.stringify({
              area_cascade: false,
              area_has_default: true,
              area_default_level: 1
            })
          )
        }
        callback && callback()
      } else {
        callback && callback()
      }
    })
    .catch(() => {
      callback && callback()
    })
}

export const usePersonalConfig = (callback) => {
  const _local = JSON.parse(localStorage.getItem('ecowatch_wholeConfig'))
  if (_local === undefined || _local === null || _local === '') {
    getAllConfig(callback)
  } else {
    callback && callback()
  }
}
