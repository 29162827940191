import { configs } from '@/config/axios/config'
import { MockMethod } from 'vite-plugin-mock'

const { result_code } = configs

const timeout = 1000

const List: {
	username: string
	password: string
	role: string
	roleId: string
}[] = [
	{
		username: 'admin',
		password: 'admin',
		role: 'admin',
		roleId: '1'
	},
	{
		username: 'test',
		password: 'test',
		role: 'test',
		roleId: '2'
	}
]

export default [
	// 冰机实时COP
	{
		url: '/fm/getIceMachineCOPApi',
		method: 'post',
		response: () => {
			return {
				data: [
					{
						nodeId: 17648,
						sensorName: 'COP',
						sensorType: 'CustomMeasure',
						ts: 1690265504885,
						v: 3
					},
					{
						nodeId: 17649,
						sensorName: 'COP',
						sensorType: 'CustomMeasure',
						ts: 1690265504885,
						v: 7
					}
				],
				status: 200,
				statusText: 'OK',
				headers: {
					'access-control-allow-credentials': 'true',
					'access-control-allow-origin':
						'https://portal-apm-bemsdev-cluster01.axa-dev.wise-paas.top/',
					connection: 'close',
					'content-length': '97',
					'content-type': 'application/json; charset=utf-8',
					date: 'Tue, 25 Jul 2023 06:11:53 GMT',
					server: 'MSF-Server-v3.3.0-beta.3',
					vary: 'Origin, Origin'
				},
				config: {
					transitional: {
						silentJSONParsing: true,
						forcedJSONParsing: true,
						clarifyTimeoutError: false
					},
					transformRequest: [null],
					transformResponse: [null],
					timeout: 60000,
					xsrfCookieName: 'XSRF-TOKEN',
					xsrfHeaderName: 'X-XSRF-TOKEN',
					maxContentLength: -1,
					maxBodyLength: -1,
					env: {
						FormData: null
					},
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'org-id': 1
					},
					baseURL: '/',
					retry: 0,
					retryDelay: 1000,
					url: '/api-apm/api/v1/latest/data',
					method: 'post',
					data: '{"sensors":[{"nodeId":17648,"sensorType":"CustomMeasure","sensorName":"COP"},{"nodeId":17649,"sensorType":"CustomMeasure","sensorName":"COP"}],"startTs":"2023-07-23T16:00:00.000Z","endTs":"2023-07-24T16:00:00.000Z","count":9999,"retTsType":"unixTs"}',
					withCredentials: true
				},
				request: {}
			}
		}
	},

	// 冰机实时负荷占比
	{
		url: '/fm/getIceMachineRealTimeLoadRatioApi',
		method: 'post',
		response: () => {
			return {
				data: [
					{
						nodeId: 17648,
						sensorName: 'COP',
						sensorType: 'CustomMeasure',
						ts: 1690265504885,
						v: 60
					},
					{
						nodeId: 17649,
						sensorName: 'COP',
						sensorType: 'CustomMeasure',
						ts: 1690265504885,
						v: 40
					}
				],
				status: 200,
				statusText: 'OK',
				headers: {
					'access-control-allow-credentials': 'true',
					'access-control-allow-origin':
						'https://portal-apm-bemsdev-cluster01.axa-dev.wise-paas.top/',
					connection: 'close',
					'content-length': '97',
					'content-type': 'application/json; charset=utf-8',
					date: 'Tue, 25 Jul 2023 06:11:53 GMT',
					server: 'MSF-Server-v3.3.0-beta.3',
					vary: 'Origin, Origin'
				},
				config: {
					transitional: {
						silentJSONParsing: true,
						forcedJSONParsing: true,
						clarifyTimeoutError: false
					},
					transformRequest: [null],
					transformResponse: [null],
					timeout: 60000,
					xsrfCookieName: 'XSRF-TOKEN',
					xsrfHeaderName: 'X-XSRF-TOKEN',
					maxContentLength: -1,
					maxBodyLength: -1,
					env: {
						FormData: null
					},
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'org-id': 1
					},
					baseURL: '/',
					retry: 0,
					retryDelay: 1000,
					url: '/api-apm/api/v1/latest/data',
					method: 'post',
					data: '{"sensors":[{"nodeId":17648,"sensorType":"CustomMeasure","sensorName":"COP"},{"nodeId":17649,"sensorType":"CustomMeasure","sensorName":"COP"}],"startTs":"2023-07-23T16:00:00.000Z","endTs":"2023-07-24T16:00:00.000Z","count":9999,"retTsType":"unixTs"}',
					withCredentials: true
				},
				request: {}
			}
		}
	},

	// 供回水温差趋势
	{
		url: '/fm/getSupplyReturnWaterTemperatureTrendApi',
		method: 'post',
		response: () => {
			return {
				data: [
					{
						count: 24,
						nodeId: 17648,
						nodeName: '冷冻水',
						sensorName: 'COP趋势',
						sensorType: 'CustomMeasure',
						value: [
							{
								ts: 1690131563000,
								v: 1
							},
							{
								ts: 1690135163000,
								v: 2.3
							},
							{
								ts: 1690138763000,
								v: 5
							},
							{
								ts: 1690142363000,
								v: 4.6
							},
							{
								ts: 1690145963000,
								v: 2.5
							},
							{
								ts: 1690149563000,
								v: 3.6
							},
							{
								ts: 1690153163000,
								v: 10.1
							},
							{
								ts: 1690156763000,
								v: 3
							},
							{
								ts: 1690160363000,
								v: 5
							},
							{
								ts: 1690163963000,
								v: 5
							},
							{
								ts: 1690167563000,
								v: 8
							},
							{
								ts: 1690171163000,
								v: 0
							},
							{
								ts: 1690174763000,
								v: 11
							},
							{
								ts: 1690178363000,
								v: 12
							},
							{
								ts: 1690181963000,
								v: 2
							},
							{
								ts: 1690185563000,
								v: 3
							},
							{
								ts: 1690189163000,
								v: 4
							},
							{
								ts: 1690192763000,
								v: 5
							},
							{
								ts: 1690196363000,
								v: 6
							},
							{
								ts: 1690199963000,
								v: 8
							},
							{
								ts: 1690203563000,
								v: 2
							},
							{
								ts: 1690207163000,
								v: 1
							},
							{
								ts: 1690210763000,
								v: 9
							},
							{
								ts: 1690214363000,
								v: 7
							}
						]
					},
					{
						count: 24,
						nodeId: 17649,
						nodeName: '冷却水',
						sensorName: 'COP趋势',
						sensorType: 'CustomMeasure',
						value: [
							{
								ts: 1690131563000,
								v: 11
							},
							{
								ts: 1690135163000,
								v: 22.3
							},
							{
								ts: 1690138763000,
								v: 15
							},
							{
								ts: 1690142363000,
								v: 4.61
							},
							{
								ts: 1690145963000,
								v: 12.5
							},
							{
								ts: 1690149563000,
								v: 13.6
							},
							{
								ts: 1690153163000,
								v: 1.1
							},
							{
								ts: 1690156763000,
								v: 13
							},
							{
								ts: 1690160363000,
								v: 15
							},
							{
								ts: 1690163963000,
								v: 5.5
							},
							{
								ts: 1690167563000,
								v: 18
							},
							{
								ts: 1690171163000,
								v: 10
							},
							{
								ts: 1690174763000,
								v: 11
							},
							{
								ts: 1690178363000,
								v: 22
							},
							{
								ts: 1690181963000,
								v: 2
							},
							{
								ts: 1690185563000,
								v: 3
							},
							{
								ts: 1690189163000,
								v: 14
							},
							{
								ts: 1690192763000,
								v: 15
							},
							{
								ts: 1690196363000,
								v: 6
							},
							{
								ts: 1690199963000,
								v: 18
							},
							{
								ts: 1690203563000,
								v: 2
							},
							{
								ts: 1690207163000,
								v: 11
							},
							{
								ts: 1690210763000,
								v: 9
							},
							{
								ts: 1690214363000,
								v: 7.5
							}
						]
					}
				],
				status: 200,
				statusText: 'OK',
				headers: {
					'access-control-allow-credentials': 'true',
					'access-control-allow-origin':
						'https://portal-apm-bemsdev-cluster01.axa-dev.wise-paas.top/',
					connection: 'close',
					'content-length': '883',
					'content-type': 'application/json; charset=utf-8',
					date: 'Tue, 25 Jul 2023 05:26:11 GMT',
					server: 'MSF-Server-v3.3.0-beta.3',
					vary: 'Origin, Origin'
				},
				config: {
					transitional: {
						silentJSONParsing: true,
						forcedJSONParsing: true,
						clarifyTimeoutError: false
					},
					transformRequest: [null],
					transformResponse: [null],
					timeout: 60000,
					xsrfCookieName: 'XSRF-TOKEN',
					xsrfHeaderName: 'X-XSRF-TOKEN',
					maxContentLength: -1,
					maxBodyLength: -1,
					env: {
						FormData: null
					},
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'org-id': 1
					},
					baseURL: '/',
					retry: 0,
					retryDelay: 1000,
					url: '/api-apm/api/v1/hist/raw/data',
					method: 'post',
					data: '{"sensors":[{"nodeId":17648,"sensorType":"CustomMeasure","sensorName":"COP趋势"},{"nodeId":17649,"sensorType":"CustomMeasure","sensorName":"COP趋势"}],"startTs":"2023-07-23T16:00:00.000Z","endTs":"2023-07-24T16:00:00.000Z","count":9999,"retTsType":"unixTs"}',
					withCredentials: true
				},
				request: {}
			}
		}
	},

	// 冰机COP趋势
	{
		url: '/fm/getIceMachineTrendApi',
		method: 'post',
		response: () => {
			return {
				data: [
					{
						count: 24,
						nodeId: 17648,
						nodeName: '冰机1#',
						sensorName: 'COP趋势',
						sensorType: 'CustomMeasure',
						value: [
							{
								ts: 1690131563000,
								v: 1
							},
							{
								ts: 1690135163000,
								v: 2.3
							},
							{
								ts: 1690138763000,
								v: 5
							},
							{
								ts: 1690142363000,
								v: 4.6
							},
							{
								ts: 1690145963000,
								v: 2.5
							},
							{
								ts: 1690149563000,
								v: 3.6
							},
							{
								ts: 1690153163000,
								v: 10.1
							},
							{
								ts: 1690156763000,
								v: 3
							},
							{
								ts: 1690160363000,
								v: 5
							},
							{
								ts: 1690163963000,
								v: 5
							},
							{
								ts: 1690167563000,
								v: 8
							},
							{
								ts: 1690171163000,
								v: 0
							},
							{
								ts: 1690174763000,
								v: 11
							},
							{
								ts: 1690178363000,
								v: 12
							},
							{
								ts: 1690181963000,
								v: 2
							},
							{
								ts: 1690185563000,
								v: 3
							},
							{
								ts: 1690189163000,
								v: 4
							},
							{
								ts: 1690192763000,
								v: 5
							},
							{
								ts: 1690196363000,
								v: 6
							},
							{
								ts: 1690199963000,
								v: 8
							},
							{
								ts: 1690203563000,
								v: 2
							},
							{
								ts: 1690207163000,
								v: 1
							},
							{
								ts: 1690210763000,
								v: 9
							},
							{
								ts: 1690214363000,
								v: 7
							}
						]
					},
					{
						count: 24,
						nodeId: 17649,
						nodeName: '冰机2#',
						sensorName: 'COP趋势',
						sensorType: 'CustomMeasure',
						value: [
							{
								ts: 1690131563000,
								v: 11
							},
							{
								ts: 1690135163000,
								v: 22.3
							},
							{
								ts: 1690138763000,
								v: 15
							},
							{
								ts: 1690142363000,
								v: 4.61
							},
							{
								ts: 1690145963000,
								v: 12.5
							},
							{
								ts: 1690149563000,
								v: 13.6
							},
							{
								ts: 1690153163000,
								v: 1.1
							},
							{
								ts: 1690156763000,
								v: 13
							},
							{
								ts: 1690160363000,
								v: 15
							},
							{
								ts: 1690163963000,
								v: 5.5
							},
							{
								ts: 1690167563000,
								v: 18
							},
							{
								ts: 1690171163000,
								v: 10
							},
							{
								ts: 1690174763000,
								v: 11
							},
							{
								ts: 1690178363000,
								v: 22
							},
							{
								ts: 1690181963000,
								v: 2
							},
							{
								ts: 1690185563000,
								v: 3
							},
							{
								ts: 1690189163000,
								v: 14
							},
							{
								ts: 1690192763000,
								v: 15
							},
							{
								ts: 1690196363000,
								v: 6
							},
							{
								ts: 1690199963000,
								v: 18
							},
							{
								ts: 1690203563000,
								v: 2
							},
							{
								ts: 1690207163000,
								v: 11
							},
							{
								ts: 1690210763000,
								v: 9
							},
							{
								ts: 1690214363000,
								v: 7.5
							}
						]
					}
				],
				status: 200,
				statusText: 'OK',
				headers: {
					'access-control-allow-credentials': 'true',
					'access-control-allow-origin':
						'https://portal-apm-bemsdev-cluster01.axa-dev.wise-paas.top/',
					connection: 'close',
					'content-length': '883',
					'content-type': 'application/json; charset=utf-8',
					date: 'Tue, 25 Jul 2023 05:26:11 GMT',
					server: 'MSF-Server-v3.3.0-beta.3',
					vary: 'Origin, Origin'
				},
				config: {
					transitional: {
						silentJSONParsing: true,
						forcedJSONParsing: true,
						clarifyTimeoutError: false
					},
					transformRequest: [null],
					transformResponse: [null],
					timeout: 60000,
					xsrfCookieName: 'XSRF-TOKEN',
					xsrfHeaderName: 'X-XSRF-TOKEN',
					maxContentLength: -1,
					maxBodyLength: -1,
					env: {
						FormData: null
					},
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						'org-id': 1
					},
					baseURL: '/',
					retry: 0,
					retryDelay: 1000,
					url: '/api-apm/api/v1/hist/raw/data',
					method: 'post',
					data: '{"sensors":[{"nodeId":17648,"sensorType":"CustomMeasure","sensorName":"COP趋势"},{"nodeId":17649,"sensorType":"CustomMeasure","sensorName":"COP趋势"}],"startTs":"2023-07-23T16:00:00.000Z","endTs":"2023-07-24T16:00:00.000Z","count":9999,"retTsType":"unixTs"}',
					withCredentials: true
				},
				request: {}
			}
		}
	},

] as MockMethod[]
