import { defineStore } from 'pinia'
import { store } from '../index'
import { useCache } from '@/hooks/web/useCache'
import { appModules } from '@/config/app'
import type { AppState, LayoutType, ThemeTypes } from '@/config/app'
import { setCssVar, humpToUnderline } from '@/utils'
import { ElMessage } from 'element-plus'
// import Vrouter from '@/router'

const { wsCache } = useCache()
// const route = Vrouter.currentRoute.value

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => appModules,
  persist: {
    enabled: true
  },
  getters: {
    getPlatName(): string {
      return this.platName
    },
    getCopyRight(): string {
      return this.copyRight
    },
    getLanauge(): any {
      return JSON.parse(window.sessionStorage.getItem('lang')).v
    },
    getBreadcrumb(): boolean {
      return this.breadcrumb
    },
    getBreadcrumbIcon(): boolean {
      return this.breadcrumbIcon
    },
    getCollapse(): boolean {
      return this.collapse
    },
    getUniqueOpened(): boolean {
      return this.uniqueOpened
    },
    getHamburger(): boolean {
      return this.hamburger
    },
    getScreenfull(): boolean {
      return this.screenfull
    },
    getSize(): boolean {
      return this.size
    },
    getLocale(): boolean {
      return this.locale
    },
    getTagsView(): boolean {
      return this.tagsView
    },
    getTagsViewIcon(): boolean {
      return this.tagsViewIcon
    },
    getLogo(): boolean {
      return this.logo
    },
    // 右上角logo
    getWiseLogo(): string {
      return this.wiseLogo
    },
    // 网页favicon
    getFavicon(): string {
      return this.favicon
    },
    // 登录背景页Back
    getBack(): string {
      return this.back
    },
    getAdvlogo(): string {
      return this.advLogo
    },
    // 登录用户
    getUserName(): string {
      return this.userName
    },
    // 登录密码
    getPassword(): string {
      return this.password
    },
    //登录状态
    getRemember(): boolean {
      return this.remember
    },
    getFixedHeader(): boolean {
      return this.fixedHeader
    },
    getGreyMode(): boolean {
      return this.greyMode
    },
    getPageLoading(): boolean {
      return this.pageLoading
    },
    getLayout(): LayoutType {
      return this.layout
    },
    getTitle(): string {
      return this.title
    },
    getUserInfo(): string {
      return this.userInfo
    },
    getIsDark(): boolean {
      return this.isDark
    },
    getCurrentSize(): ElememtPlusSize {
      return this.currentSize
    },
    getSizeMap(): ElememtPlusSize[] {
      return this.sizeMap
    },
    getMobile(): boolean {
      return this.mobile
    },
    getTheme(): ThemeTypes {
      return this.theme
    },
    getFooter(): boolean {
      return this.footer
    }
  },
  actions: {
    setPlatName(platName: string) {
      this.platName = platName
    },
    setCopyRight(copyRight: string) {
      this.copyRight = copyRight
    },
    setBreadcrumb(breadcrumb: boolean) {
      this.breadcrumb = breadcrumb
    },
    setBreadcrumbIcon(breadcrumbIcon: boolean) {
      this.breadcrumbIcon = breadcrumbIcon
    },
    setCollapse(collapse: boolean) {
      this.collapse = collapse
    },
    setUniqueOpened(uniqueOpened: boolean) {
      this.uniqueOpened = uniqueOpened
    },
    setHamburger(hamburger: boolean) {
      this.hamburger = hamburger
    },
    setScreenfull(screenfull: boolean) {
      this.screenfull = screenfull
    },
    setSize(size: boolean) {
      this.size = size
    },
    setLocale(locale: boolean) {
      this.locale = locale
    },
    setTagsView(tagsView: boolean) {
      this.tagsView = tagsView
    },
    setTagsViewIcon(tagsViewIcon: boolean) {
      this.tagsViewIcon = tagsViewIcon
    },
    setLogo(logo: boolean) {
      this.logo = logo
    },
    setAdvlogo(advLogo: string) {
      this.advLogo = advLogo
    },
    setwiseLogo(wiseLogo: string) {
      this.wiseLogo = wiseLogo
    },
    setfavicon(favicon: string) {
      this.favicon = favicon
    },
    setcopyrightLogo(copyrightLogo: string) {
      this.copyrightLogo = copyrightLogo
    },
    setback(back: string) {
      this.back = back
    },
    // 登录用户
    setUserName(userName: string) {
      this.userName = userName
    },
    // 登录密码
    setPassword(password: string) {
      this.password = password
    },
    //登录状态
    setRemember(remember: boolean) {
      this.remember = remember
    },
    setFixedHeader(fixedHeader: boolean) {
      this.fixedHeader = fixedHeader
    },
    setGreyMode(greyMode: boolean) {
      this.greyMode = greyMode
    },
    setPageLoading(pageLoading: boolean) {
      this.pageLoading = pageLoading
    },
    setLayout(layout: LayoutType) {
      if (this.mobile && layout !== 'classic') {
        ElMessage.warning('移动端模式下不支持切换其他布局')
        return
      }
      this.layout = layout
      wsCache.set('layout', this.layout)
    },
    setTitle(title: string) {
      this.title = title
    },
    setIsDark(isDark: boolean) {
      // const color = 'radial-gradient(94.63% 94.63% at 50% 5.37%, #263743 0%, #000000 100%)'
      this.isDark = isDark
      if (this.isDark) {
        // console.log('currentRoute', route)
        // const el = document.documentElement
        // // const el = document.getElementById('xxx')
        // // 获取 css 变量
        // getComputedStyle(el).getPropertyValue(`--el-color-primary`)
        // // 设置 css 变量
        // el.style.setProperty('--dark-bg', color)
        // el.style.setProperty('--xingHe-test', 'transparent')
        // el.style.setProperty('--top-dark-bg', 'rgba(95, 117, 162, 0.1)')
        // el.style.setProperty('--table-border', 'rgba(85, 137, 155, 0.3)')
        // el.style.setProperty('--card-bg', '#141F26')
        // el.style.setProperty('--card-border', 'rgba(63, 77, 90, 0.5)')
        // el.style.setProperty('--title-color', '#ffffff')
        // el.style.setProperty('--tree-font-color', '#ffffff')
        // el.style.setProperty('--select-font-color', '#5AC8FA')
        // el.style.setProperty('--button-bg', '#5AC8FA')
        // el.style.setProperty('--box-shadow', 'unset')
        // el.style.setProperty('--border-radius', 'unset')
        // el.style.setProperty('--border-bg', '#5AC8FA')
        // el.style.setProperty('--check-bg', '#5AC8FA')
        // el.style.setProperty('--page-color', '#5AC8FA')
        // el.style.setProperty('--btn-test-color', '#85969b')
        // el.style.setProperty('--selected-cell-color', 'rgba(87, 110, 125, 0.3)')
        // el.style.setProperty('--table-hover-color', 'rgba(43, 59, 75, 0.3)')
        // el.style.setProperty('--popper-color', '#ffffff')
        // el.style.setProperty('--el-header-bg', 'rgba(95, 117, 162, 0.1)')
        // el.style.setProperty('--transparent-inDark', 'transparent')
        // el.style.setProperty('--border-input', 'unset')
        // el.style.setProperty('--box-shadow-light', 'rgba(85, 137, 155, 0.3)')
        // el.style.setProperty('--tree-node-icon', '#ffffff')
        // el.style.setProperty('--status-btn-bg-on', '#233C29')
        // el.style.setProperty('--status-btn-span-on', '#33C733')
        // el.style.setProperty('--status-btn-bd-on', '#33C733')
        // el.style.setProperty('--status-btn-bg-off', '#352529')
        // el.style.setProperty('--status-btn-span-off', '#E53348')
        // el.style.setProperty('--status-btn-bd-off', '#E53348')
        // el.style.setProperty('--status-btn-bg-un', '#334353')
        // el.style.setProperty('--status-btn-span-un', '#85969B')
        // el.style.setProperty('--status-btn-bd-un', '#85969B')
        // el.style.setProperty('--tree-hover', 'rgba(87, 110, 125, 0.3)')
        // el.style.setProperty('--select-hover', 'rgba(87, 110, 125, 0.3)')
        // el.style.setProperty('--tree-border-color', '#ffffff')
        document.documentElement.classList.add('dark')
        document.documentElement.classList.remove('light')
      } else {
        // const el = document.documentElement
        // // 获取 css 变量
        // getComputedStyle(el).getPropertyValue(`--el-color-primary`)
        // // 设置 css 变量
        // el.style.setProperty('--dark-bg', '#e6e6e6')
        // // el.style.setProperty('--xingHe-test', 'transparent')
        // el.style.setProperty('--top-dark-bg', '#f0f0f0')
        // el.style.setProperty('--table-border', '#006296')
        // el.style.setProperty('--card-bg', '#ffffff')
        // el.style.setProperty('--card-border', '#e1e1e1')
        // el.style.setProperty('--title-color', '#515a6e')
        // el.style.setProperty('--tree-font-color', '#515a6e')
        // el.style.setProperty('--select-font-color', '#515a6e')
        // el.style.setProperty('--button-bg', '#008cd6')
        // // el.style.setProperty('--box-shadow', 'unset')
        // // el.style.setProperty('--border-radius', 'unset')
        // el.style.setProperty('--border-bg', 'unset')
        // el.style.setProperty('--check-bg', '#409eff')
        // el.style.setProperty('--page-color', '#515a6e')
        // // el.style.setProperty('--btn-test-color', '#85969b')
        // el.style.setProperty('--selected-cell-color', '#dcdfe6')
        // el.style.setProperty('--table-hover-color', '#fafafa')
        // el.style.setProperty('--popper-color', '#515a6e')
        // el.style.setProperty('--el-header-bg', '#fafafa')
        // el.style.setProperty('--transparent-inDark', '#ffffff')
        // el.style.setProperty('--border-input', 'unset')
        // el.style.setProperty('--box-shadow-light', '#dcdfe6')
        // el.style.setProperty('--tree-node-icon', '#a8abb2')
        // el.style.setProperty('--status-btn-bg-on', '#e3f0d3')
        // el.style.setProperty('--status-btn-span-on', '#73b725')
        // el.style.setProperty('--status-btn-bd-on', '#73b725')
        // el.style.setProperty('--status-btn-bg-off', '#ffd6d6')
        // el.style.setProperty('--status-btn-span-off', '#ce2f2f')
        // el.style.setProperty('--status-btn-bd-off', '#ce2f2f')
        // el.style.setProperty('--status-btn-bg-un', '#e1e1e1')
        // el.style.setProperty('--status-btn-span-un', '787878')
        // el.style.setProperty('--status-btn-bd-un', '#787878')
        // el.style.setProperty('--tree-hover', '#ffffff')
        // el.style.setProperty('--select-hover', '#fafafa')
        // el.style.setProperty('--tree-border-color', '#dcdfe6')
        document.documentElement.classList.add('light')
        document.documentElement.classList.remove('dark')
      }
      wsCache.set('isDark', this.isDark)
    },
    setCurrentSize(currentSize: ElememtPlusSize) {
      this.currentSize = currentSize
      wsCache.set('currentSize', this.currentSize)
    },
    setMobile(mobile: boolean) {
      this.mobile = mobile
    },
    setTheme(theme: ThemeTypes) {
      this.theme = Object.assign(this.theme, theme)
      wsCache.set('theme', this.theme)
    },
    setCssVarTheme() {
      for (const key in this.theme) {
        setCssVar(`--${humpToUnderline(key)}`, this.theme[key])
      }
    },
    setFooter(footer: boolean) {
      this.footer = footer
    },

    changeState({ key, value }) {
      this[key] = value
    }
  }
})

export const useAppStoreWithOut = () => {
  return useAppStore(store)
}
