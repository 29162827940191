import { useAxios } from '@/hooks/web/useAxios'

const request = useAxios()
import type { UserLoginType, UserType } from './types'

export const loginApi = (data: UserLoginType) => {
  return request.post({
    // url: '/user/login',
    url: '/auth/api/v1/login',
    data: {
      userName: data.username,
      password: data.password
    }
  })
}
// 修改密码
export const modifyPaswordApi = (data) => {
  return request.post({
    url: '/loginSet/loginpage/resetpassword',
    data
  })
}
// 忘记密码
export const forgetPaswordApi = (data) => {
  return request.get({
    url: '/auth/api/v1/forgetpwd?email=' + data.username
  })
}
// 个性化/loginpage/customs
export const getCustomsApi = () => {
  return request.get({
    url: '/loginSet/loginpage/customs'
  })
}

// 删除个性化 https://api-eco-common-bemsdev-cluster01.axa-dev.wise-paas.top/loginpage/custom?id=x
export const deleteCustomsApi = (id) => {
  return request.delete({
    url: `/loginSet/loginpage/custom?id=${id}`
  })
}

export const createCustomsApi = (data) => {
  return request.post({
    url: '/loginSet/loginpage/custom',
    data
  })
}

export const editCustomsApi = (data) => {
  return request.put({
    url: '/loginSet/loginpage/custom',
    data
  })
}

export const getCustomsConfigApi = () => {
  return request.get({
    url: '/loginSet/custom/config?item=login'
  })
}
export const getResetConfigApi = (id) => {
  return request.put({
    url: `/loginSet/custom/config/resume?item=${id}`
  })
}
export const createTypeConfigApi = (data) => {
  return request.put({
    url: `/loginSet/custom/config`,
    data
  })
}
export const redirectRouter = (redirectUrl, orgId) => {
  const data = {
    redirectUrl: redirectUrl,
    orgId: orgId
  }
  return request.post({
    url: `/loginSet/router/redirect`,
    data
  })
}
export const goRouter = (source, orgId, startApp) => {
  let url
  if (source === 'login') {
    url = `/loginSet/router?source=${source}&skipType=forward&orgId=${orgId}`
  } else if (startApp === '') {
    url = `/loginSet/router?source=${source}&skipType=forward&orgId=${orgId}`
  } else if (startApp !== '') {
    url = `/loginSet/router?source=${source}&skipType=forward&orgId=${orgId}&startApp=${startApp}`
  }
  return request.get({
    url: url
  })
}
export const backRouter = (source, orgId, clickApp) => {
  return request.get({
    url: `/loginSet/router?source=${source}&skipType=backward&orgId=${orgId}&startApp=${clickApp}`
  })
}
export const changeTypeConfigApi = (data) => {
  return request.put({
    url: `/loginSet/custom/config`,
    data
  })
}
export const refreshToken = () => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: `/auth/api/v1/refreshtoken`
  })
}
export const logout = () => {
  return request.delete({
    url: '/api-apm/auth/loginout'
  })
}
export const getTenantIdAPI = () => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: '/tenant-apm/tenantId'
  })
}
export const PostTenantIdAPI = () => {
  return request.post({
    data: {
      makePoint: 'v2'
    },
    url: '/measure-apm/measure/metric/tenant/config'
  })
}
export const loginOutApi = () => {
  return request.get({ '&componentName=StaticProperty': undefined, url: '/user/loginOut' })
}

export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    total: number
    list: UserType[]
    // @ts-ignore
  }>({ '&componentName=StaticProperty': undefined, url: '/user/list', params })
}

export const getAdminRoleApi = ({ params }) => {
  return request.get<{
    list: AppCustomRouteRecordRaw[]
    // @ts-ignore
  }>({ url: '/role/list', params })
}

// 从权限管理系统获取服务资源
export const getServices = (param: number) => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: '/auth/api/v1/service?queryType=Self&type=menu&index=0&count=1000',
    headers: {
      'org-id': param
    }
  })
}
// 从权限管理系统获取菜单资源
export const getMenus = (param: number, service: string) => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: `/auth/api/v1/resource?service=${service}&type=menu&index=0&count=1000`,
    headers: {
      'org-id': param
    }
  })
}

// 获取所有资源
export const getMenusAll = (param: number) => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: `/auth/api/v1/resource?type=menu&index=0&count=1000`,
    headers: {
      'org-id': param
    }
  })
}

// 发送日志请求
export const postLog = (param: object) => {
  return request.post({
    url: `/log-api/v1/http_log`,
    data: param
  })
}

export const getTestRoleApi = ({ params }) => {
  return request.get<{
    list: string[]
    // @ts-ignore
  }>({ url: '/role/list', params })
}

// 查询组织
export const getOrgNumbersApi = () => {
  return request.get({
    '&componentName=StaticProperty': undefined,
    url: `/auth/api/v1/org?index=0&count=10000`
  })
}

export const getPlatApp = () => {
  return request.get({
    url: `/loginSet/platform/app`,
    headers: {
      'org-id': 0
    }
  })
}

export const getPlatPage = () => {
  return request.get({
    url: `/loginSet/custom/config/page?service=platform`,
    headers: {
      'org-id': 0
    }
  })
}

export const postPlatPage = (data) => {
  return request.post({
    url: `/loginSet/custom/config/page`,
    data
  })
}

export const putPlatPage = (data) => {
  return request.put({
    url: `/loginSet/custom/config/page`,
    data
  })
}

export const putPlatApp = (data) => {
  return request.put({
    url: `/loginSet/platform/app`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

export const postPlatApp = (data) => {
  return request.post({
    url: `/loginSet/platform/app`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

export const putPlatCategory = (data) => {
  return request.put({
    url: `/loginSet/platform/category`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

export const postPlatCategory = (data) => {
  return request.post({
    url: `/loginSet/platform/category`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

export const deletePlatCategory = (data) => {
  return request.delete({
    url: `/loginSet/platform/category?ids=${data}`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

// 厂务
export const getIemsApp = (id) => {
  return request.get({
    url: `/loginSet/iems/app`,
    headers: {
      'org-id': id
    }
  })
}

export const getIemsPage = (id) => {
  return request.get({
    url: `/loginSet/custom/config/page?service=iemsfmcs`,
    headers: {
      'org-id': id
    }
  })
}

export const postIemsPage = (data, id) => {
  return request.post({
    url: `/loginSet/custom/config/page`,
    data,
    headers: {
      'org-id': id
    }
  })
}

export const putIemsPage = (data, id) => {
  return request.put({
    url: `/loginSet/custom/config/page`,
    data,
    headers: {
      'org-id': id
    }
  })
}

export const putIemsApp = (data, id) => {
  return request.put({
    url: `/loginSet/iems/app`,
    data,
    headers: {
      'org-id': id
    }
  })
}

export const deleteIemsApp = (data, id) => {
  return request.delete({
    url: `/loginSet/iems/app?ids=${data}`,
    data,
    headers: {
      'org-id': id
    }
  })
}

export const deletePlatApp = (data) => {
  return request.delete({
    url: `/loginSet/platform/app?ids=${data}`,
    data,
    headers: {
      'org-id': 0
    }
  })
}

export const postIemsApp = (data, id) => {
  return request.post({
    url: `/loginSet/iems/app`,
    data,
    headers: {
      'org-id': id
    }
  })
}
// 平台应用中心分类
export const getPlatCategory = () => {
  return request.get({
    url: `/loginSet/platform/category`,
    headers: {
      'org-id': 0
    }
  })
}

//获取ECOWatch与框架版本号接口
export const getVersion = () => {
  return request.get({ url: '/loginSet/common/app/version' })
}
export const getOverviewConfig = () => {
  return request.get({
    url: `/loginSet/custom/config/items?type=appset&name=iems`
  })
}
export const getAIUrl = () => {
  return request.get({
    url: `/loginSet/custom/config/items?type=AIUrl`
  })
}
