import { useCache } from '@/hooks/web/useCache'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import zhTW from 'element-plus/es/locale/lang/zh-tw'
import en from 'element-plus/es/locale/lang/en'

const { wsCache } = useCache()

export const elLocaleMap = {
  'zh-cn': zhCn,
  'zh-tw': zhTW,
  'zh-hk': zhTW,
  // 英国
  en: en,
  // 英国 - 英国
  'en-gb': en,
  // 英国 -澳大利亚
  'en-au': en,
  // 英国 -加拿大
  'en-ca': en,
  // 英国 -美国
  'en-us': en,
  // 英国 -南非
  'en-za': en,
  // 英国 -新西兰
  'en-nz': en,
  // 英国 -印度
  'en-in': en,
  // 英国 -香港
  'en-hk': en,
  // 英语（英国，《牛津英语词典》拼法）
  'en-gb-oxendict': en
}
export interface LocaleState {
  currentLocale: LocaleDropdownType
  localeMap: LocaleDropdownType[]
}

// navigator.language页面多语言首选和浏览器保持一致
export const localeModules: LocaleState = {
  currentLocale: {
    lang: wsCache.get('lang')?.toLowerCase() || navigator.language?.toLowerCase() || 'en',
    elLocale: elLocaleMap[wsCache.get('lang') || navigator.language?.toLowerCase()] || 'en'
  },
  // 多语言
  localeMap: [
    {
      lang: 'zh-cn',
      name: '简体中文'
    },
    {
      lang: 'zh-tw',
      name: '繁體中文'
    },
    {
      lang: 'en',
      name: 'English'
    }
  ]
}
