import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()
/*不需左侧菜单的路由 如登录、门户等*/
export const constantRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/portal',
    name: 'Root',
    meta: {
      hidden: true,
      canTo: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/forgetPassword',
    component: () => import('@/views/Login/Login.vue'),
    name: 'ForgetPassword',
    meta: {
      hidden: true,
      title: t('忘记密码'),
      noTagsView: true
    }
  },

  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]
export const ascFrontPortal: AppRouteRecordRaw[] = [
  // 空压静态资源
]
/* portal页 */
export const portalRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/portal',
    component: Layout,
    name: 'Portal',
    children: [
      {
        path: '',
        component: () => import('@/views/Portal/Index.vue'),
        name: 'Portal',
        meta: {
          hidden: true,
          canTo: true,
          noTagsView: true
        }
      }
    ],
    meta: {
      hidden: true,
      canTo: true,
      noTagsView: true
    }
  }
]
/* map页 */
export const mapRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/map',
    component: Layout,
    name: 'Map',
    meta: {
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Map/Index.vue'),
        name: 'Map',
        meta: {
          hidden: true,
          canTo: true
        }
      }
    ]
  }
]
/* overview页 */
export const overviewRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/overview',
    component: Layout,
    name: 'Overview',
    meta: {
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Overview/Index.vue'),
        name: 'Overview',
        meta: {
          hidden: true,
          canTo: true
        }
      }
    ]
  }
]

/* 驾驶舱页 */
export const cockpitRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/cockpit',
    component: () => import('@/views/Cockpit/Index.vue'),
    name: 'Cockpit',
    meta: {
      hidden: true,
      title: '驾驶舱',
      noTagsView: true
    }
  }
  // {
  //   path: '/cockipt',
  //   component: Layout,
  //   name: 'Cockpit',
  //   meta: {
  //     hidden: true,
  //     canTo: true
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/Cockpit/Index.vue'),
  //       name: 'Cockpit',
  //       meta: {
  //         hidden: true,
  //         canTo: true
  //       }
  //     }
  //   ]
  // }
]
/* 视频监控 */
export const videoMonitorRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/videoMonitor',
    component: () => import('@/views/VideoMonitor/Index.vue'),
    name: 'VideoMonitor',
    meta: {
      hidden: true,
      title: '视频监控',
      noTagsView: true
    }
  }
  // {
  //   path: '/cockipt',
  //   component: Layout,
  //   name: 'Cockpit',
  //   meta: {
  //     hidden: true,
  //     canTo: true
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/Cockpit/Index.vue'),
  //       name: 'Cockpit',
  //       meta: {
  //         hidden: true,
  //         canTo: true
  //       }
  //     }
  //   ]
  // }
]
/*框架层 菜单 frame开头*/
export const asyncRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/frame/setting',
    component: Layout,
    redirect: '/frame/setting/normal',
    name: 'settingsNew',
    meta: {
      title: t('loginPage.personSetting'),
      alwaysShow: true
    },
    children: [
      {
        path: 'normal',
        component: () => import('@/views/Management/BasicSetting.vue'),
        name: 'normal',
        meta: {
          title: t('loginPage.loginPage'),
          activeMenu: 'normal',
          meta: false
        }
      },
      {
        path: 'platForm',
        component: () => import('@/views/Management/PlatformApplication.vue'),
        name: 'platForm',
        meta: {
          title: t('loginPage.platFormApp'),
          activeMenu: 'platForm',
          meta: false
        }
      },
      {
        path: 'iemsFmcs',
        component: () => import('@/views/Management/IemsfmcsSetting.vue'),
        name: 'iemsFmcs',
        meta: {
          title: t('loginPage.iemsfmscApp'),
          activeMenu: 'iemsFmcs',
          meta: false
        }
      },
      {
        path: 'colorSet',
        component: () => import('@/views/Management/ColorSet.vue'),
        name: 'colorSet',
        meta: {
          title: t('colorSet.themeSetting'),
          activeMenu: 'colorSet',
          meta: false
        }
      },
      {
        path: 'mapSet',
        component: () => import('@/views/Management/MapSetting.vue'),
        name: 'mapSet',
        meta: {
          title: t('loginPage.mapSetting'),
          activeMenu: 'mapSet',
          meta: false
        }
      }
    ]
  },
  // {
  //   path: '/frame',
  //   component: Layout,
  //   redirect: '/frame/normal',
  //   name: 'settings',
  //   meta: {
  //     title: t('router.systemSettings'),
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'orgNew',
  //       component: () => import('@/views/Auth/Org.vue'),
  //       name: 'OrgNew',
  //       meta: {
  //         title: t('auth.orgManager'),
  //         hidden: true
  //       }
  //     }
  //     // ECOWatchRouter
  //   ]
  // },

  {
    path: '/frame/auth',
    component: Layout,
    redirect: '/frame/auth/org',
    name: 'auth',
    meta: {
      title: t('auth.authManage'),
      alwaysShow: true
    },
    children: [
      {
        path: 'org',
        component: () => import('@/views/Auth/Org.vue'),
        name: 'Org',
        meta: {
          title: t('auth.orgManager'),
          hidden: false
        }
      },
      {
        path: 'user',
        component: () => import('@/views/Auth/User.vue'),
        name: 'User',
        meta: {
          title: t('auth.userManager')
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/Auth/Menu.vue'),
        name: 'Menu',
        meta: {
          title: t('auth.menuMgmt')
        }
      },
      {
        path: 'role',
        component: () => import('@/views/Auth/Role.vue'),
        name: 'Role',
        meta: {
          title: t('auth.roleAuth')
        }
      }
    ]
  },
  {
    path: '/frame/log',
    component: Layout,
    redirect: '/frame/log/action',
    name: 'log',
    meta: {
      title: t('journal.LogMgmt'),
      alwaysShow: true
    },
    children: [
      {
        path: 'action',
        // component: () => import('@/views/EnergyConsumptionStatistics/PeakAndValleyAnalysis.vue'),
        name: 'action',
        meta: {
          hidden: true,
          title: t('journal.OperationLog')
        }
      },
      {
        path: 'system',
        component: () => import('@/views/Journal/Journal.vue'),
        name: 'system',
        meta: {
          title: t('journal.systemLog')
        }
      }
    ]
  },
  {
    path: '/frame/menuManage',
    component: Layout,
    name: '/frame/menuManage',
    meta: {
      hidden: true
    },
    children: [
      {
        path: '',
        // component: () => import('@/views/EnergyConsumptionStatistics/PeakAndValleyAnalysis.vue'),
        name: 'menuManageDemo',
        meta: {
          title: t('auth.menuManage')
        }
      }
    ]
  },
  {
    path: '/frame/import',
    component: Layout,
    redirect: '/frame/import/data',
    name: 'import',
    meta: {
      hidden: true,
      title: '数据管理',
      alwaysShow: true
    },
    children: [
      {
        path: 'data',
        component: () => import('@/views/Management/Data.vue'),
        name: 'data',
        meta: {
          title: t('数据录入')
        }
      },
      {
        path: 'resource',
        component: () => import('@/views/Management/Resource.vue'),
        name: 'resource',
        meta: {
          title: t('资源录入')
        }
      }
    ]
  }
  // 报警设置
  // {
  //   path: '/frame/alarm',
  //   component: Layout,
  //   redirect: '/frame/alarm/setting',
  //   name: 'AlarmSetting',
  //   meta: {
  //     title: t('alarmSetting.alarmSetting')
  //     // alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'setting',
  //       component: () => import('@/views/Alarm/Setting.vue'),
  //       name: 'realtimeAlarm',
  //       meta: {
  //         title: t('alarmSetting.alarmSetting')
  //       }
  //     }
  //   ]
  // }
]

/* carbonr选择页 */
export const carbonrSelectRouterMapFront: AppRouteRecordRaw[] = [
  {
    path: '/carbonr-select',
    component: Layout,
    name: 'CarbonrSelect',
    children: [
      {
        path: '',
        component: () => import('@/views/Carbonr/Index.vue'),
        name: 'CarbonrSelect',
        meta: {
          hidden: true,
          canTo: true,
          noTagsView: true
        }
      }
    ],
    meta: {
      hidden: true,
      canTo: true,
      noTagsView: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMapFront as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})
// @ts-ignore
window.router = router
export const resetRouter = (): void => {
  const resetWhiteNameList = [
    'Redirect',
    'Login',
    'NoFind',
    'Root',
    'Portal',
    'PortalParent',
    'ForgetPassword'
  ]
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
